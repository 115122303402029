import React, { useContext, useEffect, useRef, useState } from "react";
import { Badge, Button, Form, Input, Popconfirm, Space, Switch, Table, notification } from "antd";
import * as api from "../../api/getapi";
import * as post from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import axios from "axios";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {}
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const FileTransferModal = ({ requestId, close }) => {
  const [checkStrictly, setCheckStrictly] = useState(false);
  const [categoryFields, setCategoryFields] = useState({});
  const [conditionFields, setConditionFields] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [newSelectedRows, setNewSelectedRows] = useState([]);
  const [someSelected, setSomeSelected] = useState(false);
  const [intialDataSource, setIntialDataSource] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [paginationData, setPaginationData] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [statusFlag, setStatusFlag] = useState(false);
  const [count, setCount] = useState(2);
  const getTable = useApi(api.getValidateData);
  const saveProgress = useApi(post.saveProgress);
  const [notificationApi, contextHolder] = notification.useNotification();

  const [isClicked, setIsClicked] = useState(false);

  const rowSelection = {
    selectedRowKeys: newSelectedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      setNewSelectedRows(selectedRowKeys);
      setSomeSelected(true);
    },
    onSelect: (record, selected, selectedRows) => {
      //   setNewSelectedRows([...newSelectedRows,record])
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      // if (selectedRows.length === 0) {
      //     setSelectAllCheck(false)
      // } else {
      //     setSelectAllCheck(true)
      // }
    },
  };
  const fetchTableData = async () => {
    try {
      setisLoading(true);
      const data = await getTable.request(requestId, pagination);
      setPaginationData({
        current: data?.data?.paginationData.current_page,
        pageSize: data?.data?.paginationData.limit,
        total: data?.data?.paginationData.totalCount,
        position: ["bottomCenter"],
      });
      setIntialDataSource(
        data?.data?.data.map((item, index) => {
          return {
            key: index,
            row_id: item.row_id,
            category: {
              value: item.category_id,
              label: item.category,
            },
            condition: { value: item.condition_id === -1 ? 2 : item.condition_id, label: item.condition_item == "nil" || item.condition_item == "Other" ? "New" : item.condition_item },
            cost_price: item.cost_price,
            quantity: item.quantity,
            sale_price: item.sale_price,
            status: item.status === "Problematic" ? item.status : item.title && item.sale_price && item.cost_price && item.quantity >= 0 && item.category_id >= 0 ? "done" : "pending",
            title: item.title,
          };
        }),
      );
      setDataSource(
        data?.data?.data.map((item, index) => {
          return {
            key: index,
            row_id: item.row_id,
            category: {
              value: item.category_id,
              label: item.category,
            },
            condition: { value: item.condition_id === -1 ? 2 : item.condition_id, label: item.condition_item == "nil" || item.condition_item == "Other" ? "New" : item.condition_item },
            cost_price: item.cost_price,
            quantity: item.quantity,
            sale_price: item.sale_price,
            status: item.status === "Problematic" ? item.status : item.title && item.sale_price && item.cost_price && item.quantity >= 0 && item.category_id >= 0 ? "done" : "pending",
            title: item.title,
          };
        }),
      );
      setStatusFlag(
        data?.data?.data
          .map((item, index) => {
            return {
              key: index,
              row_id: item.row_id,
              category: {
                value: item.category_id,
                label: item.category,
              },
              condition: { value: item.condition_id === -1 ? 2 : item.condition_id, label: item.condition_item == "nil" || item.condition_item == "Other" ? "New" : item.condition_item },
              cost_price: item.cost_price,
              quantity: item.quantity,
              sale_price: item.sale_price,
              status: item.status === "Problematic" ? item.status : item.title && item.sale_price && item.cost_price && item.quantity >= 0 && item.category_id >= 0 ? "done" : "pending",
              title: item.title,
            };
          })
          .some((item) => item.status === "done"),
      );
    } catch (err) {
      setisLoading(false);
    }
    setisLoading(false);
  };
  function findChangedObjects(array1, array2) {
    let changedObjects = [];

    array1.forEach((obj1) => {
      let found = false;

      array2.forEach((obj2) => {
        if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
          found = true;
        }
      });

      if (!found) {
        changedObjects.push(obj1);
      }
    });
    return changedObjects;
  }
  const fetchCategory = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + "/categories", {
        withCredentials: true,
      });
      if (res) {
        setCategoryFields(
          res?.data?.data?.map((cat) => ({
            value: cat.id,
            label: cat.category,
          })),
        );
      }
    } catch (error) {}
  };

  const fetchCondition = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + "/conditions", {
        withCredentials: true,
      });
      if (res) {
        setConditionFields(
          res?.data?.data
            ?.filter((item) => item.id !== -1)
            .map((cat) => ({
              value: cat.id,
              label: cat.label,
            })),
        );
      }
    } catch {}
  };
  useEffect(() => {
    if (requestId) {
      fetchTableData();
    }
  }, [requestId, pagination, toggle]);
  useEffect(() => {
    if (requestId) {
      fetchCategory();
      fetchCondition();
    }
  }, [requestId]);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleFieldChange = (e, id, name) => {
    setStatusFlag(true);
    if (newSelectedRows.length > 0 && newSelectedRows.includes(id)) {
      const values = dataSource.map((item) => {
        if (newSelectedRows.includes(item.key) && item.status !== "problematic") {
          if (name === "category" || name === "condition") {
            item[name] = { value: e.value, label: e.label };
            // Add any additional checks for status here
            if (item["status"] !== "problematic" && item["category"]?.value !== -1 && Object.values(item).every((value) => value !== null && value !== undefined && value !== "")) {
              return { ...item, status: "done" };
            } else {
              return { ...item, status: "pending" };
            }
          } else {
            item[e.target.id] = e.target.value;
            if (item["status"] !== "problematic" && item["category"]?.value !== -1 && Object.values(item).every((value) => value !== null && value !== undefined && value !== "")) {
              return { ...item, status: "done" };
            } else {
              return { ...item, status: "pending" };
            }
            // Add any additional checks for status here
          }
        }
        return item;
      });
      const updatedDataSource = values.map((item) => {
        // Check conditions to update status
      });
      setDataSource(values);
    } else {
      let values = [...dataSource];
      if (name === "category" || name === "condition") {
        values[id][name] = e;
        values[id].title && values[id].sale_price && values[id].quantity >= 0 && values[id].cost_price && values[id].category.value !== -1 && values[id].status !== "Problematic" ? (values[id].status = "done") : (values[id].status = "pending");
      } else {
        values[id][e.target.id] = e.target.value;
        values[id].title && values[id].sale_price && values[id].quantity >= 0 && values[id].cost_price && values[id].category.value !== -1 && e.target.value !== "" && values[id].status !== "Problematic" ? (values[id].status = "done") : (values[id].status = "pending");
      }
      setDataSource(values);
    }
  };

  const handleProblematic = (id) => {
    setStatusFlag(true);
    let values = [...dataSource];

    if (newSelectedRows.length > 0) {
      values.map((item) => {
        if (newSelectedRows.includes(item.key)) {
          item["status"] = "Problematic";
        }
      });
    }
    values[id].status = "Problematic";
    setDataSource(values);
  };
  const defaultColumns = [
    {
      title: "Title",
      dataIndex: "title",
      width: "30%",
      editable: true,
      key: "title",
      // render: (_, record) => {
      //     return <>
      //     <Input value={record.title} onChange={(e) => handleFieldChange(e, record.key, "title")} />
      //     </>
      // }
    },
    {
      title: "Sale Price",
      dataIndex: "sale_price",
      width: "16%",
      editable: true,
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      editable: true,
      width: "16%",
    },
    {
      title: "Cost Price",
      dataIndex: "cost_price",
      editable: true,
      width: "16%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "16%",
      render: (_, record) => {
        return record.status === "Problematic" ? (
          <p>
            {" "}
            <Badge status="danger" color="#f50" text={record.status} />
          </p>
        ) : record.status === "done" ? (
          <p>
            {" "}
            <Badge status="success" text={record.status} />{" "}
          </p>
        ) : (
          <p>
            {" "}
            <Badge status="warning" text={record.status} />{" "}
          </p>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category_id",
      width: "16%",
      render: (_, record) => {
        return (
          <div style={{ width: "130px" }}>
            <ReactSelect
              className="w-100"
              important
              placeholder="Select Category"
              searchable={false}
              value={record?.category}
              onChange={(e) => {
                handleFieldChange(e, record.key, "category");
              }}
              options={categoryFields}
            />
          </div>
        );
      },
    },

    {
      title: "Condition",
      dataIndex: "condition_id",
      width: "16%",
      render: (_, record) => {
        return (
          <div style={{ width: "130px" }}>
            <ReactSelect
              important
              placeholder="Select condition"
              searchable={false}
              value={record?.condition}
              options={conditionFields}
              onChange={(e) => {
                handleFieldChange(e, record.key, "condition");
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "16%",
      render: (_, record) => {
        return (
          <div onClick={() => handleProblematic(record.key)} className="d-flex cursor-pointer justify-content-center">
            <FontAwesomeIcon icon={faCircleExclamation} />
          </div>
        );
      },
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        // handleSave,
      }),
    };
  });
  const handleTableChange = (pagination) => {
    setNewSelectedRows([]);
    setPagination(pagination);
  };

  const handleSaveProgress = async () => {
    setIsClicked(true);
    const source = dataSource.filter((item) => (item.status !== "Problematic" ? item.status !== "pending" && item.category.value !== -1 : item));
    if (source.length > 0 && statusFlag) {
      //   const filterData = findChangedObjects(dataSource, intialDataSource); // USED BY NOOR IDK WHY
      const filterData = source.filter((item) => newSelectedRows.includes(item.key));
      if (!filterData.length) {
        setSelectAllCheck(false);

        toast.error("No data to save, please select the row you want to save ", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      setNewSelectedRows([]);
      setSelectAllCheck(false);
      const finaldata = filterData.map((item) => {
        return {
          row_id: item.row_id,
          title: item.title,
          sale_price: item.sale_price,
          quantity: item.quantity,
          cost_price: item.cost_price,
          status: item.status,
          category_id: item.category.value,
          condition_id: item.condition.value,
          condition_item: item.condition.label,
          fk_req_id: requestId,
        };
      });
      try {
        const data = await saveProgress.request(requestId, finaldata);
        if (data) {
          setStatusFlag(false);
          setToggle((prev) => !prev);
          toast.success(`${finaldata?.length} item saved successfully`, {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsClicked(false);
        }
      } catch (error) {
        setIsClicked(false);
      }
    } else {
      setIsClicked(false);
      toast.error("No data to save, please fill all fields ", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const rowClassName = (record) => {
    if (record.status === "problematic") {
      return "problematic-row";
    }
    return "";
  };
  return (
    <div>
      {/* <Space align="center" style={{ marginBottom: 16 }}>
                CheckStrictly: <Switch checked={checkStrictly} onChange={setCheckStrictly} />
            </Space> */}
      <Table
        components={components}
        rowClassName={rowClassName}
        onRow={(record, rowIndex) => {
          return {
            onSelect: (event, record) => {},
            onChange: (event) => {
              handleFieldChange(event, record.key, "status");
              // let values = [...dataSource]
              //     values[record.key].status==="Problematic"?values[record.key].status="Problematic":event.target.value!==""?values[record.key].status="done":values[record.key].status="pending"
              //     setDataSource(values);
            },
          };
        }}
        // rowClassName={() => 'editable-row'}
        bordered
        loading={isLoading}
        rowSelection={{ ...rowSelection, checkStrictly }}
        dataSource={dataSource}
        columns={columns}
        pagination={paginationData}
        onChange={handleTableChange}
      />
      <div className="d-flex justify-content-end">
        <Button type="primary" onClick={dataSource?.length ? handleSaveProgress : close} disabled={isClicked}>
          {dataSource?.length ? "Save Progress" : "Close"}
        </Button>
      </div>
    </div>
  );
};
export default FileTransferModal;
