import React from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as post from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function DisapprovedModal({ requestId, onClose, toggle }) {
  const disapprove = useApi(post.disapproveAddonsRequest);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      status: "Cancelled",
    };
    try {
      const data = await disapprove.request(requestId, payload);
      if (data) {
        toast.success("Request Disapproved Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        onClose();
        toggle((prev) => !prev);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  return (
    <div>
      <hr />
      <div className="d-flex justify-content-center pb-4">
        <FontAwesomeIcon icon={faExclamationTriangle} style={{ width: "40px", height: "40px", color: "red" }} />
      </div>
      <div>
        <span className="d-flex justify-content-center pb-4">Do you really want to cancel this request?</span>
      </div>
      <hr />
      <div className="mt-2 gap-2 d-flex justify-content-end">
        <Button type="submit" onClick={handleSubmit} variant="warning">
          Disapprove
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outline-secondary"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default DisapprovedModal;
