import "./CardsSection.css";
import "boxicons";

export default function CardsSection({ sectionTitle, children, dropdown }) {
  return (
    <div className="card-section my-3 p-3">
      <div className={dropdown ? "d-flex flex-column flex-md-row align-items-center justify-content-between mb-2" : "d-flex align-items-center"}>
        <div className="w-100 w-md-auto">
          <box-icon name="stats" color="#FFA500" size="1.5rem" className="me-2" pull="left"></box-icon>
          <p className="text-secondary section-title mb-3 fw-light fs-5 d-inline-block">{sectionTitle}</p>
        </div>
        <div className="w-100 w-md-auto">{dropdown}</div>
      </div>
      <div className="d-flex row g-2 align-items-center justify-content-center">{children}</div>
    </div>
  );
}
