import React, { useState, useEffect } from "react";

import APhase from "../Actions/APhase";
import BPhase from "../Actions/BPhase";
import EPhase from "../Actions/EPhase";
import CPhase from "../Actions/CPhase";
import DPhase from "../Actions/DPhase";
import GPhase from "../Actions/GPhase";
import HPhase from "../Actions/HPhase";
import YPhase from "../Actions/YPhase";
import XPhase from "../Actions/XPhase";
import FPhase from "../Actions/FPhase";
import ZPhase from "../Actions/ZPhase";

function Subscription({ phase, search, sizeOfPage, orderBy, refresh, toggle }) {
  switch (phase) {
    case "A":
      return <APhase sizeOfPage={sizeOfPage} orderBy={orderBy} search={search} toggle={toggle} refresh={refresh} phase={phase} />;
    case "B":
      return <BPhase sizeOfPage={sizeOfPage} orderBy={orderBy} search={search} refresh={refresh} toggle={toggle} phase={phase} />;
    case "C":
      return <CPhase sizeOfPage={sizeOfPage} orderBy={orderBy} search={search} refresh={refresh} toggle={toggle} phase={phase} />;
    case "D":
      return <DPhase sizeOfPage={sizeOfPage} orderBy={orderBy} search={search} toggle={toggle} refresh={refresh} phase={phase} />;
    case "E":
      return <EPhase sizeOfPage={sizeOfPage} refresh={refresh} orderBy={orderBy} search={search} toggle={toggle} phase={phase} />;
    case "F":
      return <FPhase sizeOfPage={sizeOfPage} orderBy={orderBy} refresh={refresh} search={search} toggle={toggle} phase={phase} />;
    case "G":
      return <GPhase sizeOfPage={sizeOfPage} orderBy={orderBy} search={search} refresh={refresh} toggle={toggle} phase={phase} />;
    case "H":
      return <HPhase sizeOfPage={sizeOfPage} refresh={refresh} orderBy={orderBy} search={search} toggle={toggle} phase={phase} />;
    case "Y":
      return <YPhase sizeOfPage={sizeOfPage} orderBy={orderBy} refresh={refresh} search={search} toggle={toggle} phase={phase} />;
    case "X":
      return <XPhase sizeOfPage={sizeOfPage} orderBy={orderBy} refresh={refresh} search={search} toggle={toggle} phase={phase} />;
    case "Z":
      return <ZPhase sizeOfPage={sizeOfPage} refresh={refresh} orderBy={orderBy} search={search} toggle={toggle} phase={phase} />;
  }
  return (
    <div>
      <strong>Please Select Any Phase</strong>

      {/* {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult name="" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            columnNotShow={["id", "username", "is_active"]}
            dateColumn={[
              "current_plan_start_date",
              "current_plan_end_date",
              "banner_showing_start_date",
              "grace_period_ends_on",
            ]}
            sortColumn={[""]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            toggleActionButton={
              user.user_type === "super-admin" && {
                label: (data) => {
                  return "Shift to paid plan";
                },
                onClick: (data) => {
                  shiftPlan(data);
                },
                variant: (data) => {
                  return "outline-primary";
                },
                style: { padding: "8px", fontSize: "12px" },
              }
            }
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar
                noOfPages={noOfPages}
                currentPage={currentPage}
                changePage={changePage}
              />
            </Col>
            <Col
              className="d-md-none d-flex align-items-center mt-3"
              xs="6"
              lg="3"
            >
              <span className="text-secondary fs-6">{count} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>

          <Modal
            show={showDeactivateModal}
            onHide={closeModal}
            animation={true}
            centered
          >
            <Modal.Header>
              <Modal.Title>
                Confirm {isActive ? "Deactivate" : "Activate"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center pb-4"></div>
              <div>
                <span className="d-flex justify-content-center pb-4">
                  Do you really want to {isActive ? "Deactivate" : "Activate"}?
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gap-3">
                <Button
                  className="w-100 rounded-3"
                  onClick={closeModal}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  className="w-100 rounded-3"
                  onClick={() => handleStatus(deleteId, isActive)}
                  variant={`${isActive ? "danger" : "primary"}`}
                >
                  {isActive ? "Deactivate" : "Activate"}
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
          <PopUpModal
            size="md"
            title="Shift Plan"
            show={showShiftModal}
            onHide={() => setShifModal(false)}
          >
            <ShiftModal
              close={() => setShifModal(false)}
              toggle={toggle}
              id={shopId}
              userId={user.user_id}
            />
          </PopUpModal>
        </>
      )} */}
    </div>
  );
}

export default Subscription;
