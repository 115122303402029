import React, { useEffect, useState } from "react";
import { Button, Col, Form, Overlay, Row, Tooltip } from "react-bootstrap";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { useSelector } from "react-redux";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import { toast } from "react-toastify";
import { useRef } from "react";
function ChangePlan({ close, id, toggle }) {
  const [isLoading, setIsLoading] = useState(false);
  const getReq = useApi(api.getRequestforsubs);
  const changePlan = useApi(api.getChangePlanDetails);
  const [discount, setDiscount] = useState(0);
  const [payable, setPayable] = useState(0);
  const [paidAmount, setPaidAmount] = useState();
  const [transDate, setTransDate] = useState();
  const [transactionId, settransactionId] = useState();
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [paidCheck, setPaidCheck] = useState(false);
  const [selectImageURL, setSelectImagURL] = useState();
  const [selectImage, setSelectImag] = useState();
  const [validated, setValidated] = useState(false);
  const imageUpload = useApi(postapi.UPloadImage);
  const [isClicked, setIsClicked] = useState(false);
  const [remaining, setRemaining] = useState();

  const planChange = useApi(postapi.ChangePlan);
  const { user } = useSelector((state) => state.auth);
  const [checkReq, setCheckReq] = useState();
  const fetchRefundDetails = async () => {
    try {
      const data = await changePlan.request(id);
      setPayable(data?.data?.success?.data.total_billable);
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0], e.target.files[0].name);
    setSelectImag(formData);
    setSelectImagURL(URL.createObjectURL(e.target.files[0]));
  };
  const confirm = async () => {
    try {
      const requestId = getReq.data[0].id;
      const data = await planChange.request({ shop_id: id });
      toast.success(data?.data?.success?.message, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      toggle((prev) => !prev);
      close();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setIsLoading(true);
        const data = await getReq.request({
          shop_id: id,
          user_id: user.user_id,
          request_type: "change-plan-request",
          status: "in_progress",
        });
        setCheckReq(data?.data?.length);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchRefundDetails();
    fetchRequests();
  }, []);
  useEffect(() => {
    if (discount) {
      setPayable(Number(resData?.total_billable) - Number(discount));
    } else {
      setPayable(resData?.total_billable);
    }
    setRemaining(Number(payable) - Number(paidAmount));
  }, [discount, paidAmount]);
  const submit = async (e) => {
    e.preventDefault();
    let imgData;
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    try {
      setValidated(true);
      if (selectImage) {
        imgData = await imageUpload.request(selectImage);
      }
      if (resData?.total_billable) {
        if (paidAmount && transactionId && transDate) {
          setIsClicked(true);

          const transaction = {
            shop_id: id,
            transaction_date: transDate,
            plan_id: resData?.next_plan_id,
            plan_amount: resData?.next_plan_amount,
            arrears: resData?.arrears,
            discount: discount,
            total_payable: payable,
            amount_paid: paidAmount,
            remaining_amount: Number(payable) - Number(paidAmount),
            transaction_image_url: imgData ? imgData.data.files[0] : null,
            transaction_logged_by: user.user_id,
            transaction_id: transactionId,
          };

          await planChange.request(transaction);

          toggle((prev) => !prev);
          toast.success("Plan Shifted successfully ", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // close();
          setIsClicked(false);
          setValidated(false);
          close();
        } else {
          setIsClicked(false);
        }
      }
    } catch (error) {
      // setError(error.response.data.error.message);
      setIsClicked(false);
    }
  };
  const resData = changePlan?.data?.success?.data;
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <div>
          {getReq?.data?.length > 0 ? (
            <>
              {resData?.changeType !== "UPGRADE" ? (
                <>
                  <div className="mb-3 text-center">
                    <strong>{resData?.changeType?.replaceAll("_", " ")}</strong>
                  </div>
                  <div>
                    {resData?.total_refund && (
                      <span>
                        <strong>Total Refund Amount :</strong>
                        <span> {resData?.total_refund}</span>
                      </span>
                    )}
                    <div className="d-flex flex-column ms-5 mb-4">
                      {resData?.current_plan_name && (
                        <span>
                          <strong>Current plan name :</strong>
                          <span> {resData?.current_plan_name}</span>
                        </span>
                      )}
                      <span>
                        <strong>Current billing frequency :</strong>
                        <span> {resData?.current_billing_frequency}</span>
                      </span>

                      {resData?.next_plan_name && (
                        <span>
                          <strong>Next plan name:</strong>
                          <span> {resData?.next_plan_name}</span>
                        </span>
                      )}
                      <span>
                        <strong>Next billing frequency:</strong>
                        <span> {resData?.next_billing_frequency}</span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex gap-3">
                    <Button variant="secondary" className="w-100 rounded-3" onClick={close}>
                      Cancel
                    </Button>
                    <Button className="w-100 rounded-3" onClick={confirm}>
                      Confirm
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Row>
                    <div className="mb-3 text-center">
                      <strong>{resData?.changeType?.replace("_", " ")}</strong>
                    </div>
                    <div>
                      <div className="d-flex flex-column ms-5 mb-4">
                        {resData?.total_refund && (
                          <span>
                            <strong>Total Refund Amount :</strong>
                            <span> {resData?.total_refund}</span>
                          </span>
                        )}
                        {resData?.total_billable && (
                          <span>
                            <strong>Total Billable Amount :</strong>
                            <span> {resData?.total_billable}</span>
                          </span>
                        )}
                        <span>
                          <strong>Current plan name :</strong>
                          <span> {resData?.current_plan_name}</span>
                        </span>
                        <span>
                          <strong>Current billing frequency :</strong>
                          <span> {resData?.current_billing_frequency}</span>
                        </span>

                        {resData?.next_plan_name && (
                          <span>
                            <strong>Next plan name:</strong>
                            <span> {resData?.next_plan_name}</span>
                          </span>
                        )}
                        <span>
                          <strong>Next billing frequency:</strong>
                          <span> {resData?.next_billing_frequency}</span>
                        </span>
                      </div>
                    </div>
                    {resData?.total_billable && (
                      <>
                        {" "}
                        <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                          <label>Discount</label>
                          <Form.Control
                            value={discount}
                            name="contact_person_name"
                            onChange={(e) => {
                              setDiscount(e.target.value.replace(/\D/g, ""));
                            }}
                            type="number"
                            placeholder="Rs.1000"
                          />
                        </Form.Group>
                        <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                          <label>
                            Payable <span className="text-danger">*</span>
                          </label>
                          <Form.Control value={Math.round(payable)} type="number" placeholder="Rs.1000" disabled />
                          <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                          <label>
                            Paid Amount <span className="text-danger">*</span>
                          </label>

                          <Overlay target={target.current} show={show} placement="right">
                            {(props) => (
                              <Tooltip style={{ background: "#ffecb5" }} id="overlay-example" {...props}>
                                Paid amount must be less than equal to payable
                              </Tooltip>
                            )}
                          </Overlay>
                          <Form.Control
                            value={paidAmount}
                            ref={target}
                            placeholder="Rs.1000"
                            required
                            // min={0}
                            // max={payable}
                            onChange={(e) => {
                              // e.target.value = parseInt(e.target.value, 10);

                              // let { value, min, max } = e.target;

                              if (Number(payable) >= Number(e.target.value)) {
                                setPaidCheck(false);
                                setPaidAmount(e.target.value);
                              } else {
                                setShow(true);
                                setTimeout(() => {
                                  setShow(false);
                                }, 2000);
                                setPaidCheck(true);
                              }
                              // const value1 = Math.max(
                              //   Number(min),
                              //   Math.min(Number(max), Number(value))
                              // );

                              // setPaidAmount(value1);
                            }}
                          />

                          <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                          <label>
                            Transaction Id <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            value={transactionId}
                            name="contact_person_name"
                            onChange={(e) => {
                              settransactionId(e.target.value);
                            }}
                            placeholder="Enter Transaction Id"
                            required
                          />
                          <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                          <label>
                            Transaction Date
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            value={transDate}
                            onChange={(e) => {
                              setTransDate(e.target.value);
                            }}
                            required
                            type="date"
                          />

                          <Form.Control.Feedback type="invalid">Please select Date.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                          <label>Upload Image</label>
                          <Form.Control type="file" size="sm" accept="image/*" name="contact_person_name" onChange={(e) => handleImageUpload(e)} />
                          <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}
                    <div className="d-flex gap-3 mt-4">
                      <Button variant="secondary" className="w-100 rounded-3" onClick={close}>
                        Cancel
                      </Button>
                      <Button className="w-100 rounded-3" onClick={resData?.total_billable ? submit : confirm}>
                        Confirm
                      </Button>
                    </div>
                  </Row>
                </>
              )}
            </>
          ) : (
            <div className=" mb-3 text-center">
              <strong className="">There is no request for this shop</strong>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ChangePlan;
