import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getParentModules, getParentsModules } from "../../api/getapi";
import { AddSellerModule, editSellerModule } from "../../api/postapi";
import ReactSelect from "../../Components/ReactSelect";
import SearchableSelect from "../../Components/SearchableSelect";
import useApi from "../../Hooks/useApi";

function AddModule({ toggle, close, editData }) {
  const [validated, setValidated] = useState(false);
  const [parentOptions, setParentOptions] = useState([]);
  const [intialValues, setintialValues] = useState({
    name: "",
    type: "",
    nav_key: "",
    is_active: "",
    is_coming: "",
    is_submodule: false,
    parentModule: "",
  });

  const add_module = useApi(AddSellerModule);
  const getParent = useApi(getParentsModules);
  const editModule = useApi(editSellerModule);

  const handleChange = (e) => {
    const { value } = e.target;
    setintialValues({ ...intialValues, [e.target.name]: value });
  };

  const handleCheckBoxChange = (e) => {
    if (e.target.name !== "is_submodule") {
      setintialValues({ ...intialValues, [e.target.name]: e.target.value });
    } else {
      setintialValues({ ...intialValues, [e.target.name]: e.target.checked });
    }
  };
  const handleSelectChange = (e, name) => {
    setintialValues({ ...intialValues, [name]: e });
  };

  useEffect(() => {
    if (editData) {
      setintialValues({
        name: editData.name,
        type: { label: editData.type, value: editData.type },
        nav_key: editData.nav_key,
        is_active: editData.is_active===1 ? "option1" : "option2",
        is_submodule: editData.is_submodule,
        parentModule: { value: editData.parent_module, label: editData.parent_name },

      });
    }
  }, [editData]);

  const submit = async (e) => {
    e.preventDefault();
    setValidated(true);
    const obj = {
      name: intialValues.name,
      type: intialValues.type.value,
      nav_key: intialValues.nav_key,
      is_active: intialValues.is_active==="option1" ? true : false,
      is_coming: intialValues.is_active==="option2" ? true : false,
      is_submodule: intialValues.is_submodule,
      ...(intialValues.is_submodule && { parent_module: intialValues.parentModule.value }),
    };

    if(!Object.values(obj).some((item) => item === "")){

    if (editData) {
      try {
        const data = await editModule.request(editData.id, obj);
        toggle((prev) => !prev);
        close();
      } catch (error) {}
    } else {
      try {
        const data = await add_module.request(obj);
        toggle((prev) => !prev);
        close();
      } catch (error) {}
    }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getParent.request(0);
        setParentOptions(data.data.data.map((item) => ({ label: item.name, value: item.id })));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={submit}>
        <Row>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Name <span className="text-danger">*</span>
            </label>
            <Form.Control className="p-2" name="name" type="text" placeholder="Title" value={intialValues.name} onChange={(e) => handleChange(e)} required />
            <Form.Control.Feedback type="invalid">* Please Enter Title</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
            <label>
              Module Type <span className="text-danger">*</span>
            </label>
            <SearchableSelect
              placeholder="Select Type "
              selectClassName={"bg-white"}
              defaultValue={intialValues.type}
              value={intialValues.type}
              searchable={false}
              options={[
                { value: "Common", label: "Common" },
                { value: "Exclusive", label: "Exclusive" },
              ]}
              handleChange={(e) => {
                handleSelectChange(e, "type");
              }}
            />
            {/* <Form.Control
                className="p-2"
                name="city"
                hidden
                type="text"
                value={
                  Object.keys(frequency)?.length > 0 ? frequency?.value : null
                }
                onChange={() => {}}
                required
              />
              <Form.Control.Feedback type="invalid">
                * Please Select priority
              </Form.Control.Feedback> */}
          </Form.Group>

          <Col className="mt-2 mb-4" lg="12" md="6" sm="12">
            <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
              <label>
                Nav key <span className="text-danger">*</span>
              </label>
              <Form.Control className="p-2" name="nav_key" type="text" placeholder="nav key" value={intialValues.nav_key} onChange={(e) => handleChange(e)} required />
              <Form.Control.Feedback type="invalid">* Please Enter nav</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-2" lg="4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="mb-1">
                Status <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Check type="radio" label=" is-Active" name="is_active" value="option1" checked={intialValues.is_active === "option1"} onChange={(e) => handleCheckBoxChange(e)} />
              <Form.Check type="radio" label="is-Coming" name="is_active" value="option2" checked={intialValues.is_active === "option2"} onChange={(e) => handleCheckBoxChange(e)} />
            </Form.Group>
          </Col>

          <Col className="py-2" lg="4">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="mb-1">
                Is Sub Module <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Check // prettier-ignore
                type="checkbox"
                name="is_submodule"
                id="custom-switch"
                label="Yes/No"
                onChange={(e) => handleCheckBoxChange(e)}
                checked={intialValues.is_submodule}
              />
            </Form.Group>
          </Col>
          {intialValues.is_submodule ? (
            <Col className="mt-2 mb-4" lg="12" md="6" sm="12">
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                <label>
                  Parent Module <span className="text-danger">*</span>
                </label>
                <SearchableSelect
                  placeholder="Select Type "
                  selectClassName={"bg-white"}
                  // defaultValue={frequency}
                  value={intialValues.parentModule}
                  searchable={false}
                  options={parentOptions}
                  handleChange={(e) => {
                    handleSelectChange(e, "parentModule");
                  }}
                />
                {/* <Form.Control
                className="p-2"
                name="city"
                hidden
                type="text"
                value={
                  Object.keys(frequency)?.length > 0 ? frequency?.value : null
                }
                onChange={() => {}}
                required
              />
              <Form.Control.Feedback type="invalid">
                * Please Select priority
              </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
          ):""}
        </Row>
        <div className={`mb-4 `}>
          <Button className="w-100" type="submit">
            {editData ? "Edit Module" : "Add Module"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AddModule;
