import React, { useState, useEffect } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";

import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";

import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PopUpModal from "../Modal";
import AddUser from "../../Pages/SecondaryUser/AddUser";
import AddPage from "../../Pages/data/AddPage";
const DELAY_TIME = 100;

function Data({ userType, salesman, search, status, setOrderBy, sizeOfPage, subscription, orderBy, refresh, toggle }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [action, setAction] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const getPaginationLength = useApi(api.getPointsNoOfPages);
  const deleteCall = useApi(api.DeleteComponent);
  const getcomp = useApi(api.getCompoentData);
  const getcomppages = useApi(api.getCompoentNoOfPages);
  const getsingle = useApi(api.getSingleCompoentData);
  const [editData, setEditData] = useState();
  const [modalAddUserShow, setAddUserModalShow] = useState(false);
  const [showDataModal, setShowDataModal] = useState();
  const [cid, setCid] = useState();

  const fetchpaginationCount = async () => {
    try {
      const data = await getcomppages.request({
        search: search,
        size: sizeOfPage,
      });
      setCount(data?.data?.success?.data?.no_of_items);
      if (data?.data?.success?.data?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.data?.success?.data?.no_of_pages);
        if (currentPage > data?.data?.success?.data?.no_of_pages) {
          setCurrentPage(data?.data?.success?.data?.no_of_pages);
        }
      }
    } catch {}
  };
  const fetchSingleData = async (dataa) => {
    setCid(dataa.id);
    setEditData(dataa);
    setShowDataModal(true);
    // try {
    //   const data = await getsingle.request(dataa.id);
    //   setEditData(data?.data?.success?.data);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    fetchpaginationCount();
    const pageTimeout = setTimeout(async () => {
      const res = await getcomp.request({
        search: search,
        orderBy: orderBy,
        size: sizeOfPage,
        page: currentPage,
      });

      const data = res?.data;
      setIsLoading(false);

      setItems(data?.success?.data);

      if (user.permissionArray.includes("User")) {
        setAction(true);
      } else {
        setAction(false);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [userType, search, orderBy, currentPage, refresh, sizeOfPage, status]);
  const changePage = (page) => {
    setCurrentPage(page);
  };

  const deleteuser = async (data) => {
    try {
      await deleteCall.request(data.id);
      toggle((prev) => !prev);
    } catch (error) {}
  };
  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult name="" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            columnNotShow={["id", "filters", "is_active"]}
            // edit={action ? edit : false}
            dateColumn={["trial_till", "created_at"]}
            sortColumn={["shop_name", "trial_till", "total_points"]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            actions={true}
            // remove={action ? deleteuser : false}
            edit={action ? fetchSingleData : false}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
          <PopUpModal size="lg" title="Edit Modal" show={showDataModal} onHide={() => setShowDataModal(false)}>
            <AddPage close={() => setShowDataModal(false)} editData={editData} toggle={toggle} cid={cid} />
          </PopUpModal>
        </>
      )}
    </div>
  );
}

export default Data;
