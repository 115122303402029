import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { useSelector } from "react-redux";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";

function CancelPlanDeactivate({ close, id, toggle }) {
  const [isLoading, setIsLoading] = useState(false);

  const cancelSubscription = useApi(api.cancelSubscriptionDeactivate);

  const confirm = async () => {
    try {
      const data = await cancelSubscription.request(id);
      toggle((prev) => !prev);
      close();
      // if (data) {
      //   setTimeout(() => close(), 1000);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <div>
          <>
            <div>
              <span className="d-flex justify-content-center me-4 pb-4">
                Do you really want to Cancel shop?
              </span>
            </div>
            <div className="d-flex gap-3">
              <Button
                variant="secondary"
                className="w-100 rounded-3"
                onClick={close}
              >
                Cancel
              </Button>
              <Button className="w-100 rounded-3" onClick={confirm}>
                Confirm
              </Button>
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default CancelPlanDeactivate;
