import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import AddForm from "../../Components/AddForm";

import style from "../../styles/AddInventory.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import SearchableSelect from "../../Components/SearchableSelect";

import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { toast } from "react-toastify";
import ReactSelect from "./../../Components/ReactSelect";

const AddPlans = () => {
  const modulesNotShow = ["Dashboard", "Employees", "Customer", "GeneralReport", "Settings", "LocationList", "Banks", "SystemSettings", "Subscriptions"];
  const modulesAlwaysChecked = ["Dashboard", "GeneralReport", "Customer", "Reports", "Settings", "LocationList", "Banks", "SystemSettings", "Subscriptions", "Employees"];

  const { user } = useSelector((state) => state.auth);

  const location = useLocation();
  const navigate = useNavigate();

  //form validation
  const [validatedVendor, setValidatedVendor] = useState(false);
  //vendors
  const [planName, setplanName] = useState("");
  const [planType, setPlanType] = useState("");
  const [planAmount, setPlanAmount] = useState("");
  const [itemsLimit, setItemsLimit] = useState("");
  const [locationLimit, setLocationLimit] = useState("");
  const [emailsLimit, setEmailsLimit] = useState("");
  const [smsLimit, setSmsLimit] = useState("");
  const [yearlyTotal, setYearlyTotal] = useState("");
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [quarterlyTotal, setQuarterlyTotal] = useState("");
  const dropdownShops = useApi(api.getDropdownShops);
  const checkCustomPlan = useApi(api.checkCustomPlan);
  const FetchAllThemes = useApi(api.getAllThemes);
  const getPricess = useApi(api.fetchAllConsumableItems);
  const getParent = useApi(api.getParentModules);
  const getSubModules = useApi(api.getSubModules);
  const addplan = useApi(postapi.AddPlan);
  const [terms, setTerms] = useState("");
  const [shop, setShop] = useState([]);
  const [error, setError] = useState("");
  const [selectedTheme, setSelectedTheme] = useState();
  const [theme, setTheme] = useState();
  const [filterInput, setFilterInput] = useState("".replace(/\W/g, ""));
  const [modulesOptions, setModulesOptions] = useState([]);
  const [subModulesOptions, setSubModulesOptions] = useState([]);
  const [p_id, setP_id] = useState();
  const [prices, setPrices] = useState([]);
  const [customPrices, setCustomPrices] = useState({
    secondaryUsersTotal: 0,
    marketplaceThemeTotal: 0,
    smsTotal: 0,
    emailTotal: 0,
    locationLimitTotal: 0,
    modulesTotal: 0,
    themesTotal: 0,
    itemsTotal: 0,
    yearlyTotal: 0,
    monthlyTotal: 0,
    quarterlyTotal: 0,
  });
  const [customForm, setCustomForm] = useState({
    planName: "",
    planType: "",
    planAmount: "",
    shop: "",
    itemsLimit: "",
    emailsLimit: "",
    smsLimit: "",
    locationLimit: "",
    yearlyTotal: 0,
    monthlyTotal: 0,
    quarterlyTotal: 0,
    primaryUser: "",
    secondaryUser: "",
    themes: [],
    modules: [],
    submodules: [],
  });
  // Get Shops
  const handlePlanChange = (e) => {
    const { name, value } = e.target;
    if (name === "planAmount" || name === "itemsLimit" || name === "emailsLimit" || name === "smsLimit" || name === "locationLimit") {
      value.replace(/\D/g, "");
    }
    setCustomForm({ ...customForm, [name]: value });
  };

  const handleSelectPlanChange = (e, name) => {
    if (name === "themes") {
      setCustomForm({ ...customForm, [name]: e });
    }
    setCustomForm({ ...customForm, [name]: e });
  };
  const handleSelectModuleChange = (id, name) => {
    setCustomForm((prevState) => ({
      ...prevState,
      modules: [...prevState.modules, id],
    }));
  };
  const fetchModulesData = async () => {
    try {
      const data = await getParent.request(0);
      let val = data.data.map((item) => {
        return { ...item, checked: modulesAlwaysChecked.includes(item.name), disabled: modulesAlwaysChecked.includes(item.name), submodulesArray: item.submodulesArray.map((submodule) => ({ ...submodule, checked: modulesAlwaysChecked.includes(submodule.name) })) };
      });
      setModulesOptions(val);
    } catch (error) {
      console.log(error);
    }
  };
  const getItemsPrices = async () => {
    getPricess
      .request()
      .then((res) => {
        setPrices(res.data.data.consumableItems);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const fetchSubModulesData = async (id) => {
    setP_id(id);
    // setSubModulesOptions(modulesOptions[id].submodulesArray);
    // try {
    //   const data = await getSubModules.request(id);
    //   setSubModulesOptions(data.data.data.map((item) => ({ label: item.name, value: item.id })));
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    setCustomForm({ ...customForm, planAmount: customPrices.secondaryUserTotal + customPrices.marketplaceThemeTotal + customPrices.smsTotal + customPrices.emailTotal + customPrices.locationLimitTotal + customPrices.itemsTotal + customPrices.modulesTotal + customPrices.themesTotal });
  }, [customPrices]);

  const getShops = async () => {
    return new Promise((resolve, reject) => {
      dropdownShops
        .request()
        .then((res) => {
          const result = res.data.filter((cat) => cat.shop_name.concat("-", cat.shop_name).toLowerCase().includes(filterInput.toLowerCase()));
          const getData = result.map((cat) => ({
            value: cat,
            label: (
              <>
                <strong className="fs-12" name={cat.shop_name}>
                  {cat.shop_name}
                </strong>
                <p className="fs-10 text-lowercase">
                  {cat.whatsapp} <br />
                  {/* {cat.email} */}
                  {cat.username}
                </p>
              </>
            ),
            name: cat.shop_name,
          }));
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };

  const getThemes = async () => {
    await FetchAllThemes.request().then((res) => {
      setTheme(res?.data?.data);
    });
  };
  const ThemeOptions = theme?.map((cat) => ({ value: cat.id, label: cat.theme_name, price: cat.price }));

  useEffect(() => {
    if (customForm?.planType?.value === "custom" && Object.values(customForm?.shop).length > 0) {
      var name = "CP_" + customForm.shop?.value?.id;
      checkCustomPlan.request({ planName: name }).then((res) => {
        setplanName(name + "_" + (res.data.length + 1));
      });
    }
  }, [customForm, shop]);

  //purchase

  useEffect(() => {
    getThemes();
    fetchModulesData();
    getItemsPrices();
  }, []);

  // submit form

  const extractIds = (data) => {
    const ids = [];
    data.forEach((mainObj) => {
      if (mainObj.checked) {
        ids.push(mainObj.id);
      }
      mainObj.submodulesArray.forEach((submodule) => {
        if (submodule.checked) {
          ids.push(submodule.id);
        }
      });
    });
    return ids;
  };
  const handleSubmit = async (e) => {
    // Extracting values from each array of objects

    const finalObject = {
      plan: {
        plan_name: planName,
        plan_type: customForm.planType.value,
        plan_amount: customForm.planAmount,
        ...(modulesOptions.find((module) => module.name === "Marketplace")?.checked && { marketplace_items_limit: customForm.itemsLimit }),
        emails_limit: customForm.emailsLimit,
        locations_limit: customForm.locationLimit,
        sms_limit: customForm.smsLimit,
        monthly_grand_total: Math.round(Number(customForm?.planAmount)),
        quarterly_grand_total: Math.round(quarterlyTotal),
        yearly_grand_total: Math.round(yearlyTotal),
        secondary_users_limit: customForm.secondaryUser,
      },
      modules: extractIds(modulesOptions),
      ...(modulesOptions.find((module) => module.name === "Marketplace")?.checked && { themes: customForm.themes?.map((cat) => cat.value) }),
    };
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    let valid = Object.values(finalObject.plan).some((item) => {
      if (item === "" || item.length === 0) {
        return true;
      } else {
        return false;
      }
    });
    if (!valid) {
      try {
        await addplan.request(finalObject);
        e.preventDefault();
        toast.success("plan Added Successfully", {
          position: "bottom-right",
          autoClose: 1500,
        });
        navigate("/subscription/plans");
      } catch (error) {
        setError(error.response.data.error.message);
      }
    }
    setValidatedVendor(true);
  };

  const handleCheckboxChange = (e, name) => {
    e.stopPropagation();
    const isChecked = e.target.checked;
    setModulesOptions((prev) =>
      prev.map((item, i) => {
        if (item.name === name) {
          // Update custom prices based on the checkbox stat

          // Update the checked state of the item
          return { ...item, checked: isChecked };
        }
        return item;
      }),
    );

    if (!isChecked) {
      // Uncheck all submodules when the parent module is unchecked
      setModulesOptions((prev) => {
        return prev.map((item, i) => {
          if (item.name === name) {
            return {
              ...item,
              submodulesArray: item.submodulesArray.map((subItem) => ({
                ...subItem,
                checked: false,
              })),
            };
          }
          return item;
        });
      });
    }
  };

  const handleSubmoduleCheckboxChange = (e, name, pName) => {
    const parentModule = modulesOptions.find((item) => item.name === pName);
    if (parentModule?.checked ? parentModule?.checked : false) {
      setModulesOptions((prevModulesOptions) => {
        return prevModulesOptions.map((module, i) => {
          if (module.name === pName) {
            return {
              ...module,
              submodulesArray: module.submodulesArray.map((item, subIndex) => {
                if (item.name === name) {
                  return { ...item, checked: e.target.checked };
                }
                return item;
              }),
            };
          }
          return module;
        });
      });
    } else {
      alert("Please select Parent Module first");
    }
  };

  const calculate = () => {
    let total = 0;
    modulesOptions.forEach((module) => {
      if (module.checked) {
        total = total + Number(module.price);
        module.submodulesArray.forEach((subModule) => {
          if (subModule.checked) {
            total = total + Number(subModule.price);
          }
        });
      }
    });
    customForm.themes.forEach((theme) => {
      total = total + Number(theme.price);
    });
    const limitNameMapping = {
      itemsLimit: "marketplace_items_limit",
      smsLimit: "sms_limit",
      emailsLimit: "emails_limit",
      locationLimit: "locations_limit",
      secondaryUser: "secondary_users_limit",
      // Add other mappings if needed
    };

    Object.keys(limitNameMapping).map((val) => {
      const limitName = limitNameMapping[val];
      if (limitName) {
        prices.forEach((price) => {
          if (price.name === limitName) {
            const newAmount = price.per_unit_price * customForm[val];
            total = total + newAmount;
          }
        });
        // const newAmount = prices[limitName].per_unit_price * customForm[val];
        // total = total + newAmount;
      }
    });

    setCustomForm((prev) => ({ ...prev, planAmount: total }));
    if (customForm.planType?.value !== "trial") {
      setQuarterlyTotal(Number(total) * 3 * 0.9);
      setYearlyTotal(Number(total) * 12 * 0.8);
      setMonthlyTotal(Number(total));
    }
  };

  return (
    <>
      <AddForm>
        <div className="d-flex justify-content-between align-items-center px-5">
          <h5>Add Plan</h5>
        </div>
        <hr />
        <div className={`${style.formMain}`}>
          <Form noValidate validated={validatedVendor} onSubmit={handleSubmit}>
            {error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}
            <hr className={`${style.borderedHr}`} />

            <Row className="">
              <Col className="py-2" md={6}>
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>
                    Plan Name<span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={planName}
                    className="p-2"
                    onChange={(e) => {
                      setplanName(e.target.value);
                      // handlePlanChange(e);
                    }}
                    name="planName"
                    type="text"
                    placeholder="e.g. XYZ "
                    required
                    disabled={customForm?.planType?.value === "custom" && Object.values(customForm?.shop).length > 0}
                  />
                  <Form.Control.Feedback type="invalid">Please Enter Plan Name.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col className="py-2" md={6}>
                <Form.Group>
                  <Form.Label>
                    Plan Type <span className="text-danger">*</span>
                  </Form.Label>
                  <SearchableSelect
                    multiselect={false}
                    placeholder="Select"
                    searchable={false}
                    options={[
                      // { value: "trial", label: "trial" },
                      { value: "fixed", label: "fixed" },
                      { value: "custom", label: "Custom" },
                    ]}
                    handleChange={(e) => handleSelectPlanChange(e, "planType")}
                  />
                  <Form.Control value={customForm.planType} onChange={() => {}} hidden required />
                  <Form.Control.Feedback type="invalid">Please select Plan Type.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              {customForm?.planType?.value === "custom" && (
                <Col md={6} className="py-2">
                  <Form.Group className="w-100" controlId="formBasicShopName">
                    <label>
                      Shop<span className="text-danger">*</span>
                    </label>
                    <ReactSelect
                      placeholder="Select shop"
                      value={customForm?.shop}
                      searchable={true}
                      required
                      options={getShops}
                      handleChange={(e) => {
                        handleSelectPlanChange(e, "shop");
                        // setShop({
                        //   value: e.value,
                        //   label: e.name,
                        // });
                      }}
                      onInputChange={(newInput) => {
                        const inputValue = newInput.replace(/\W/g, "");
                        setFilterInput(inputValue);
                        return inputValue;
                      }}
                    />
                    <Form.Control className="p-2" name="shop" hidden type="text" value={Object.keys(customForm?.shop)?.length > 0 ? customForm?.shop?.value : null} onChange={() => {}} required />
                    <Form.Control.Feedback type="invalid">* Please Select Shop</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}
              <Col className="py-2" lg="6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label className="mb-1">
                    Secondary User <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    required
                    onChange={(e) => {
                      handlePlanChange(e);
                    }}
                    placeholder="Enter secondary user"
                    name="secondaryUser"
                    value={customForm.secondaryUser}
                  />
                </Form.Group>
              </Col>

              <Col lg="12" className="mb-2 ">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <Form.Label> Associated Modules</Form.Label>
                  <div className="d-flex gap-3 flex-wrap">
                    {modulesOptions
                      .filter((item) => !modulesNotShow.includes(item.name))
                      .map((item, index) => (
                        <div style={{ border: "1px solid aliceblue" }} className="d-flex flex-column  ">
                          <div style={{ maxHeight: "50px" }} onClick={() => fetchSubModulesData(index)} className=" mb-2 d-flex gap-3 p-2 bg-light align-items-center ">
                            <Form.Check
                              key={index}
                              className={""}
                              onChange={(e) => {
                                handleCheckboxChange(e, item?.name);
                              }}
                              name={item?.name}
                              checked={item.checked}
                              required={modulesOptions.filter((item) => item.checked).length === 0}
                              disabled={item?.disabled}
                              type="checkbox"
                              id={`${index}`}
                            />
                            <label
                            //  htmlFor={`${index}`}
                            >
                              {item.name}
                            </label>
                          </div>
                          <Form.Group className="w-100" controlId="formBasicShopName">
                            <div className="d-grid ms-2 ">
                              {item?.submodulesArray
                                ?.filter((item) => !modulesNotShow.includes(item.name))
                                .map((sItem, i) => (
                                  <Form.Check
                                    key={i}
                                    className={""}
                                    onChange={(e) => {
                                      handleSubmoduleCheckboxChange(e, sItem.name, item.name);
                                    }}
                                    required={modulesOptions.filter((item) => item.checked).length === 0}
                                    name={sItem.name}
                                    checked={sItem.checked}
                                    type="checkbox"
                                    id={`${index}-${sItem.name}`}
                                    label={`${sItem.name}`}
                                  />
                                ))}
                            </div>
                          </Form.Group>
                        </div>
                      ))}
                  </div>
                </Form.Group>
              </Col>
              {/* <Col lg="12" className="mb-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <Form.Label> Associated Sub-Modules</Form.Label>

                  <div className="d-grid gap-3">
                    {modulesOptions[p_id]?.submodulesArray?.map((item, index) => (
                      <Form.Check
                        key={index}
                        className={""}
                        onChange={(e) => {
                          handleSubmoduleCheckboxChange(e, index);                
                        }}
                        name={item.name}
                        checked={item.checked}
                        type="checkbox"
                        id={`${index}-${p_id}`}
                        label={`${item.name}`}
                      />
                    ))}

                  </div>
                </Form.Group>
              </Col> */}

              {modulesOptions.find((module) => module.name === "Marketplace")?.checked && (
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <Form.Label> Associated Themes</Form.Label>
                  <SearchableSelect
                    searchable={false}
                    multiselect={true}
                    value={customForm.themes}
                    options={ThemeOptions}
                    name=""
                    handleChange={(e) => handleSelectPlanChange(e, "themes")}
                    // options={}
                  />
                  <Form.Control value={customForm.themes} onChange={() => {}} hidden required />
                  <Form.Control.Feedback type="invalid">Please select Theme.</Form.Control.Feedback>
                </Form.Group>
              )}

              {modulesOptions.find((module) => module.name === "Marketplace")?.checked && (
                <Col md={6} className="py-2">
                  <Form.Group className="w-100" controlId="formBasicShopName">
                    <label>
                      MarketPlace Items Limit <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      value={customForm.itemsLimit}
                      name="itemsLimit"
                      onChange={(e) => {
                        handlePlanChange(e);
                        // setItemsLimit(e.target.value.replace(/\D/g, ""));
                      }}
                      type="number"
                      placeholder="eg.1000"
                      required
                    />
                    <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )}

              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>
                    Emails Limit <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={customForm.emailsLimit}
                    name="emailsLimit"
                    onChange={(e) => {
                      handlePlanChange(e);
                      // setEmailsLimit(e.target.value.replace(/\D/g, ""));
                    }}
                    type="number"
                    placeholder="eg.1000"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>
                    Locations Limit <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={customForm.locationLimit}
                    name="locationLimit"
                    onChange={(e) => {
                      handlePlanChange(e);
                      // setLocationLimit(e.target.value.replace(/\D/g, ""));
                    }}
                    type="number"
                    placeholder="eg.1000"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-2" md={6}>
                <Form.Group className="mb-3" controlId="price">
                  <label>
                    Sms Limit <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={customForm.smsLimit}
                    name="smsLimit"
                    onChange={(e) => {
                      handlePlanChange(e);
                      // setSmsLimit(e.target.value.replace(/\D/g, ""));
                    }}
                    type="number"
                    placeholder="eg.1000"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12" className="py-2 d-flex justify-content-center ">
                <Button onClick={calculate} className="w-75" variant="secondary">
                  Calculate
                </Button>
              </Col>
              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <Form.Label>
                    Plan Amount<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    value={customForm.planAmount}
                    name="planAmount"
                    onChange={(e) => {
                      // setPlanAmount(e.target.value.replace(/\D/g, ""));
                      handlePlanChange(e);
                    }}
                    type="number"
                    placeholder="Rs.1000"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-2" md={6}>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>
                    Yearly Total<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control value={Math.round(yearlyTotal)} type="number" placeholder="Rs.1000" disabled />
                  <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-2" md={6}>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>
                    Monthly Total <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control value={Math.round(monthlyTotal)} type="number" placeholder="Rs.1000" disabled />
                  <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="py-2">
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>
                    Quarterly Total <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control value={Math.round(quarterlyTotal)} type="number" placeholder="Rs.1000" disabled />
                  <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="py-3">
              <Col>
                <Button type="submit" className="w-100">
                  Add Plan
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </AddForm>
    </>
  );
};

export default AddPlans;
