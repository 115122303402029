import { useState } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";

import { useEffect } from "react";
const ConfirmationModal = ({ showConfirmationModal, isClicked, submit, planAmount, remaining, arrears, discount, transactionId, transDate, billingPeriod, paidAmount, planType, image, payable, setShowConfirmationModal }) => {
  useEffect(() => {
    // const reader = new FileReader();
    // reader.readAsDataURL(image.files);
  }, []);

  return (
    <>
      <Modal
        className="border"
        size="lg"
        show={showConfirmationModal}
        onHide={() => {
          setShowConfirmationModal(false);
        }}
        animation={true}
        centered
        backdrop="static"
      >
        <Modal.Header style={{ background: "aliceblue" }}>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4"></div>
          <div>
            <span className=" d-flex justify-content-around pb-4">
              <div className="">
                {" "}
                <div className="d-flex">
                  <strong>Plan Type: </strong>
                  <p className="ms-1"> {planType}</p>
                </div>
                <div className="d-flex">
                  <strong>Plan Amount: </strong>
                  <p className="ms-1"> {planAmount}</p>
                </div>
                <div className="d-flex">
                  <strong>Arrear: </strong>
                  <p className="ms-1"> {arrears}</p>
                </div>
                <div className="d-flex">
                  <strong>Discount: </strong>
                  <p className="ms-1"> {discount}</p>
                </div>
              </div>
              <div>
                <div className="d-flex ">
                  <strong>Transaction Id: </strong>
                  <p className="ms-1"> {transactionId}</p>
                </div>
                <div className="d-flex ">
                  <strong>Transaction Date: </strong>
                  <p className="ms-1"> {transDate}</p>
                </div>
                <div className="d-flex ">
                  <strong>Billing Period: </strong>
                  <p className="ms-1"> {billingPeriod}</p>
                </div>
                <div className="d-flex ">
                  <strong>Amount Paid: </strong>
                  <p className="ms-1"> {paidAmount}</p>
                </div>
                <div className="d-flex ">
                  <strong>Amount Payable: </strong>
                  <p className="ms-1"> {payable}</p>
                </div>
              </div>
              <div>
                <img height={250} width={220} src={image} />
              </div>
            </span>
            <div className="d-flex justify-content-center">
              <div className="d-flex ">
                <strong>Remaining Amount: </strong>
                <strong className="ms-1"> {remaining}</strong>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "aliceblue" }}>
          <div className="d-flex gap-3">
            <Button
              className="w-100 rounded-3"
              onClick={() => {
                setShowConfirmationModal(false);
              }}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button onClick={submit} className="w-100 rounded-3" variant={"primary"} disabled={isClicked}>
              submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
