import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import PopUpModal from "../../Components/Modal";
import { toast } from "react-toastify";
const ShopFeatures = () => {
  const { user } = useSelector((state) => state.auth);
  const Changed4uStatusCall = useApi(postapi.Changed4uStatus, { hasCatchError: true });
  const fetchAllShopsCall = useApi(api.fetchAllShops, { hasCatchError: true });
  let [shops, setShops] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ shop_registered_at: "DESC" });
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = useState({ value: null, label: "All" });
  const [d4u, setD4u] = useState({ value: undefined, label: "All" });
  const [error, setError] = useState("");
  const [enableDisableModalShow, setEnableDisableModalShow] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [shopData, setShopData] = useState({});

  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setStatus({ value: null, label: "All" });
    setD4u({ value: undefined, label: "All" });
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const fetchAllShopsRequests = async () => {
    try {
      const res = await fetchAllShopsCall.request({
        size: sizeOfPages,
        page: currentPage,
        search: searchInput,
        orderBy: orderBy,
        status: status.value,
        d4u: d4u.value,
      });
      const data = res?.data?.data;
      setIsLoading(false);
      if (data) {
        setShops(data.shops);
        setCount(data?.pagination?.no_of_items);
        if (data?.pagination?.no_of_pages === 0) {
          setNoOfPages(0);
        } else {
          setNoOfPages(data?.pagination?.no_of_pages);
          if (currentPage > data?.pagination?.no_of_pages) {
            setCurrentPage(data?.pagination?.no_of_pages);
          }
        }
      } else {
        setShops([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllShopsRequests();
  }, [currentPage, sizeOfPages, searchInput, toggle, status, orderBy, d4u]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [searchInput, status, d4u]);
  const handleStatus = (e) => {
    if (e.label === "All") {
      setStatus({ value: null, label: "All" });
    } else {
      setStatus({ value: e.value, label: e.label });
    }
  };

  const handled4uStatus = (e) => {
    if (e.label === "All") {
      setD4u({ vale: undefined, label: e.label });
    } else {
      setD4u({ value: e.value, label: e.label });
    }
  };

  const handleEnableDisable = (data) => {
    setEnableDisableModalShow(true);
    setShopData(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsClicked(true);
      const response = await Changed4uStatusCall.request({ id: shopData["shop_id"], active: shopData["is_d4u_enabled"] === 0 ? true : false });
      if (response.status === 200) {
        toast.success(`${response?.data?.data?.message}`, {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        refresh((prev) => !prev);
        close();
      }
    } catch (error) {
      setError(error.response.data.error.message);
      refresh((prev) => !prev);
    }
  };
  const close = () => {
    setEnableDisableModalShow(false);
    setShopData({});
    setError(null);
    setIsClicked(false);
  };
  return (
    <>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              value={status}
              searchable={false}
              options={[
                { value: null, label: "All" },
                { value: "paid", label: "Paid" },
                { value: "trial", label: "Trial" },
              ]}
              handleChange={handleStatus}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Dru shops"
              value={d4u}
              searchable={false}
              options={[
                { value: undefined, label: "All" },
                { value: "true", label: "Enabled" },
                { value: "false", label: "Disabled" },
              ]}
              handleChange={handled4uStatus}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : shops.length === 0 ? (
            <NoResult name="Shops" />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview
                data={shops}
                columnNotShow={["is_d4u_enabled"]}
                sortColumn={["shop_id", "shop_name", "username", "shop_email", "shop_registered_at"]}
                datetimeColumn={["shop_registered_at"]}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                formattedColumn={{
                  heading: "D4U Enabled",
                  style: (row) => {
                    const isd4uEnabled = row["is_d4u_enabled"];
                    if (isd4uEnabled === 1) {
                      return "text-success";
                    } else {
                      return "text-danger";
                    }
                  },
                  format: (row) => {
                    const isd4uEnabled = row["is_d4u_enabled"];
                    if (isd4uEnabled === 1) {
                      return "Enabled";
                    } else {
                      return "Disabled";
                    }
                  },
                }}
                toggleActionButton={
                  (user.user_type === "super-admin" || user.user_type === "admin") && {
                    label: (data) => {
                      if (data["is_d4u_enabled"] === 1) {
                        return "Disable";
                      } else {
                        return "Enable";
                      }
                    },
                    onClick: (data) => {
                      handleEnableDisable(data);
                    },
                    variant: (data) => {
                      if (data["is_d4u_enabled"] === 1) {
                        return "outline-danger";
                      } else {
                        return "outline-primary";
                      }
                    },
                    style: { padding: "8px", fontSize: "12px" },
                    column: "is_d4u_enabled",
                  }
                }
              />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
        </>
        <PopUpModal title={`${shopData["is_d4u_enabled"] === 1 ? "Disable" : "Enable"} Confirmation`} show={enableDisableModalShow} onHide={close} closeButton={false}>
          <>
            {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

            <div>
              <span className="d-flex justify-content-center pb-4">
                Are you sure you want to {shopData["is_d4u_enabled"] === 1 ? "Disable" : "Enable"} "Deliver for You" feature for {shopData["shop_name"]}?
              </span>
            </div>
            <div className="d-flex gap-3">
              <span
                className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
                style={{
                  cursor: "pointer",
                  border: "2px solid rgba(151, 156, 162, 1)",
                  borderRadius: "6px",
                }}
                onClick={() => close()}
              >
                Cancel
              </span>
              <Button className="w-100" onClick={handleSubmit} disabled={isClicked}>
                {shopData["is_d4u_enabled"] === 1 ? "Disable" : "Enable"}
              </Button>
            </div>
          </>
        </PopUpModal>
      </Container>
    </>
  );
};

export default ShopFeatures;
