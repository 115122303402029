const formatNumber = (number) => {
    const suffixes = ["", "K", "M", "B", "T"];
    const tier = Math.log10(Math.abs(number)) / 3 | 0;
  
    if (tier === 0) return number;
  
    const suffix = suffixes[tier];
    const scale = Math.pow(10, tier * 3);
  
    const scaledNumber = number / scale;
  
    // Limit to 1 decimal place if less than 10, otherwise, round to nearest integer
    const formattedNumber = scaledNumber < 10 ? scaledNumber.toFixed(1) : Math.round(scaledNumber);
  
    return formattedNumber + ' '+ suffix;
  };
  
  export { formatNumber };