import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";

import SearchableSelect from "../../Components/SearchableSelect";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import Requests from "../../Components/Request/Request";
import PopUpModal from "../../Components/Modal";
import AddRequest from "../../Components/Request/AddRequest";
import moment from "moment";
import DatePicker from "../../Components/DatePicker/DatePicker";

const RequestPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [showReqModal, setReqModal] = useState(false);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [statusType, setStatusType] = useState(
    user.user_type === "super-admin"
      ? {
          value: null,
          label: "All",
        }
      : {
          value: "in_progress",
          label: "InProgress",
        }
  );
  const [priorityfilter, setPriorityFilter] = useState(
    user.user_type === "super-admin"
      ? {
          value: null,
          label: "All",
        }
      : {
          value: "high",
          label: "high",
        }
  );
  const [addUserShow, setAddUserShow] = useState(true);
  const [refresh, toggle] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userTypeOptions, setUserTypeOptions] = useState([
    { value: "super-admin", label: "Super Admin" },
    { value: "admin", label: "Admin" },
    { value: "marketing", label: "marketing" },
    { value: "data", label: "data" },
  ]);
  const [status, setStatus] = useState({ value: true, label: "Active" });
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  // shops on  payment

  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({ plan_id: "ASC" });
  // INCOMPLETE ------------------------------------

  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const resetFilterHandle = () => {
    if (user.user_type === "super-admin") {
      setStatusType({
        value: null,
        label: "All",
      });
      setPriorityFilter({
        value: null,
        label: "All",
      });
      setSearchInput("");
      setFromDate(moment().subtract(29, "days")._d);
      setToDate(moment().endOf("day")._d);
    } else {
      setStatusType({
        value: "in_progress",
        label: "InProgress",
      });
      setPriorityFilter({
        value: "high",
        label: "high",
      });
      setSearchInput("");
    }
  };
  useEffect(() => {}, [refresh]);

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };
  const addRequest = () => {
    setReqModal(true);
  };
  const handleStatusChange = (e) => {
    setStatusType(e);
  };
  const handlePriorityChange = (e) => {
    setPriorityFilter(e);
  };
  return (
    <>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>

          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              defaultValue={statusType}
              value={statusType}
              searchable={false}
              options={
                user.user_type === "super-admin"
                  ? [
                      { value: null, label: "All" },
                      { value: "pending", label: "pending" },
                      { value: "in_progress", label: "InProgress" },
                      { value: "closed", label: "closed" },
                    ]
                  : [
                      { value: "pending", label: "pending" },
                      { value: "in_progress", label: "InProgress" },
                      { value: "closed", label: "closed" },
                    ]
              }
              handleChange={handleStatusChange}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              defaultValue={priorityfilter}
              value={priorityfilter}
              searchable={false}
              options={
                user.user_type === "super-admin"
                  ? [
                      { value: null, label: "All" },
                      { value: "high", label: "high" },
                      { value: "medium", label: "medium" },
                      { value: "low", label: "low" },
                    ]
                  : [
                      { value: "high", label: "high" },
                      { value: "medium", label: "medium" },
                      { value: "low", label: "low" },
                    ]
              }
              handleChange={handlePriorityChange}
            />
          </Col>
          {user.user_type === "super-admin" && (
            <Col xs="2" lg="2">
              <DatePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
            </Col>
          )}
          {(user.user_type === "super-admin" || user.user_type === "admin") && (
            <Col xs="2" lg="2">
              <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={addRequest}>
                <span className="d-none d-lg-inline">Add Request</span> <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          )}
          {/* For Mobile screen End */}
        </Row>

        <>
          <div className="pt-2">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Requests
                search={searchInput}
                status={status}
                handleby={user.user_type === "super-admin" ? null : statusType.value === "pending" ? null : user.user_id}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                sizeOfPage={sizeOfPages}
                userType={userType}
                refresh={refresh}
                priority={priorityfilter.value}
                toggle={toggle}
                statusType={statusType.value}
                dates={user.user_type === "super-admin" ? { from: moment(fromDate).format("YYYY-MM-DD"), to: moment(toDate).format("YYYY-MM-DD") } : null}
              />
            </Col>
          </Row>
        </>
      </Container>
      <PopUpModal size="md" title="Add Request" show={showReqModal} onHide={() => setReqModal(false)}>
        <AddRequest
          close={() => setReqModal(false)}
          toggle={toggle}
          // close={closeModalAdd}
        />
      </PopUpModal>
    </>
  );
};
export default RequestPage;
