import style from "./../../styles/Sidebar.module.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const NavOption = ({ setIsSandwichOpen, isSandwichOpen, title, icon, activeIcon, path, navPath, identifier, isSubNav, active, setActive, activeParent, parentIdentifier, setActiveParent, activeCaret, setActiveCaret, subnavigation, isOpen, setIsOpen }) => {
  const location = useLocation();
  return (
    // <div className={`${style.Sidebartooltip} `}>
    <div
      style={
        location.pathname === navPath
          ? {
              background: " #f8f6ff ",
              boxShadow: " 0 0 15px rgb(0 0 0 / 0%)",
              color: "rgba(89, 50, 234, 1) !important",
              textDecoration: "none",
            }
          : !subnavigation && activeParent === identifier
          ? {
              background: " #f8f6ff ",
              boxShadow: " 0 0 15px rgb(0 0 0 / 0%)",
              textDecoration: "none",
            }
          : { color: "#545e6f", color: "#39414A", textDecoration: "none" }
      }
      id={`tooltip-anchor-${title}`}
      className={` mt-1 ${subnavigation ? (!isSandwichOpen ? style.sublink : style.sublinkClose) : style.sideLink} ${subnavigation ? style.subanimate : ""} d-flex  justify-content-between align-items-center`}
    >
      <NavLink
        to={path}
        className={`${style.subSideLink}  my-1 d-block mt-2 `}
        onClick={() => {
          if (window.innerWidth < 820) {
            setIsSandwichOpen((prev) => !prev);
          }

          setIsOpen(true);
          setActive(navPath);
          if (subnavigation) {
            setActiveCaret(parentIdentifier);
            setActiveParent(parentIdentifier);
          } else {
            setActiveParent(identifier);
            setActiveCaret(identifier);
          }
        }}
      >
        <div className={`d-flex   ${subnavigation && " "} ${isSandwichOpen ? "justify-content-center" : "gap-2 justify-content-between"} align-items-center`}>
          <div className="d-flex  align-items-center gap-2">
            {icon}
            <span className={`${active === navPath || activeParent === identifier ? style.activeColor : ""}`}> {!isSandwichOpen && title}</span>
          </div>
        </div>
        {/* {isSandwichOpen && <span className={style.tooltipClose}>{title}</span>} */}
        {isSandwichOpen && <Tooltip anchorId={`tooltip-anchor-${title}`} content={`${title}`} place="right" className={`${style.reactTooltip}`} />}
      </NavLink>
      <div
        className={`p-2 ${isSandwichOpen && "d-none"}`}
        onClick={() => {
          setActiveCaret(identifier);
          if (isOpen) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
          if (activeCaret !== identifier) {
            setIsOpen(true);
          }
        }}
      >
        {!isSandwichOpen && isSubNav && <FontAwesomeIcon icon={activeCaret === identifier && isOpen ? faChevronUp : faChevronDown} />}
      </div>
    </div>
  );
};

export default NavOption;
