import React, { useState } from "react";
import PopUpModal from "../../Components/Modal";
import { Button, Form } from "react-bootstrap";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { toast } from "react-toastify";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";

const StatusChangeConfirmationModal = ({ show, onHide, toggle, id, status }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [deliveryPersonName, setDeliveryPersonName] = useState("");
  const [deliveryPersonPhone, setDeliveryPersonPhone] = useState("");
  const changeOrderStatus = useApi(postapi.changeOrderStatus, { hasCatchError: true });
  const statusMap = {
    Confirmed: "Confirm",
    Picked: "Pick",
    Shipped: "Ship",
    Delivered: "Deliver",
    Returned: "Return",
    Cancelled: "Cancel",
  };

  const close = () => {
    setIsClicked(false);
    onHide();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var response;
      setValidated(true);
      if (status === "Picked") {
        if (deliveryPersonName !== "" && deliveryPersonPhone !== "") {
          setIsClicked(true);
          response = await changeOrderStatus.request({ id, status, postman_name: deliveryPersonName, postman_phone: deliveryPersonPhone });
        }
      } else {
        setIsClicked(true);
        response = await changeOrderStatus.request({ id, status });
      }
      if (response.status === 200) {
        toast.success(`${response?.data?.data?.message}`, {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setValidated(false);
        setDeliveryPersonName("");
        setDeliveryPersonPhone("");
        close();
        toggle();
      }
    } catch (error) {
      setError(error.response.data.error.message);
      setValidated(false);
      toggle();
    }
  };

  return (
    <PopUpModal title={`${status} Confirmation`} show={show} onHide={close} closeButton={false}>
      <>
        {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

        <div>
          <span className="d-flex justify-content-center pb-4">Are you sure you want to {statusMap[status]} this order?</span>
        </div>
        {status === "Picked" ? (
          <Form noValidate validated={validated}>
            <Form.Group>
              <label>
                Delivery Person Name<span className="text-danger">*</span>
              </label>
              <Form.Control
                type="text"
                placeholder="Delivery Person Name"
                required
                name="deliveryPersonName"
                maxLength={35}
                value={deliveryPersonName}
                onChange={(e) => {
                  setDeliveryPersonName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">Enter Delivery Person Name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="my-3">
              <label>
                Delivery Person Phone<span className="text-danger">*</span>
              </label>
              <PhoneInput placeholder="+92-3XX-XXXXXXX" value={deliveryPersonPhone} onChange={(e) => setDeliveryPersonPhone(e.target.value)} required />
              <Form.Control.Feedback type="invalid">* Please Enter Delivery Person Phone Number</Form.Control.Feedback>
            </Form.Group>
          </Form>
        ) : (
          <></>
        )}
        <div className="d-flex gap-3">
          <span
            className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
            style={{
              cursor: "pointer",
              border: "2px solid rgba(151, 156, 162, 1)",
              borderRadius: "6px",
            }}
            onClick={() => close()}
          >
            Cancel
          </span>
          <Button className="w-100" onClick={handleSubmit} disabled={isClicked}>
            {statusMap[status]}
          </Button>
        </div>
      </>
    </PopUpModal>
  );
};

export default StatusChangeConfirmationModal;
