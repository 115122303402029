import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationsCount: 0,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationCount(state, action) {
      state.notificationsCount = action.payload.unreadNotificationsCount;
    },
  },
});
export const notificationActions = notificationSlice.actions;
export default notificationSlice.reducer;
