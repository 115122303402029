import { Editor } from "@tinymce/tinymce-react";
import { Form } from "react-bootstrap";

const Markdown = ({
  value,
  onInit,
  onEditorChange,
  setValue,
  setText,
  placeholder,
  label,
}) => {
  return (
    <Form.Group className="mb-3">
      {label ? (
        <Form.Label className="d-flex justify-content-start">
          {label}
          <span style={{ color: "red" }}>*</span>
        </Form.Label>
      ) : (
        <Form.Label>Description</Form.Label>
      )}
      <Editor
        apiKey="v1oaw3yn9un79c63uodd74j2ecckl6kpejp444c7divbpm6m"
        onEditorChange={(val, editor) => {
          setValue(val);
          setText(editor.getContent({ format: "text" }));
        }}
        value={value}
        init={{
          max_chars: 10,
          placeholder: placeholder ? placeholder : "Write product details",
          height: 300,
        }}
      />
    </Form.Group>
  );
};

export default Markdown;
