import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import style from "../../styles/AddInventory.module.css";

import * as post from "../../api/postapi";
import axios from "axios";
import { toast } from "react-toastify";

const EditThemeForm = ({ id, close, toggle }) => {
  const [themeName, setThemeName] = useState("");
  const [Thumbnail, setThumbnail] = useState(null);
  const [Description, setDescription] = useState("");
  const [noOfsections, setNoOfSections] = useState(null);
  const [maxPerSection, setMaxPerSection] = useState(null);
  const [PrimaryBannerLimit, setPrimaryBannerLimit] = useState(0);
  const [SecondaryBannerLimit, setSecondaryBannerLimit] = useState(0);
  const [isTopCategories, setIsTopCategories] = useState(false);
  const [validated, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchTheme = useApi(api.fetchTheme);
  const EditTheme = useApi(post.EditTheme);
  const fileInputRef = useRef(null); // Ref to file input element

  const fetchThemeById = async (id) => {
    const data = await fetchTheme.request(id);
    setThemeName(data.data.data[0].theme_name);
    setDescription(data.data.data[0].description);
    setNoOfSections(data.data.data[0].num_sections);
    setMaxPerSection(data.data.data[0].max_products_per_section);
    setPrimaryBannerLimit(data.data.data[0].primaryBannerLimit);
    setSecondaryBannerLimit(data.data.data[0].secondaryBannerLimit);
    setIsTopCategories(data.data.data[0].isTopCategories);
    setThumbnail(data.data.data[0].thumbnail);
  };

  const handleUpload = async () => {
    if (!Thumbnail) {
      throw new Error("Please select an image.");
    }

    const formData = new FormData();
    formData.append("file", Thumbnail);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images/thumbnails`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response; // Return the URL of the uploaded image
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidate(true);
    if (!themeName || !Thumbnail || !Description || !noOfsections || !maxPerSection || noOfsections < 0 || maxPerSection < 0 || PrimaryBannerLimit < 0 || SecondaryBannerLimit < 0) {
      // toast.error("Please fill in all required fields.",{position: "bottom-right",
      // autoClose: 1500,});
      return;
    }
    try {
      const uploadedThumbnailUrl = await handleUpload();

      await EditTheme.request(id, {
        theme_name: themeName,
        description: Description,
        num_sections: noOfsections,
        max_products_per_section: maxPerSection,
        primaryBannerLimit: PrimaryBannerLimit,
        secondaryBannerLimit: SecondaryBannerLimit ? SecondaryBannerLimit : 0,
        isTopCategories: isTopCategories,
        thumbnail: uploadedThumbnailUrl.data.files[0],
      });
      close();
      toast.success("Theme Updated Successfully", { position: "bottom-right", autoClose: 1500 });
      toggle((prev) => !prev);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchThemeById(id);
  }, [toggle]);

  //    useEffect(()=>{
  //        fetchThemeById(id)
  //    }[toggle])

  return (
    <Form noValidate onSubmit={handleSubmit} validated={validated}>
      <hr className={`${style.borderedHr}`} />
      <Row>
        <Col className="py-2" md={6}>
          <Form.Group className="w-100" controlId="formThemeName">
            <Form.Label>
              Theme Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control name="theme_name" type="text" placeholder="Theme Name" required value={themeName} onChange={(e) => setThemeName(e.target.value)} />
            <Form.Control.Feedback type="invalid">Please enter a theme name.</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={6} className="py-2">
          <Form.Group className="mt-3" as={Col} lg="12" md="12" sm="12" controlId="formBasicShopName">
            <label>Upload Thumbnail</label>
            <Form.Control ref={fileInputRef} type="file" size="sm" accept="image/*" name="image" onChange={(e) => setThumbnail(e.target.files[0])} />
            <a href={process.env.REACT_APP_URL_S3 + "/" + Thumbnail} target="_blank" rel="noreferrer">
              Preview
            </a>
            <Form.Control.Feedback type="invalid">Please select an image.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="py-2">
          <Form.Group className="w-100">
            <Form.Label>
              Theme Description<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control name="theme_description" as="textarea" rows={2} placeholder="Theme Description" required value={Description} onChange={(e) => setDescription(e.target.value)} maxLength={200} />
            <Form.Control.Feedback type="invalid">Please enter a theme description.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="py-2" md={4}>
          <Form.Group className="w-100">
            <Form.Label>
              Number of Sections <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control name="theme_section" type="number" placeholder="Number of Sections" min={0} required value={noOfsections || ""} onChange={(e) => setNoOfSections(parseInt(e.target.value) || null)} />
            <Form.Control.Feedback type="invalid">Please enter a valid number of sections.</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className="py-2" md={4}>
          <Form.Group className="w-100">
            <Form.Label>
              Max Products Per Section <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control name="theme_max_product" type="number" placeholder="Max Products Per Section" min={0} required value={maxPerSection || ""} onChange={(e) => setMaxPerSection(parseInt(e.target.value) || null)} />
            <Form.Control.Feedback type="invalid">Please enter a valid number of products per section.</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className="py-2 mt-4 d-flex align-items-center" md={4}>
          <Form.Group controlId="formTopCategories" className="mb-0">
            <Form.Check type="checkbox" label="Top Categories" value={isTopCategories} checked={isTopCategories ? true : false} onChange={(e) => setIsTopCategories(e.target.checked)} />
            {/* <Form.Control.Feedback type="invalid">Please select top categories.</Form.Control.Feedback> */}
          </Form.Group>
        </Col>
      </Row>

      <Row className="">
        <Col className="py-2" md={6}>
          <Form.Group className="w-100">
            <Form.Label>
              Primary Banner Limit <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control name="theme_section" type="number" placeholder="Primary Banner Limit" min={0} required value={PrimaryBannerLimit} onChange={(e) => setPrimaryBannerLimit(parseInt(e.target.value) || null)} />
            <Form.Control.Feedback type="invalid">Please enter a valid primary banner limit.</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className="py-2" md={6}>
          <Form.Group className="w-100">
            <Form.Label>Secondary Banner Limit </Form.Label>
            <Form.Control name="theme_max_product" type="number" placeholder="Secondary Banner Limit" min={0} required value={SecondaryBannerLimit} onChange={(e) => setSecondaryBannerLimit(parseInt(e.target.value) || null)} />
            <Form.Control.Feedback type="invalid">Please enter a valid secondary banner limit.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <Button type="submit" className="w-100" disabled={loading || !themeName || !Description || !noOfsections || !maxPerSection || !Thumbnail || !PrimaryBannerLimit}>
            {loading ? "Submitting..." : "Edit Theme"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditThemeForm;
