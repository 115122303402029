import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "./Cookies";
import authService from "./authService";
const initialState = {
  user: null,
  error: false,
  message: "",
};
// Login User
export const login = createAsyncThunk(
  process.env.REACT_APP_API_URL + "/auth/login",
  async (user, thunkApi) => {
    try {
      const res = await authService.login(user);
      if (res.user_type === "admin" || res.user_type === "super-admin") {
        return res;
      } else {
        return thunkApi.rejectWithValue("Invalid email/password!");
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  },
);
export const currentUser = createAsyncThunk(
  process.env.REACT_APP_API_URL + "/user/get-tech-user-by-accesstoken",
  async (refreshToken, thunkApi) => {
    try {
      const res = await authService.getUser(refreshToken);
      if (res.user_type === "admin" || res.user_type === "super-admin") {
        return res;
      } else {
        return thunkApi.rejectWithValue("Invalid email/password!");
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user.user;
    },
    logout: (state, action) => {
      state.user = null;
      state.error = false;
      state.message = "";
      localStorage.removeItem("refreshToken");
      if (process.env.REACT_APP_ENV === "development") {
        Cookies.remove("ad_token");
      } else if (process.env.REACT_APP_ENV === "staging") {
        Cookies.remove("ad_token", { domain: ".techbazaar.pk" });
      } else if (process.env.REACT_APP_ENV === "uat") {
        Cookies.remove("aaccess", { domain: ".techbazaar.pk" });
      } else {
        Cookies.remove("ad_access", { domain: ".techbazaar.pk" });
      }
    },
    addUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.error = false;
        state.message = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.user = null;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(currentUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(currentUser.rejected, (state, action) => {
        state.user = null;
        state.error = true;
        state.message = action.payload;
      });
  },
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
