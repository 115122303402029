import React from "react";
import { Button } from "react-bootstrap";

function TrialExpireModal({ close, confirm }) {
  return (
    <>
      <div>
        <span className="d-flex justify-content-center pb-4">
          Do you really want to Expire trial?
        </span>
      </div>
      <div className="d-flex gap-3">
        <Button variant="secondary" className="w-100 rounded-3" onClick={close}>
          Cancel
        </Button>
        <Button className="w-100 rounded-3" onClick={confirm}>
          Confirm
        </Button>
      </div>
    </>
  );
}

export default TrialExpireModal;
