import React, { useState } from "react";
import PopUpModal from "../../Components/Modal";
import { Button, Form } from "react-bootstrap";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { toast } from "react-toastify";

const DeliveryForYouOrdersReasonModal = ({ show, onHide, toggle, id, status }) => {
  const [reason, setReason] = useState();
  const [error, setError] = useState("");
  const changeOrderStatus = useApi(postapi.changeOrderStatus, { hasCatchError: true });

  const close = () => {
    setReason("");
    onHide();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await changeOrderStatus.request({ id, status, reason });
      if (response.status === 200) {
        toast.success(`${response?.data?.data?.message}`, {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        close();
        toggle();
      }
    } catch (error) {
      setError(error.response.data.error.message);
      toggle();
    }
  };

  return (
    <PopUpModal title={status + " Reason"} show={show} onHide={close}>
      {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <label>Reason</label>
          <Form.Control
            required
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            as="textarea"
            rows={3}
          />
          <Form.Control.Feedback type="invalid">Fill Reason.</Form.Control.Feedback>
        </Form.Group>
        <div className="mt-2 gap-2 d-flex justify-content-end">
          <Button onClick={close} variant="outline-primary">
            Cancel
          </Button>
          <Button type="submit" variant={status === "Cancelled" ? "danger" : "secondary"}>
            {status === "Cancelled" ? "Cancel Order" : "Return Order"}
          </Button>
        </div>
      </Form>
    </PopUpModal>
  );
};

export default DeliveryForYouOrdersReasonModal;
