import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import SearchableSelect from "../../Components/SearchableSelect";
import ReactSelect from "../../Components/ReactSelect";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import ShopkeeperOnPayment from "../../Components/shopkeepers/ShopkeeperOnPayment";
import ShopKeeperOnTrial from "../../Components/shopkeepers/ShopkeeperOnTrial";

const Shopkeepers = () => {
  const [province, setProvince] = useState(null);
  const [city, setCity] = useState(null);

  const [searchInput, setSearchInput] = useState("");
  const [cityName, setCityName] = useState([]);

  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [salesman, setSalesman] = useState(null);
  const [filterInput, setFilterInput] = useState("".replace(/\W/g, ""));
  const [shopType, setShopType] = useState();
  const [status, setStatus] = useState({ value: true, label: "Active" });

  // shops on  payment

  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({ shop_name: "ASC" });

  // INCOMPLETE ------------------------------------

  // GET PROVINCE
  const provincecall = useApi(api.getProvinces);
  const cityCalll = useApi(api.getCities);
  const SalesManCall = useApi(api.getSalesman);
  const getSalesman = async () => {
    return new Promise((resolve, reject) => {
      SalesManCall.request()
        .then((res) => {
          const result = res.data.filter((cat) => cat.user_full_name.concat("-", cat.user_email).toLowerCase().includes(filterInput.toLowerCase()));
          const getData = result.map((cat) => ({
            value: cat.user_id,
            label: (
              <>
                <strong name={cat.user_full_name}>{cat.user_full_name}</strong>
                <p className="fs-14">{cat.user_email}</p>
              </>
            ),
            name: cat.user_full_name,
          }));
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  const getProvince = async () => {
    return new Promise((resolve, reject) => {
      provincecall
        .request()
        .then((res) => {
          const getData = res.data.map((cat) => ({
            value: cat.province_id,
            label: cat.province_name,
          }));

          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  useEffect(() => {
    if (province) {
      getCities(province?.value);
    }
  }, [province]);

  const getCities = async (id) => {
    return new Promise((resolve, reject) => {
      cityCalll
        .request(id)
        .then((res) => {
          const getData = res.data.map((cat) => ({
            value: cat.city_id,
            label: cat.city_name,
          }));

          setCityName(getData);
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };

  const filterProvince = (e) => {
    setCity(null);
    getProvince();
    if (e.label === "none") {
      setProvince({});
    } else {
      setProvince({ value: e.value, label: e.label });
    }
  };
  const filterCities = (e) => {
    if (e.label === "none") {
      setCity({});
    } else {
      setCity({ value: e.value, label: e.label });
    }
  };
  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const resetFilterHandle = () => {
    setCity(null);
    setProvince(null);
    setSalesman(null);
    setSearchInput("");
    setStatus({ value: true, label: "Active" });
  };

  const handleTabs = (e) => {
    // resetFilterHandle();
    setShopType(e);
    setSearchInput("");
  };
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };
  return (
    <>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs lg="2" className="d-none   d-lg-inline">
            <ReactSelect placeholder="Filter Province" value={province} searchable={false} options={getProvince} handleChange={filterProvince} />
          </Col>
          <Col xs lg="2" className="d-none d-lg-inline">
            <SearchableSelect placeholder="Filter City" disabled={!province} value={city} searchable={false} options={cityName} handleChange={filterCities} />
          </Col>
          <Col xs lg="2" className="d-none d-lg-inline">
            <ReactSelect
              placeholder="Filter Salesman"
              value={salesman}
              searchable={true}
              options={getSalesman}
              handleChange={(e) => {
                setSalesman({
                  value: e.value,
                  label: e.name,
                });
              }}
              onInputChange={(newInput) => {
                const inputValue = newInput.replace(/\W/g, "");
                setFilterInput(inputValue);
                return inputValue;
              }}
            />
          </Col>
          <Col xs lg="2" className="d-none d-lg-inline">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              defaultValue={{ value: true, label: "Active" }}
              value={status}
              searchable={false}
              options={[
                { value: true, label: "Active" },
                { value: false, label: "Inactive" },
                { value: "awaiting", label: "Awaiting Activation" },
              ]}
              handleChange={(e) => {
                setStatus(e);
              }}
            />
          </Col>

          {/* For Mobile screen Start */}
          <Col xs="6" lg="2" className="mb-2">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>

          <Col xs="2" lg="3" className="d-md-none d-inline ">
            <SearchInput searchlogo bordered />
          </Col>
          <Col className="d-md-none d-flex" xs="2" lg="2">
            <a href="#">
              <Button variant="outline-primary">
                <FontAwesomeIcon icon={faFilter} />
              </Button>
            </a>
          </Col>
          <Col xs="2" lg="2" xl="2"></Col>
          {/* For Mobile screen End */}
        </Row>

        <>
          <div className="pt-2">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Tabs defaultActiveKey="Payment" onSelect={handleTabs}>
                <Tab eventKey="Payment" title="On Payment" className="text-secondary mb-5">
                  <ShopkeeperOnPayment shopType={shopType} salesman={salesman} search={searchInput} province={province} city={city} status={status} orderBy={orderBy} setOrderBy={setOrderBy} sizeOfPage={sizeOfPages} />
                </Tab>

                <Tab eventKey="onTrial" title={`On Trial`} className="text-secondary mb-5">
                  <ShopKeeperOnTrial shopType={shopType} salesman={salesman} search={searchInput} province={province} city={city} status={status} orderBy={orderBy} setOrderBy={setOrderBy} sizeOfPage={sizeOfPages} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </>
      </Container>
    </>
  );
};
export default Shopkeepers;
