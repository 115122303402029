import React, { useRef, useState } from "react";
import AddForm from "../../Components/AddForm";
import style from "../../styles/AddInventory.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
// import { Add_Theme } from "../../api/postapi";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { useNavigate } from "react-router-dom";

const AddTheme = () => {
  const [themeName, setThemeName] = useState("");
  const [Thumbnail, setThumbnail] = useState(null);
  const [Description, setDescription] = useState("");
  const [noOfsections, setNoOfSections] = useState(null);
  const [maxPerSection, setMaxPerSection] = useState(null);
  const [PrimaryBannerLimit, setPrimaryBannerLimit] = useState();
  const navigate = useNavigate();

  const [SecondaryBannerLimit, setSecondaryBannerLimit] = useState();
  const [isTopCategories, setIsTopCategories] = useState(false);
  const [isChecked,setIsChecked]=useState(false)
  const [validated, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const AddTheme = useApi(postapi.AddTheme); // State for loading submit button

  const fileInputRef = useRef(null); // Ref to file input element

  const handleUpload = async () => {
    if (!Thumbnail) {
      throw new Error("Please select an image.");
    }

    const formData = new FormData();
    formData.append("file", Thumbnail);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images/thumbnails`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response; // Return the URL of the uploaded image
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidate(true);

    if (!themeName || !Thumbnail || !Description || !noOfsections || !maxPerSection || noOfsections<0|| maxPerSection<0|| PrimaryBannerLimit<0 || SecondaryBannerLimit<0) {
      // toast.error("Please fill in all required fields.",{position: "bottom-right",
      // autoClose: 1500,});
      return;
    }

    setLoading(true);

    try {
      const uploadedThumbnailUrl = await handleUpload();
        await AddTheme.request({
           theme_name: themeName,
          description: Description,
          has_sections: noOfsections > 0,
          num_sections: noOfsections,
          max_products_per_section: maxPerSection,
          isTopCategories: isTopCategories,
          isPrimaryBanner: PrimaryBannerLimit > 0,
          isSecondaryBanner: SecondaryBannerLimit > 0,
          primaryBannerLimit: PrimaryBannerLimit,
          secondaryBannerLimit: SecondaryBannerLimit?SecondaryBannerLimit:0,
          thumbnail: uploadedThumbnailUrl.data.files[0]})
     

      // toast.success("Theme added successfully!",{position: "bottom-right",
      // autoClose: 1500});

      // Reset form state after successful submission
      setThemeName("");
      setThumbnail(null);
      setDescription("");
      setNoOfSections(null);
      setMaxPerSection(null);
      setPrimaryBannerLimit(null);
      setSecondaryBannerLimit(null);
      setValidate(false);
      setIsChecked(false);

      if (fileInputRef.current) {
        fileInputRef.current.value = ""; 
      }
      setIsTopCategories(false);
      navigate("/theme");


     } catch (error) {
      console.error("Error adding theme:", error);
      // toast.error(error.response?.data?.message || "An error occurred while adding the theme.",{position: "bottom-right",
      // autoClose: 1500});
    } finally {
      setLoading(false);
    }
  };

  return (
    <AddForm>
      <div className="d-flex justify-content-between align-items-center px-5">
        <h5>Add Theme</h5>
      </div>
      <hr />
      <div className={`${style.formMain}`}>
        <Form noValidate onSubmit={handleSubmit} validated={validated}>
          <hr className={`${style.borderedHr}`} />
          <Row>
            <Col className="py-2" md={6}>
              <Form.Group className="w-100" controlId="formThemeName">
                <Form.Label>Theme Name <span className="text-danger">*</span></Form.Label>
                <Form.Control name="theme_name" type="text" placeholder="Theme Name" required value={themeName} onChange={(e) => setThemeName(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please enter a theme name.</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6} className="py-2">
              <Form.Group className="mt-3" as={Col} lg="12" md="12" sm="12" controlId="formBasicShopName">
                <label>Upload Thumbnail <span className="text-danger">*</span></label>
                <Form.Control ref={fileInputRef} type="file" size="sm" accept="image/*" name="image" onChange={(e) => setThumbnail(e.target.files[0])} isInvalid={validated && !Thumbnail} />
                <Form.Control.Feedback type="invalid">Please select an image.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="py-2">
              <Form.Group className="w-100">
                <Form.Label>Theme Description <span className="text-danger">*</span></Form.Label>
                <Form.Control name="theme_description" as="textarea" rows={2} placeholder="Theme Description" required value={Description} onChange={(e) => setDescription(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please enter a theme description.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="py-2" md={4}>
              <Form.Group className="w-100">
                <Form.Label>Number of Sections <span className="text-danger">*</span></Form.Label>
                <Form.Control name="theme_section" type="number" placeholder="Number of Sections" required value={noOfsections || ""} min={0} onChange={(e) => setNoOfSections(parseInt(e.target.value) || null)} />
                <Form.Control.Feedback type="invalid">Please enter a valid number of sections.</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col className="py-2 px-0 mx-0" md={4}>
              <Form.Group  style={{width:'106%'}}>
                <Form.Label>Maximum Products/Section <span className="text-danger">*</span></Form.Label>
                <Form.Control  name="theme_max_product" type="number" placeholder="Max Products Per Section" required value={maxPerSection || ""} min={0} onChange={(e) => setMaxPerSection(parseInt(e.target.value) || null)} />
                <Form.Control.Feedback type="invalid">Please enter a valid number of products per section.</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col className="py-2 mt-4 d-flex justify-content-center align-items-center px-0 mx-0" md={4}>
              <Form.Group controlId="formTopCategories" className="mb-0">
                <Form.Check
                  type="checkbox"
                  label="Top Categories"
                  value={isTopCategories}
                  checked={isTopCategories?true:false}
                  

                  onChange={(e) => setIsTopCategories(e.target.checked)}
                />
                {/* <Form.Control.Feedback type="invalid">Please select top categories.</Form.Control.Feedback> */}
              </Form.Group>
            </Col>
          </Row>

          <Row className="">
            <Col className="py-2" md={6}>
              <Form.Group className="w-100">
                <Form.Label>Primary Banner Limit <span className="text-danger">*</span></Form.Label>
                <Form.Control  name="theme_section" type="number" placeholder="Primary Banner Limit" min={0}  value={PrimaryBannerLimit} onChange={(e) => setPrimaryBannerLimit(parseInt(e.target.value) || null)} />
                <Form.Control.Feedback type="invalid">Please enter a valid primary banner limit.</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col className="py-2" md={6}>
              <Form.Group className="w-100">
                <Form.Label>Secondary Banner Limit</Form.Label>
                <Form.Control name="theme_max_product" type="number" placeholder="Secondary Banner Limit" min={0} value={SecondaryBannerLimit } onChange={(e) => setSecondaryBannerLimit(parseInt(e.target.value) || null)} />
                <Form.Control.Feedback type="invalid">Please enter a valid secondary banner limit.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="py-3">
            <Col>
            <Button type="submit" className="w-100" disabled={loading || !themeName||!Description||!noOfsections||!maxPerSection||!Thumbnail||!PrimaryBannerLimit  }>
                {loading ? "Submitting..." : "Save Theme"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </AddForm>
  );
};

export default AddTheme;
