import { useState } from 'react';

function useMarkedFields() {
    const [validation, setValidation] = useState({ all: false });
    const setValidateField = (event) => {
        setValidation((validation)=> ({
            ...validation,
            [event.target.name]: true
        }))
    }

    const setAllFieldsTouched = (event) => {
        setValidation({ all: true })
    }

    const bindField = (fieldName) => ({
        "data-touched": validation.all || validation[fieldName],
        onBlur: setValidateField,
    });

    return [bindField, setAllFieldsTouched];
}

export default useMarkedFields;