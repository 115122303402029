import React, { useState, useEffect } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";

import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";

import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PopUpModal from "../Modal";
import AddUser from "../../Pages/SecondaryUser/AddUser";
import EditPlans from "./EditPlans";
import { toast } from "react-toastify";
const DELAY_TIME = 100;

function Plans({ userType, search, status, setOrderBy, sizeOfPage, subscription, orderBy, refresh, toggle }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [action, setAction] = useState(false);
  const [planData, setPlanData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [planId, setPlanId] = useState();
  const [showEditPlansModal, setShowEditPlansModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const getPaginationLength = useApi(api.getPlansNoOFPages);
  const getuser = useApi(api.getPlans);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [isActive, setIsActive] = useState();
  const [deleteId, setDeleteId] = useState();
  const deactivateplan = useApi(api.deactivatePlans);
  const activateplan = useApi(api.activatePlans);
  const fetchThemeByPlanId = useApi(api.fetchThemeByPlanId);

  const fetchpaginationCount = async () => {
    try {
      const data = await getPaginationLength.request({
        search: search,
        is_active: status.value,
        size: sizeOfPage,
        orderBy: orderBy,
        page: currentPage,
      });
      setCount(data.data.no_of_items);

      if (data.data.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data.data.no_of_pages);
        if (currentPage > data.data.no_of_pages) {
          setCurrentPage(data.data.no_of_pages);
        }
      }
    } catch {}
  };
  useEffect(() => {
    console.log(toggle);
    fetchpaginationCount();
    const pageTimeout = setTimeout(async () => {
      const res = await getuser.request({
        search: search,
        is_active: status.value,
        orderBy: orderBy,
        size: sizeOfPage,
        page: currentPage,
      });

      const data = res.data;
      setIsLoading(false);
      setItems(data);
      if (user.permissionArray.includes("User")) {
        setAction(true);
      } else {
        setAction(false);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [userType, search, status, orderBy, currentPage, refresh, sizeOfPage, status, showEditPlansModal]);
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const edit = async (data) => {
    if (data["plan_type"] === "Custom" || data["plan_type"] === "customPlan") {
      toast.error("can't edit custom plan", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      await fetchThemeByPlanId
        .request(data["id"])
        .then((res) => {
          setPlanData(res.data.themes);

          setPlanId(data["id"]);
          setShowEditPlansModal(true);
        })
        .catch((error) => {});
    }
  };

  const handleDeactivate = (id) => {
    setDeleteId(id);
    setShowDeactivateModal(true);
    setIsActive(true);
  };
  const handleActivate = (id) => {
    setDeleteId(id);
    setShowDeactivateModal(true);
    setIsActive(false);
  };
  const closeModal = () => {
    setShowDeactivateModal(false);
  };
  const handleStatus = async (id, status) => {
    if (status) {
      try {
        await deactivateplan.request(id);
        closeModal();
        toggle((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await activateplan.request(id);
        closeModal();
        toggle((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchThemes = async (id) => {
    await fetchThemeByPlanId
      .request(id)
      .then((res) => {
        setPlanData(res.data.themes);
        setShowPlanModal(true);
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   fetchThemes(data['id'])
  // },[])

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult name="" />
      ) : (
        <>
          <CustomizeTableview
            key={items?.id}
            data={items}
            columnNotShow={["id", "username", "is_active"]}
            edit={(action && user.user_type === "super-admin") || user.user_type === "admin" ? edit : false}
            dateColumn={["trial_till"]}
            sortColumn={[""]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            toggleActionButton={
              (user.user_type === "super-admin" || user.user_type === "admin") && {
                label: (data) => {
                  if (data["is_active"] === 1) {
                    return "Deactivate";
                  } else {
                    return "Activate";
                  }
                },
                onClick: (data) => {
                  if (data["is_active"] === 1) {
                    handleDeactivate(data["id"]);
                  } else {
                    handleActivate(data["id"]);
                  }
                },
                variant: (data) => {
                  if (data["is_active"] === 1) {
                    return "outline-danger";
                  } else {
                    return "outline-primary";
                  }
                },
                style: { padding: "8px", fontSize: "12px" },
                column: "is_active",
              }
            }
            actionButton={(user.user_type === "super-admin" || user.user_type === "admin") && { label: "View Themes", variant: "outline-primary", style: { padding: "8px", fontSize: "12px" }, isShow: () => true, onClick: fetchThemes }}
            // view={{label: "View", onClick: view}}

            // view={fetchThemes(data["id"])}
            // remove={action ? deleteuser : false}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
          <PopUpModal size="lg" title="Edit Plan" show={showEditPlansModal} onHide={() => setShowEditPlansModal(false)}>
            <EditPlans
              id={planId}
              close={() => setShowEditPlansModal(false)}
              toggle={toggle}
              planData={planData}
              // close={closeModalAdd}
            />
          </PopUpModal>
          <Modal show={showDeactivateModal} onHide={closeModal} animation={true} centered>
            <Modal.Header>
              <Modal.Title>Confirm {isActive ? "Deactivate" : "Activate"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center pb-4">
                {/* <img src={triangle} alt="triangle" />
            <img
              src={dangerline}
              alt="dangerline"
              style={{ position: "absolute", right: "auto", top: "2.2rem" }}
            /> */}
              </div>
              <div>
                <span className="d-flex justify-content-center pb-4">Do you really want to {isActive ? "Deactivate" : "Activate"}?</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gap-3">
                <Button className="w-100 rounded-3" onClick={closeModal} variant="secondary">
                  Cancel
                </Button>
                <Button className="w-100 rounded-3" onClick={() => handleStatus(deleteId, isActive)} variant={`${isActive ? "danger" : "primary"}`}>
                  {isActive ? "Deactivate" : "Activate"}
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            centered
            size="lg"
            // title="Edit Plan"
            show={showPlanModal}
            onHide={() => setShowPlanModal(false)}
          >
            <Modal.Header className="d-flex justify-content-center" closeButton>
              Themes
            </Modal.Header>

            <Modal.Body>
              <CustomizeTableview
                data={planData}
                actions={false}
                columnNotShow={["id", "description", "thumbnail", "fk_theme_id", "fk_plan_id", "actions", "isSecondaryBanner", "isPrimaryBanner", "isTopCategories", "is_deleted", "has_sections", "created_at", "updated_at"]}
                // thumbnail={planData.thumbnail}
                thumbnail={{
                  title: "Thumbnail",
                  img: "img0",
                  description: "title",
                }}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}

export default Plans;
