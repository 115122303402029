import React from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import { formatNumber } from "../../Utilities/numbers";

Chart.register(CategoryScale);

const BarChart = ({ data, label, titleText }) => {
  const { "all-time": _, ...dataWithoutAllTime } = data;
  const periodLabels = {
    today: "Today",
    "this-week": "This Week",
    "this-month": "This Month",
    "this-year": "This Year",
    "last-year": "Last Year",
  };

  const chartData = {
    labels: Object.keys(dataWithoutAllTime).map((period) => periodLabels[period]),
    datasets: [
      {
        label: label,
        data: Object.values(dataWithoutAllTime),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            return formatNumber(value);
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: titleText,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;
