import React, { useState, useEffect } from "react";
import { Row, Modal, Col } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";

import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";

import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import AddModule from "./AddModule";
import PopUpModal from "../../Components/Modal";

const DELAY_TIME = 100;

function ModulesTable({ userType, handleby, search, status, setOrderBy, sizeOfPage, subscription, orderBy, refresh, toggle, statusType, priority, dates }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [action, setAction] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editData, setEditData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const deleteModule = useApi(api.deleteModule);
  const getPaginationLength = useApi(api.getRequestNoOfPages);
  const getRequ = useApi(api.getModules);

  //   const fetchpaginationCount = async () => {
  //     try {
  //       const data = await getPaginationLength.request({
  //       });
  //       setCount(data.data.no_of_items);
  //       if (data.data.no_of_pages === 0) {
  //         setNoOfPages(0);
  //       } else {
  //         setNoOfPages(data.data.no_of_pages);
  //         if (currentPage > data.data.no_of_pages) {
  //           setCurrentPage(data.data.no_of_pages);
  //         }
  //       }
  //     } catch {}
  //   };
  useEffect(() => {
    // fetchpaginationCount();
    const pageTimeout = setTimeout(async () => {
      const res = await getRequ.request({ statusType, search, sizeOfPage, currentPage });

      const data = res.data;
      setIsLoading(false);
      setItems(data);
      setNoOfPages(data?.count.no_of_pages);

      if (user.permissionArray.includes("User")) {
        setAction(true);
      } else {
        setAction(false);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [userType, statusType, search, orderBy, currentPage, refresh, sizeOfPage, status, priority, dates]);
  const changePage = (page) => {
    setCurrentPage(page);
  };

  const edit = (data) => {
    setEditModalShow(true);
    setEditData(data);
  };

  const deleteModuleById = async (data) => {
    try {
      const res = await deleteModule.request(data.id);
      if (res.status === 200) {
        toast.success(res?.data?.success?.message);
        toggle((prev) => !prev);
        refresh();
      }
    } catch (error) {}
  };
  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items.data ? (
        <NoResult name="" />
      ) : (
        <>
          <CustomizeTableview
            data={items?.data}
            columnNotShow={["is_deleted", "deleted_at"]}
            dateColumn={["created_at", "updated_at"]}
            sortColumn={[""]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            actions={true}
            remove={deleteModuleById}
            edit={edit}
            // remove={action ? deleteuser : false}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{items?.count.no_of_items} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{items?.count.no_of_items} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>

          <ToastContainer />
        </>
      )}
      <PopUpModal size="md" title="Add Module" show={editModalShow} onHide={() => setEditModalShow(false)}>
        <AddModule
          close={() => setEditModalShow(false)}
          toggle={toggle}
          editData={editData}
          // close={closeModalAdd}
        />
      </PopUpModal>
    </div>
  );
}

export default ModulesTable;
