import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import useApi from "../../Hooks/useApi";
import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";
import { useEffect } from "react";

const BankForm = ({ bank=null, close, toggle }) => {
  //form validation
  const [validated, setValidated] = useState(false);
  //vendors
  const [fullName, setFullName] = useState("");
  const [shortName, setShortName] = useState("");
  const addBanks = useApi(postapi.addBanks);
  const [error, setError] = useState("");
  const getBankById = useApi(api.getBankById);
  const editBank = useApi(postapi.editBank);

  //Get Data on Edit
  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const data = await getBankById.request(bank);
        setFullName(data?.data.full_name);
        setShortName(data?.data.short_name);
      } catch (error) {
        console.log(error);
      }
    };
    if(bank){
      fetchPlan();
    }
  }, [bank]);


  // submit form
  const handleForm = async (e) => {
    const bankPostObject = {
      full_name: fullName,
      short_name: shortName,
    };
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    if (
      fullName &&
      shortName
    ) {
      try {
        if(bank){
          await editBank.request(bank,bankPostObject);
        }else{
          await addBanks.request(bankPostObject);
        }
        close();
        toggle((prev) => !prev);
      } catch (error) {
        setError(error.response.data.error.message);
      }
    }
    setValidated(true);
  };


  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleForm}
      >
        {error && (
          <span className="text-danger p-2 text-center my-2 rounded-1">
            *{error}
          </span>
        )}
            <Form.Group className="w-100 pb-2">
              <label>
                Full Name<span className="text-danger">*</span>
              </label>
              <Form.Control
                value={fullName}
                className="p-2"
                onChange={(e) => {
                  setFullName(e.target.value);
                }}
                name="bank_name"
                type="text"
                placeholder="e.g. Allied Bank Limited "
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Bank Full Name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="pb-2 w-100">
              <label>
                Short Name<span className="text-danger">*</span>
              </label>
              <Form.Control
                value={shortName}
                name="short_name"
                onChange={(e) => {
                  setShortName(e.target.value);
                }}
                type="text"
                placeholder="e.g. ABL"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Bank Short Name.
              </Form.Control.Feedback>
            </Form.Group>
          
        <Row className="py-3">
          <Col>
            <Button className="w-100" onClick={handleForm}>
              {bank?"Edit":"Add"} Bank
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BankForm;
