import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import PopUpModal from "../../Components/Modal";
import ShopsListModal from "../../Components/ShopsListModal";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "../../Components/ReactSelect";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import NoResult from "../../Components/NoResult";
const Notifications = () => {
  let { state } = useLocation();
  const navigation = useNavigate();
  const [showDataModal, setShowDataModal] = useState();
  const [selectedShops, setSelectedShops] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    isForShops: false,
    isForUsers: false,
    link: null,
    image: null,
    channel: null,
    type: null,
  });
  const [notificationFor, setNotificationFor] = useState(state.type === "sms" ? "isForUsers" : null);
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [message, setMessage] = useState({
    header: "Dear Customer,\n\n",
    body: "",
    footer: `\n\nPlease contact us for further details.\nThank you!\nTeachBazaar Support`,
  });
  const notificationsTypesOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/notifications/types`, { withCredentials: true })
        .then((response) => {
          const data = response.data.map((res) => ({
            value: res.value,
            label: res.label,
          }));
          resolve([data[0]]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const smsChannelsOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/sms-notification/channels`, { withCredentials: true })
        .then((response) => {
          const data = Object.entries(response.data).map(([key, value]) => ({
            value: value,
            label: key,
          }));
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleNotificationChange = (e, name) => {
    if (name === "isForUsers") {
      setNotification({
        ...notification,
        isForShops: false,
        isForUsers: true,
      });
    } else if (name === "isForShops") {
      setNotification({
        ...notification,
        isForShops: true,
        isForUsers: false,
      });
    } else if (name === "channel" || name === "type") {
      setNotification({
        ...notification,
        [name]: {
          value: e.value,
          label: e.label,
        },
      });
    } else {
      setNotification({
        ...notification,
        [name]: e.target.value,
      });
    }
  };

  const handleSMSBodyChange = (e, name) => {
    setMessage({
      ...message,
      [name]: e.target.value,
    });
  };
  const handleUpload = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file.file, file.file.name);
      axios
        .post(
          `${process.env.REACT_APP_URL_IMAGE_CDN}/images/notifications`,
          formData,
          { withCredentials: true },
          {
            headers: {
              Accept: "application/json",
              "content-type": "multipart/form-data",
            },
          },
        )
        .then(({ data }) => {
          resolve(data.files[0]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let imageURL;
      setValidated(true);
      if (state.type === "notify" ? notification?.type && notification?.title?.length > 0 && selectedRows.length > 0 && notification?.message?.length > 0 : message?.body?.length > 0 && selectedRows.length > 0 && notification.channel) {
        setIsClicked(true);
        let res;
        if (state.type === "notify") {
          if (notification.image && Object.values(notification.image).length > 0) {
            imageURL = await handleUpload(notification.image);
          }
          const recipients = selectedRows.length > 0 ? selectedRows?.map(({ id }) => id) : [];
          const notificationData = {
            type: notification?.type?.value,
            title: notification?.title,
            message: notification?.message,
            link: notification?.link ? notification.link : null,
            recipientList: recipients,
            isForShops: notification?.isForShops,
            isForUsers: notification?.isForUsers,
            image: notification.image ? imageURL : null,
          };
          res = await axios.post(process.env.REACT_APP_API_URL + `/notifications/publish`, { data: notificationData }, { withCredentials: true });
        } else {
          let usersList;
          if (notificationFor === "isForUsers") {
            usersList = selectedRows.length > 0 ? selectedRows?.map(({ id, userContactNumber }) => ({ userId: id, cellNumber: userContactNumber })) : [];
          }
          const smsData = {
            recipientList: selectedRows.length > 0 ? usersList : [],
            // message: message.header + message.body + message?.footer?.replaceAll("\n", ""),
            message: message.header + message.body + message?.footer,
            channel: notification?.channel?.value,
          };
          res = await axios.post(process.env.REACT_APP_API_URL + `/sms-notification/send`, { data: smsData }, { withCredentials: true });
        }
        if (res) {
          setValidated(false);
          toast.success(`${res?.data?.message}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          setNotification({
            title: "",
            message: "",
            isForShops: false,
            isForUsers: false,
            link: null,
            image: null,
            channel: null,
            type: null,
          });
          setSelectedRows([]);
          setNotificationFor(state.type === "sms" ? "isForUsers" : null);
          setTimeout(() => navigation("/notifications"), 1500);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageUpload = async (e, name) => {
    const file = e?.target?.files[0];
    if (file) {
      setNotification({
        ...notification,
        [name]: { preview: URL.createObjectURL(file), file, name: file.name },
      });
    }
  };
  return (
    <>
      <Container fluid>
        <div className="d-flex justify-content-around gap-2">
          <Form noValidate validated={validated} onSubmit={handleSubmit} className="rounded-3 bg-white border-1 border-secondary overflow-hidden m-0 mb-0 mt-4 mb-4 text-nowrap mx-auto" style={{ width: "60%" }}>
            <Row
              className="m-4 px-3 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {state.type === "sms" ? <span className="fs-5 fw-bold d-flex justify-content-center">Send SMS</span> : <span className="fs-5 fw-bold d-flex justify-content-center">Send Notification</span>}
            </Row>
            <Row className="px-5 gap-3">
              {state.type === "notify" ? (
                <>
                  <Form.Group as={Col} lg="12" md="12" sm="12">
                    <Form.Label>
                      Notification Type <span className="text-danger">*</span>
                    </Form.Label>
                    <ReactSelect
                      placeholder="Select Notification Type"
                      value={notification?.type}
                      options={notificationsTypesOptions}
                      handleChange={(e) => {
                        handleNotificationChange(e, "type");
                      }}
                      searchable={false}
                    />
                    <Form.Control value={notification?.type} onChange={() => {}} hidden required={state.type === "notify" ? true : false} />
                    <Form.Control.Feedback type="invalid">Select Notification type.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} lg="12" md="12" sm="12" className="" controlId="title">
                    <Form.Label>
                      Title<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Write Title"
                      name="title"
                      maxLength={50}
                      value={notification.title}
                      onChange={(e) => {
                        handleNotificationChange(e, "title");
                      }}
                      required
                      isInvalid={validated && notification?.title?.length === 0}
                    />
                    <Form.Control.Feedback type="invalid">Enter Title</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} lg="12" md="12" sm="12" controlId="title">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="url"
                      placeholder="Write Link"
                      name="link"
                      maxLength={300}
                      value={notification.link}
                      onChange={(e) => {
                        handleNotificationChange(e, "link");
                      }}
                    />
                    <Form.Control.Feedback type="invalid">Enter Link</Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {state.type === "notify" ? (
                <Form.Group className="d-flex justify-content-around">
                  <Form.Check
                    type="radio"
                    label="Notification for Shops"
                    name="notificationFor"
                    onClick={(e) => {
                      handleNotificationChange(e, "isForShops");
                      setNotificationFor("isForShops");
                      setSelectedRows([]);
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Notification for Users"
                    name="notificationFor"
                    onClick={(e) => {
                      handleNotificationChange(e, "isForUsers");
                      setNotificationFor("isForUsers");
                      setSelectedRows([]);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">Please Select an Option</Form.Control.Feedback>
                </Form.Group>
              ) : (
                ""
              )}
              <Button
                type="button"
                className="w-25"
                onClick={() => {
                  if (notificationFor) {
                    setShowDataModal(true);
                  }
                }}
                disabled={!notificationFor}
              >
                {notificationFor ? (notificationFor === "isForUsers" ? "Select Users" : "Select Shops") : "Select Recipient"}
              </Button>
              {state.type !== "notify" ? (
                <Form.Group as={Col} lg="12" md="12" sm="12">
                  <Form.Label>
                    Channel <span className="text-danger">*</span>
                  </Form.Label>
                  <ReactSelect
                    placeholder="Select Channel"
                    value={notification.channel}
                    options={smsChannelsOptions}
                    handleChange={(e) => {
                      handleNotificationChange(e, "channel");
                    }}
                    searchable={false}
                  />
                  <Form.Control value={notification.channel} onChange={() => {}} hidden required={state.type === "sms" ? true : false} />
                  <Form.Control.Feedback type="invalid">Select Notification type.</Form.Control.Feedback>
                </Form.Group>
              ) : (
                ""
              )}

              {state.type === "notify" ? (
                <>
                  <Form.Group as={Col} lg="12" md="12" sm="12" controlId="body">
                    <Form.Label>
                      Message<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      rows={3}
                      maxLength={250}
                      minLength={3}
                      placeholder={state.type === "notify" ? "Write Notification Message" : "Write SMS Body"}
                      value={notification.message}
                      onChange={(e) => {
                        handleNotificationChange(e, "message");
                      }}
                      name="message"
                      pattern="^([A-Za-z ]|[0-9])+$"
                      required
                    />
                    <Form.Control.Feedback type="invalid">Enter Message </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-3" as={Col} lg="12" md="12" sm="12" controlId="formBasicShopName">
                    <label>Upload Image</label>
                    <Form.Control type="file" size="sm" accept="image/*" name="image" onChange={(e) => handleImageUpload(e, "image")} />
                  </Form.Group>
                  {notification?.image && <img height={250} width={120} src={notification?.image?.preview} />}
                </>
              ) : (
                <Form.Group as={Col} lg="12" md="12" sm="12" controlId="body">
                  <Form.Label>
                    Message<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div className="border rounded p-3">
                    <span className="ps-2 mt-2">{message.header}</span>
                    <Form.Control
                      className="border-0 rounded-0 my-2"
                      type="text"
                      as="textarea"
                      rows={3}
                      maxLength={250}
                      minLength={3}
                      placeholder={"Write SMS Body"}
                      value={message.body}
                      onChange={(e) => {
                        handleSMSBodyChange(e, "body");
                      }}
                      name="message"
                      pattern="^([A-Za-z ]|[0-9])+$"
                      required
                    />
                    <Form.Control.Feedback type="invalid">Enter Message </Form.Control.Feedback>
                    {message.footer.split("\n").map((line, index) => (
                      <div className="ps-2" key={index}>
                        {line}
                      </div>
                    ))}
                  </div>
                </Form.Group>
                // ""
              )}
              <Col lg="12" md="12" sm="12" className="d-flex justify-content-center mb-3">
                <Button type="submit" className="w-50">
                  {state.type === "sms" ? "Send SMS" : "Send Notification"}
                </Button>
              </Col>
            </Row>
          </Form>
          <div className="rounded-3 bg-white border-1 border-secondary m-0 mb-0 mt-4 text-nowrap mx-auto px-1 py-3" style={state.type === "notify" ? { maxHeight: "710px", overflowY: "auto" } : { maxHeight: "550px", overflowY: "auto" }}>
            {notificationFor === "isForUsers" ? <span className="fs-5 fw-bold d-flex justify-content-center">Users</span> : <span className="fs-5 fw-bold d-flex justify-content-center">Shops</span>}

            {selectedRows.length === 0 ? (
              <NoResult name="Recipient" />
            ) : (
              <CustomizeTableview
                data={selectedRows}
                columnNotShow={
                  notificationFor === "isForUsers"
                    ? ["id", "hasGlobal", "userId", "shopId", "subscriptionType", "userContactNumber", "userEmail"]
                    : [
                        "id",
                        "onPayment",
                        "onTrial",
                        "isShopActive",
                        "isShopDeactivated",
                        "subscriptionStatus",
                        "currentPlanStartDate",
                        "currentPlanEndDate",
                        "currentPlanBillingFrequency",
                        "isSubscriptionCancelled",
                        "cancelledOn",
                        "isSubscriptionActive",
                        "userEmail",
                        "subscriptionType",
                        "registrationDate",
                        "shopOwnerEmail",
                        "shopOwnerWhatsAppNumber",
                      ]
                }
                actions={false}
              />
            )}
          </div>
        </div>
      </Container>
      <PopUpModal
        size="xl"
        title="Shops"
        show={showDataModal}
        onHide={() => {
          setShowDataModal(false);
          // setSelectedRows([]);
        }}
        fullscreen="md-down"
      >
        <ShopsListModal close={() => setShowDataModal(false)} selectedRows={selectedRows} setSelectedRows={setSelectedRows} mode={notificationFor} state={state} />
      </PopUpModal>
    </>
  );
};

export default Notifications;
