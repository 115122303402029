import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container, Tabs, Tab } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import axios from "axios";
import { toast } from "react-toastify";
import SearchableSelect from "../../Components/SearchableSelect";
import SearchInput from "../../Components/SearchInput";
import moment from "moment/moment";
import DatePicker from "../../Components/DatePicker/DatePicker";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import style from "../../styles/Notification.module.css";
import AllNotifications from "./AllNotifications";
import SMS from "./SMS";
function NotificationsTable() {
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [currentTab, setCurrentTab] = useState("SMS");
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const handleTabs = (e) => {
    setCurrentTab(e);
    setSearchInput("");
    setSizeOfPages(10);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };

  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline mb-3"></Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
          <Col xs="2" lg="2">
            <Link to={"/notifications/send"} state={{ type: "notify" }}>
              <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit">
                <span className="d-none d-lg-inline">Send Notification</span> <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Link>
          </Col>
          <Col xs="2" lg="2">
            <Link to={"/notifications/send"} state={{ type: "sms" }}>
              <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit">
                <span className="d-none d-lg-inline">Send SMS</span> <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Link>
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Tabs defaultActiveKey="SMS" className={`${style.navtabs} `} onSelect={handleTabs}>
                <Tab eventKey="SMS" title="SMS" className="text-secondary mb-5 ">
                  <SMS sizeOfPages={sizeOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} searchInput={searchInput} fromDate={fromDate} toDate={toDate} currentTab={currentTab} />
                </Tab>
                <Tab eventKey="Notifications" title="Notifications" className="text-secondary mb-5 ">
                  <AllNotifications sizeOfPages={sizeOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} searchInput={searchInput} fromDate={fromDate} toDate={toDate} currentTab={currentTab} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </>
      </Container>
    </div>
  );
}

export default NotificationsTable;
