import { Form } from "react-bootstrap";
import InputMask from "react-input-mask";

const CnicInput = ({ placeholder, value, onChange, required }) => {
  return (
    <InputMask mask="99999-9999999-9" value={value} onChange={onChange}>
      {(inputProps) => (
        <Form.Control
          {...inputProps}
          placeholder={placeholder}
          type="tel"
          disableUnderline
          required={required}
        />
      )}
    </InputMask>
  );
};

export default CnicInput;
