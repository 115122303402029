import React, { useState, useEffect } from "react";
import { Row,  Col} from "react-bootstrap";
import PaginationBar from "../PaginationBar";

import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../NoResult";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import PopUpModal from "../Modal";
import BankForm from "./BankForm";

function Banks({
  search,
  setOrderBy,
  sizeOfPage,
  orderBy,
  refresh,
  toggle,
}) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [BankId, setBankId] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const getBanksNoOfPages = useApi(api.getBanksNoOfPages);
  const getBanks = useApi(api.getBanks);
  const fetchpaginationCount = async () => {
    try {
      const data = await getBanksNoOfPages.request({
        search: search,
        size: sizeOfPage,
        orderBy: orderBy,
      });
      setCount(data?.data.no_of_items);
      if (data?.data.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.data.no_of_pages);
        if (currentPage > data?.data.no_of_pages) {
          setCurrentPage(data?.data.no_of_pages);
        }
      }
    } catch(err){
      console.log(err)
    }
  };
  const fetchBanks = async ()=>{
    const res = await getBanks.request({
      search: search,
      orderBy: orderBy,
      size: sizeOfPage,
      page: currentPage,
    });
    const data = res?.data;
    setIsLoading(false);
    if(data){
      setItems(data);
    }else{
      setItems([]);
    }
  } 
  useEffect(() => {
    fetchpaginationCount();
    fetchBanks()

    // const pageTimeout = setTimeout(async () => {
    // }, DELAY_TIME);
    // return () => {
    //   clearTimeout(pageTimeout);
    // };
  }, [
    search,
    orderBy,
    currentPage,
    refresh,
    sizeOfPage,
  ]);
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const edit = (data) => {
    setBankId(data?.id);
    setShowEditModal(true);
  };

 

  return (
    <div>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult name="Banks" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            columnNotShow={["id", "is_deleted"]}
            edit={edit}
            dateColumn={["trial_till"]}
            sortColumn={[""]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar
                noOfPages={noOfPages}
                currentPage={currentPage}
                changePage={changePage}
              />
            </Col>
            <Col
              className="d-md-none d-flex align-items-center mt-3"
              xs="6"
              lg="3"
            >
              <span className="text-secondary fs-6">{count} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
          <PopUpModal
            title="Edit Bank"
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
          >
            <BankForm
              bank={BankId}
              close={() => setShowEditModal(false)}
              toggle={toggle}
            />
          </PopUpModal>
      </>
      )}
    </div>
  );
}

export default Banks;
