import { useState } from "react";
import { Form, Row, Col, Button, Modal, Overlay, Tooltip } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import style from "./../../styles/ResetPassword.module.css";

import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import customloader from "../../assets/images/RollingLoader.gif";

import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
import ReactSelect from "../ReactSelect";
import Loader from "../Loader";
import ConfirmationModal from "./ConfirmationModal";
import { useRef } from "react";

const ShiftModal = ({ id, userId, data, close, toggle, requestType }) => {
  const [plan, setPlan] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const [request, setRequest] = useState([]);

  const [isClicked, setIsClicked] = useState(false);

  const getarear = useApi(api.getArears);
  const getReq = useApi(api.getRequestforsubs);
  const imageUpload = useApi(postapi.UPloadImage);

  const [arrears, setArrears] = useState(0);
  const [error, setError] = useState("");
  const dropdownShops = useApi(api.getDropdownShops);
  const dropdownPlans = useApi(api.getPlansDropDown);
  const addreq = useApi(postapi.SubmitShiftPlan);
  const [filterInput, setFilterInput] = useState("".replace(/\W/g, ""));
  const [planAmount, setPlanAmount] = useState();
  const [paidAmount, setPaidAmount] = useState(0);
  const [transDate, setTransDate] = useState();
  const [billingPeriod, setBillingPeriod] = useState({
    value: "monthly",
    label: "Monthly",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectImage, setSelectImag] = useState();
  const [checkReq, setCheckReq] = useState(0);
  const [paidCheck, setPaidCheck] = useState(false);
  const [selectImageURL, setSelectImagURL] = useState();
  const [remaining, setRemaining] = useState();
  const [discount, setDiscount] = useState(0);
  const [payable, setPayable] = useState(0);
  const [transactionId, settransactionId] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [Dshow, setDShow] = useState(false);

  const target = useRef(null);
  const discountTarget = useRef(null);

  useEffect(() => {
    setPlanAmount(plan?.value?.monthly_total);
    if (billingPeriod.value === "monthly") {
      setPayable(plan?.value?.monthly_total);
    } else if (billingPeriod.value === "quarterly") {
      setPayable(plan?.value?.quarterly_total);
      setPlanAmount(plan?.value?.quarterly_total);
    } else {
      setPayable(plan?.value?.yearly_total);
      setPlanAmount(plan?.value?.yearly_total);
    }
  }, [planAmount, plan, arrears, request, billingPeriod]);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setIsLoading(true);
        const data = await getReq.request({
          shop_id: id,
          user_id: userId,
          request_type: requestType,
          status: "in_progress",
        });

        setCheckReq(data?.data?.length);
        setIsLoading(false);
      } catch (error) {}
    };
    const fetchArear = async () => {
      try {
        const data = await getarear.request(id);
        if (data) {
          setArrears(data.data.arrears);
        } else if (getarear?.error?.data) {
          setArrears(0);
        }
      } catch (error) {}
    };
    fetchArear();

    fetchRequests();
  }, []);

  const getRequest = async () => {
    return new Promise((resolve, reject) => {
      getReq
        .request({
          shop_id: id,
          user_id: userId,
          request_type: requestType,
          status: "in_progress",
        })
        .then((res) => {
          const result = res.data.filter((cat) => cat.request_title.concat("-", cat.request_title).toLowerCase().includes(filterInput.toLowerCase()));
          const getData = result.map((cat) => ({
            value: cat,
            label: (
              <>
                <strong className="fs-14" name={cat.request_title}>
                  {cat.id}-{cat.request_title}
                </strong>
              </>
            ),
            name: cat.request_title,
          }));
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  const getPlans = async () => {
    return new Promise((resolve, reject) => {
      dropdownPlans
        .request({
          plan_type: "fixed",
          shop_id: id,
          is_active: true,
        })
        .then((res) => {
          const result = res.data.filter((cat) => cat.plan_name.concat("-", cat.plan_name).toLowerCase().includes(filterInput.toLowerCase()));
          const getData = result.map((cat) => ({
            value: cat,
            label: (
              <>
                <strong className="fs-14" name={cat.plan_name}>
                  {cat.plan_name}
                </strong>
              </>
            ),
            name: cat.plan_name,
          }));
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  useEffect(() => {
    if (arrears || discount) {
      setPayable(Number(arrears) + Number(planAmount) - Number(discount));
    } else if (arrears) {
      setPayable(Number(arrears) + Number(planAmount));
    } else {
      setPayable(planAmount);
    }
    setRemaining(Number(payable) - Number(paidAmount));
  }, [arrears, planAmount, plan, request, discount, payable, billingPeriod, paidAmount]);
  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    }
    if (plan && planAmount && paidAmount && transactionId && transDate) {
      if (paidAmount > payable && discount < payable) {
        setPaidCheck(true);
      } else {
        setShowConfirmationModal(true);
      }
    }
  }
  async function submit(e) {
    setShowConfirmationModal(true);
    e.preventDefault();
    let imgData;
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    try {
      setValidated(true);
      if (selectImage) {
        imgData = await imageUpload.request(selectImage);
      }
      if (plan && planAmount && paidAmount && transactionId && transDate) {
        setIsClicked(true);
        const obj = {
          shop_id: id,
          transaction_date: transDate,
          plan_id: plan.value.id,
          plan_amount: planAmount,
          arrears: arrears,
          discount: discount,
          total_payable: payable,
          amount_paid: paidAmount,
          remaining_amount: remaining,
          remaining_amount: Number(payable) - Number(paidAmount),
          transaction_image_url: imgData ? imgData.data.files[0] : null,
          transaction_logged_by: userId,
          transaction_id: transactionId,
          billing_period: billingPeriod.value,
        };
        await addreq.request(request.value.id, obj);

        toggle((prev) => !prev);
        toast.success("Plan Shifted successfully ", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // close();
        setIsClicked(false);
        setValidated(false);
        close();
        setShowConfirmationModal(false);
      } else {
        setIsClicked(false);
      }
    } catch (error) {
      // setError(error.response.data.error.message);
      setIsClicked(false);
    }
  }
  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0], e.target.files[0].name);
    setSelectImag(formData);
    setSelectImagURL(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <div>
          {checkReq == "0" ? (
            <div className=" mb-3 text-center">
              <strong className="">There is no request for this shop</strong>
            </div>
          ) : (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                  <label>
                    Requests <span className="text-danger">*</span>
                  </label>
                  <ReactSelect
                    placeholder="Select Request"
                    value={request}
                    searchable={true}
                    options={getRequest}
                    handleChange={(e) => {
                      setRequest({
                        value: e.value,
                        label: e.name,
                      });
                      const finalobject = dropdownPlans?.data?.find((x) => x.id === e?.value?.plan_id);
                      setPlan({
                        value: finalobject,
                        label: finalobject?.plan_name,
                      });
                      const tempBillingPeriod = e.value.request_title.split("[")[1].split("]")[0];
                      if (tempBillingPeriod === "Yearly") {
                        setBillingPeriod({ value: "yearly", label: "Yearly" });
                      } else if (tempBillingPeriod === "Quarterly") {
                        setBillingPeriod({
                          value: "quarterly",
                          label: "Quarterly",
                        });
                      } else {
                        setBillingPeriod({
                          value: "monthly",
                          label: "Monthly",
                        });
                      }
                    }}
                    onInputChange={(newInput) => {
                      const inputValue = newInput.replace(/\W/g, "");
                      setFilterInput(inputValue);
                      return inputValue;
                    }}
                  />
                  <Form.Control className="p-2" name="province" hidden type="text" value={Object.keys(plan)?.length > 0 ? plan?.value : null} onChange={() => {}} required />
                  <Form.Control.Feedback type="invalid">* Please Select plan</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                  <label>
                    Plan <span className="text-danger">*</span>
                  </label>
                  <ReactSelect
                    placeholder="Select Plan"
                    value={plan}
                    searchable={true}
                    options={getPlans}
                    handleChange={(e) => {
                      setPlan({
                        value: e.value,
                        label: e.name,
                      });
                    }}
                    onInputChange={(newInput) => {
                      const inputValue = newInput.replace(/\W/g, "");
                      setFilterInput(inputValue);
                      return inputValue;
                    }}
                  />
                  <Form.Control className="p-2" name="province" hidden type="text" value={Object.keys(plan)?.length > 0 ? plan?.value : null} onChange={() => {}} required />
                  <Form.Control.Feedback type="invalid">* Please Select plan</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                  <label>
                    Billing Period <span className="text-danger">*</span>
                  </label>
                  <SearchableSelect
                    selectClassName={"bg-white"}
                    defaultValue={billingPeriod}
                    value={billingPeriod}
                    searchable={false}
                    options={[
                      { value: "monthly", label: "Monthly" },
                      { value: "quarterly", label: "Quarterly" },
                      { value: "yearly", label: "Yearly" },
                    ]}
                    handleChange={(e) => {
                      setBillingPeriod(e);
                    }}
                  />
                  <Form.Control className="p-2" name="province" hidden type="text" value={Object.keys(billingPeriod)?.length > 0 ? billingPeriod?.value : null} onChange={() => {}} required />
                  <Form.Control.Feedback type="invalid">* Please Select plan</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                  <label>
                    Plan Amounts <span className="text-danger">*</span>
                  </label>
                  <Form.Control value={Math.round(planAmount)} type="number" placeholder="" disabled />
                  <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" as={Col} lg="6" md="6" sm="12" controlId="price">
                  <label>
                    Arears <span className="text-danger">*</span>
                  </label>
                  <Form.Control value={arrears ? arrears : 0} type="number" placeholder="Rs.1000" disabled />
                  <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                  <label>Discount</label>
                  <Overlay target={discountTarget.current} show={Dshow} placement="right">
                    {(props) => (
                      <Tooltip style={{ background: "#ffecb5" }} id="overlay-example" {...props}>
                        Discount must be less than payable
                      </Tooltip>
                    )}
                  </Overlay>
                  <Form.Control
                    href="overlay-example"
                    value={discount}
                    ref={discountTarget}
                    name="discount"
                    onChange={(e) => {
                      setDiscount(e.target.value.replace(/\D/g, ""));
                      if (Number(planAmount) + Number(arrears) >= Number(e.target.value)) {
                        setPaidCheck(false);
                        setDiscount(e.target.value.replace(/\D/g, ""));
                      } else {
                        setDiscount("");
                        setDShow(true);
                        setTimeout(() => {
                          setDShow(false);
                        }, 2000);
                        setPaidCheck(true);
                      }
                    }}
                    type="number"
                    placeholder="Rs.1000"
                  />
                </Form.Group>
                <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                  <label>
                    Payable <span className="text-danger">*</span>
                  </label>
                  <Form.Control value={Math.round(payable)} type="number" placeholder="Rs.1000" disabled />
                  <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
                  <label>
                    Paid Amount <span className="text-danger">*</span>
                  </label>

                  <Overlay target={target.current} show={show} placement="right">
                    {(props) => (
                      <Tooltip style={{ background: "#ffecb5" }} id="overlay-example" {...props}>
                        Paid amount must be less than equal to payable
                      </Tooltip>
                    )}
                  </Overlay>
                  <Form.Control
                    value={paidAmount}
                    ref={target}
                    placeholder="Rs.1000"
                    required
                    // min={0}
                    // max={payable}
                    onChange={(e) => {
                      // e.target.value = parseInt(e.target.value, 10);

                      // let { value, min, max } = e.target;

                      if (Number(payable) >= Number(e.target.value)) {
                        setPaidCheck(false);
                        setPaidAmount(e.target.value);
                      } else {
                        setShow(true);
                        setTimeout(() => {
                          setShow(false);
                        }, 2000);
                        setPaidCheck(true);
                      }
                      // const value1 = Math.max(
                      //   Number(min),
                      //   Math.min(Number(max), Number(value))
                      // );

                      // setPaidAmount(value1);
                    }}
                  />

                  <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                  <label>
                    Transaction Id <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={transactionId}
                    name="contact_person_name"
                    onChange={(e) => {
                      settransactionId(e.target.value);
                    }}
                    placeholder="Enter transaction Id"
                    required
                  />
                  <Form.Control.Feedback type="invalid">* Please Enter Id.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                  <label>
                    Transaction Date<span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={transDate}
                    onChange={(e) => {
                      setTransDate(e.target.value);
                    }}
                    required
                    type="date"
                  />

                  <Form.Control.Feedback type="invalid">* Please select Date.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                  <label>Upload Image</label>
                  <Form.Control type="file" size="sm" accept="image/*" name="contact_person_name" onChange={(e) => handleImageUpload(e)} />
                  <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>
                </Form.Group>

                <Col className="mt-2 mb-4" lg="12" md="6" sm="12"></Col>
              </Row>
              <div className={`mb-4 ${style.fullWidth}`}>
                <Button className="w-100" type="submit">
                  {"Shift Plan"}
                </Button>
              </div>
            </Form>
          )}

          <ConfirmationModal
            submit={submit}
            isClicked={isClicked}
            planType={plan?.value?.plan_type}
            image={selectImageURL}
            planAmount={planAmount}
            remaining={remaining}
            payable={payable}
            arrears={arrears}
            discount={discount}
            transactionId={transactionId}
            paidAmount={paidAmount}
            transDate={transDate}
            billingPeriod={billingPeriod.value}
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
          />
        </div>
      )}
    </>
  );
};

export default ShiftModal;
