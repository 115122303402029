import React, { useState, useEffect } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";

import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
const DELAY_TIME = 100;

function Payments({
  search,
  setOrderBy,
  sizeOfPage,
  orderBy,
  dates,
  refresh,
  //   toggle,
}) {
  const { user } = useSelector((state) => state.auth);
  let [shops, setShops] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const getPaginationLength = useApi(api.getNoOfPagesPaidShops);
  const getshops = useApi(api.getPaidShops);

  const fetchpaginationCount = async () => {
    try {
      const data = await getPaginationLength.request({
        search: search,
        size: sizeOfPage,
        dates: dates,
      });
      setCount(data.data.no_of_items);

      if (data.data.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data.data.no_of_pages);
        if (currentPage > data.data.no_of_pages) {
          setCurrentPage(data.data.no_of_pages);
        }
      }
    } catch {}
  };
  useEffect(() => {
    fetchpaginationCount();
    const pageTimeout = setTimeout(async () => {
      const res = await getshops.request({
        search: search,
        orderBy: orderBy,
        dates: dates,
        size: sizeOfPage,
        page: currentPage,
      });

      const data = res.data;
      setIsLoading(false);

      setShops(data);
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [search, orderBy, currentPage, dates, sizeOfPage]);
  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !shops[0] ? (
        <NoResult name="Payment" />
      ) : (
        <>
          <CustomizeTableview
            data={shops}
            columnNotShow={["subs_transactions_id", "arrears", "transaction_image_url", "transaction_logged_by", "shop_id"]}
            dateColumn={["transaction_date"]}
            sortColumn={["shop_name", "transaction_id", "transaction_date", "total_payable", "amount_paid"]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            actions={false}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Payments;
