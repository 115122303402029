import { useState } from "react";
import { Row, Col, Button, FormControl } from "react-bootstrap";
import { useEffect } from "react";
import customloader from "../assets/images/RollingLoader.gif";
import PaginationBar from "./PaginationBar";
import CustomizeTableview from "./TableViews/CustomizeTableview";
import axios from "axios";
import SearchInput from "./SearchInput";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ForcedRedirectionsModal = ({ close, selectedRows, setSelectedRows }) => {
  const [search, setSearch] = useState("");
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalShops, setTotalShops] = useState(0);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const getShopsPrimaryUsers = async () => {
    try {
      setIsLoading(true);
      const responseData = await axios.get(process.env.REACT_APP_API_URL + `/shops/fetch-shops-primary-users/?search=${search}&page=${currentPage}&size=${sizeOfPages}`, { withCredentials: true });
      if (responseData) {
        setIsLoading(false);
        setUsers(responseData.data.data);
        setNoOfPages(responseData.data.count.no_of_pages);
        setTotalShops(responseData?.data?.count?.no_of_items);
      }
    } catch (error) {
      setIsLoading(false);
      setUsers([]);
      setNoOfPages(1);
      setTotalShops(0);
    }
  };
  useEffect(() => {
    getShopsPrimaryUsers();
  }, [currentPage, search, sizeOfPages, noOfPages]);

  return (
    <>
      <Row className="">
        <Col xs="6" lg="6" className="d-none d-lg-inline">
          <SearchInput searchlogo handleChange={handleChange} value={search} />
        </Col>
      </Row>
      <div className="position-absolute top-0 end-0 p-3">
        <FontAwesomeIcon icon={faCheck} /> {selectedRows.length} items Selected
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "auto" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : users.length > 0 ? (
        <div
          style={{
            maxHeight: "70vh",
            overflow: "auto",
            display: "block",
          }}
          className="mb-4"
        >
          <CustomizeTableview className={"customerSalesModal mb-2 mt-3"} checkbox={{ get: selectedRows, setter: setSelectedRows }} data={users} columnNotShow={["id"]} actions={false} />
        </div>
      ) : (
        <div className="mb-4 d-flex justify-content-center text-center">
          <h3>No Result Found</h3>
        </div>
      )}
      {totalShops > 10 && (
        <>
          <Row className="mt-5 align-items-center">
            <Col className="d-none d-md-block" md="3" lg="3">
              <span className="text-secondary fs-6"> {totalShops} Results</span>
            </Col>
            <Col xs="12" md="6" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
          </Row>
        </>
      )}
      <Row className="py-3">
        <Col>
          <Button
            type="clear"
            className="w-100 mt-2"
            variant="secondary"
            onClick={() => {
              close(true);
              setSelectedRows([]);
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="submit" className="w-100 mt-2" disabled={selectedRows.length === 0} onClick={() => close(true)}>
            Proceed
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ForcedRedirectionsModal;
