import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchInput from "../../Components/SearchInput";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PaginationBar from "../../Components/PaginationBar";
import SearchableSelect from "../../Components/SearchableSelect";
import NoResult from "../../Components/NoResult";
import ReactSelect from "../../Components/ReactSelect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TIMEOUT } from "../../Features/dateTime";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment/moment";
import Loader from "../../Components/Loader";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import * as postapi from "../../api/postapi";
import DeliveryForYouOrdersReasonModal from "./DeliveryForYouOrdersReasonModal";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import StatusChangeConfirmationModal from "./StatusChangeConfirmationModal";
const DeliveryForYouOrders = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const fetchAllCategories = useApi(api.fetchAllCategories);
  const fetchAllOrders = useApi(api.fetchAllOrders, { hasCatchError: true });

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [toggle, refresh] = useState(false);
  const [statusSearch, setStatusSearch] = useState({ value: null, label: "All" });
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [category, setCategory] = useState(null);
  const [orders, setOrders] = useState([]);
  const [ordersData, setOrdersData] = useState({});
  const [totalOrders, setTotalOrders] = useState();
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [reasonModal, setReasonModal] = useState(false);
  const [orderReason, setOrderReason] = useState({
    orderId: null,
    status: null,
  });
  const [phoneSearch, setPhoneSearch] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);

  // Search Products
  const searchHandle = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearch("");
    } else {
      setSearch(e.target.value);
    }
    setCurrentPage(1);
  };

  const searchStatus = (e) => {
    if (e.value) {
      setStatusSearch({ value: e.value, label: e.label });
    } else {
      setStatusSearch({ value: null, label: "All" });
    }
    setCurrentPage(1);
  };

  const getCat = async () => {
    return new Promise((resolve, reject) => {
      fetchAllCategories
        .request()
        .then((res) => {
          const getData = res.data.data
            .filter((cat) => cat.id !== 7)
            .map((cat) => ({
              value: cat.id,
              label: cat.category,
            }));
          getData.splice(0, 0, { value: null, label: "All" });
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  const filterCategories = (e) => {
    if (e.value) {
      setCategory({ value: e.value, label: e.label });
    } else {
      setCategory(null);
    }
    setCurrentPage(1);
  };

  const resetFilterHandle = (e) => {
    setStatusSearch({ value: "Pending", label: "Pending" });
    setCategory(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setSizeOfPages(10);
    setCurrentPage(1);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getDeliverForYouOrders() {
    try {
      let phoneNumber = phoneSearch.replaceAll("_", "");
      if (phoneNumber.endsWith("-")) {
        phoneNumber = phoneNumber.slice(0, -1);
      }
      fetchAllOrders
        .request({
          page: currentPage,
          size: sizeOfPages,
          search: search,
          status: statusSearch,
          category: category,
          fromDate: fromDate,
          toDate: toDate,
          orderBy: orderBy,
          phone: phoneNumber,
        })
        .then((data) => {
          setIsLoading(false);
          setTotalOrders(data?.data?.pagination?.no_of_items);
          setOrders(data?.data?.data);

          if (data?.data?.pagination?.no_of_pages === 0) {
            if (searchParams.get("page")) {
              setNoOfPages(0);
              navigate("/delivery-for-you-orders?page=0");
            }
          } else {
            setNoOfPages(data?.data?.pagination?.no_of_pages);
            if (currentPage > data?.data?.pagination?.no_of_pages) {
              setCurrentPage(data?.data?.pagination?.no_of_pages);
              navigate("/delivery-for-you-orders?page=" + data?.data?.pagination?.no_of_pages);
            }
            if (searchParams.get("page") === "0") {
              setCurrentPage(1);
              navigate("/delivery-for-you-orders?page=1");
            }
          }
        })
        .catch((err) => {
          const tempErr = err.response.data.error;

          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
            setOrders([]);
            setIsLoading(false);
          }
        });
    } catch (err) {
      const tempErr = err.response.data.error;

      if (tempErr.status === 404 && tempErr.message === "No Record Found") {
        setOrders([]);
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    let timeout;
    if (search?.length > 0) {
      timeout = setTimeout(() => {
        setIsLoading(true);
        getDeliverForYouOrders();
      }, TIMEOUT);
    } else {
      setIsLoading(true);
      getDeliverForYouOrders();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [search, fromDate, toDate, currentPage, noOfPages, sizeOfPages, statusSearch, shop_id, toggle, orderBy, category, phoneSearch]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [search, fromDate, toDate, statusSearch, category, phoneSearch]);

  const caret = async (orderId) => {
    const data = orders.filter((item) => item.id === orderId);
    const { customer_name, customer_email, customer_phone, customer_address, notes, seller_name, seller_phone, seller_address, reason, postman_name, postman_phone, ...ordersObj } = data[0];
    setOrdersData({ customer_name, customer_email, customer_phone, customer_address, notes, seller_name, seller_phone, seller_address, reason, postman_name, postman_phone });
  };
  const reasonHandle = (id, status) => {
    if (status === "Cancelled" || status === "Returned") {
      setReasonModal(true);
      setOrderReason({
        orderId: id,
        status: status,
      });
    }
  };
  const refreshHandle = () => {
    refresh((prev) => !prev);
  };
  const handleStatusModal = (value) => {
    setChangeStatusModal(true);
    if (value["status"] === "Pending") {
      setOrderReason({
        orderId: value["id"],
        status: "Confirmed",
      });
    } else if (value["status"] === "Confirmed") {
      setOrderReason({
        orderId: value["id"],
        status: "Picked",
      });
    } else if (value["status"] === "Picked") {
      setOrderReason({
        orderId: value["id"],
        status: "Shipped",
      });
    } else if (value["status"] === "Shipped") {
      setOrderReason({
        orderId: value["id"],
        status: "Delivered",
      });
    }
  };
  return (
    <>
      <Container fluid>
        <Row className="d-flex align-items-center">
          <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <SearchInput value={search} searchlogo handleChange={searchHandle} />
          </Col>
          <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <PhoneInput placeholder="+92-3XX-XXXXXXX" value={phoneSearch} onChange={(e) => setPhoneSearch(e.target.value)} required />
          </Col>
          <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
          <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <ReactSelect placeholder="Filter Category" value={category} searchable={true} options={getCat} handleChange={filterCategories} />
          </Col>

          <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <SearchableSelect
              placeholder="Filter Status"
              searchable={false}
              value={statusSearch}
              options={[
                { value: null, label: "All" },
                { value: "Pending", label: "Pending" },
                { value: "Confirmed", label: "Confirmed" },
                { value: "Picked", label: "Picked" },
                { value: "Shipped", label: "Shipped" },
                { value: "Delivered", label: "Delivered" },
                { value: "Cancelled", label: "Cancelled" },
                { value: "Returned", label: "Returned" },
              ]}
              handleChange={searchStatus}
            />
          </Col>
          <Col className="d-none d-sm-inline d-lg-none d-md-inline px-0" sm={2}></Col>
          <Col xs="6" lg="3" xl="2" sm="3" className="mb-3">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={(e) => setSizeOfPages(e.value)}
            />
          </Col>
        </Row>
        <div className="pt-2">
          <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
            <span className="d-lg-inline">Reset Filters</span>{" "}
          </Button>
        </div>

        {isLoading ? (
          <div
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        ) : orders?.length === 0 ? (
          <NoResult />
        ) : (
          <>
            <CustomizeTableview
              className={"mt-3"}
              data={orders}
              caret={caret}
              caretData={ordersData}
              link={{ label: "Click Here", column: "listing_url" }}
              columnNotShow={[
                "id",
                "item_name",
                "fk_customer_id",
                "fk_handler_id",
                "fk_item_id",
                "fk_listing_id",
                "fk_location_id",
                "fk_shop_id",
                "is_deleted",
                "deleted_at",
                "status",
                "customer_name",
                "customer_email",
                "customer_phone",
                "customer_address",
                "notes",
                "seller_name",
                "seller_phone",
                "seller_address",
                "sales_order_id",
                "reason",
                "postman_name",
                "postman_phone",
              ]}
              datetimeColumn={["created_at", "updated_at"]}
              sortColumn={["created_at", "updated_at", "item_name", "quantity", "price", "total_price", "stock_no"]}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              status={{
                column: ["status", "created_at"],
                getClassName: ({ status }) => {
                  if (status === "Delivered") {
                    return "text-success";
                  } else if (status === "Pending") {
                    return "text-warning";
                  } else if (status === "Cancelled") {
                    return "text-danger";
                  } else if (status === "Picked") {
                    return "text-info";
                  } else if (status === "Confirmed") {
                    return "text-primary";
                  } else if (status === "Shipped") {
                    return "text-secondary";
                  } else if (status === "Returned") {
                    return "text-danger";
                  }
                },
                format: ({ status }) => {
                  if (status === "Delivered") {
                    return "Delivered";
                  } else if (status === "Pending") {
                    return "Pending";
                  } else if (status === "Cancelled") {
                    return "Cancelled";
                  } else if (status === "Picked") {
                    return "Picked";
                  } else if (status === "Confirmed") {
                    return "Confirmed";
                  } else if (status === "Shipped") {
                    return "Shipped";
                  } else if (status === "Returned") {
                    return "Returned";
                  }
                },
              }}
              cancel={(value) => reasonHandle(value["id"], "Cancelled")}
              returned={(value) => reasonHandle(value["id"], "Returned")}
              actionButton={{
                column: ["status", "sales_order_id"],
                label: (value) => {
                  return value["status"] === "Pending" ? "Confirmed" : value["status"] === "Confirmed" ? "Picked" : value["status"] === "Picked" ? "Shipped" : value["status"] === "Shipped" ? "Delivered" : "Delivered";
                },
                onClick: async (er, value) => {
                  handleStatusModal(value);
                },
                variant: "outline-primary",
                disabled: (value) => value["status"] === "Cancelled" || value["status"] === "Delivered" || value["status"] === "Returned" || (value["status"] === "Confirmed" && value["sales_order_id"] === null),
                isShow: (_, row) => {
                  return row?.["status"] !== "Cancelled" && row?.["status"] !== "Delivered" && row?.["status"] !== "Returned";
                },
              }}
            />
            <Row className="mt-5 align-items-center">
              <Col className="d-none d-md-block" md="3" lg="3">
                <span className="text-secondary fs-6">{totalOrders} result(s)</span>
              </Col>
              <Col xs="12" md="6" lg="6">
                <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
              </Col>
              <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                <span className="text-secondary fs-6">{totalOrders} result(s)</span>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <DeliveryForYouOrdersReasonModal show={reasonModal} onHide={() => setReasonModal(false)} toggle={refreshHandle} id={orderReason?.orderId} status={orderReason?.status} />
      <StatusChangeConfirmationModal show={changeStatusModal} onHide={() => setChangeStatusModal(false)} toggle={refreshHandle} id={orderReason?.orderId} status={orderReason?.status} />
    </>
  );
};
export default DeliveryForYouOrders;
