import React, { useState, useEffect } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";

import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";

import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PopUpModal from "../Modal";
import AddUser from "../../Pages/SecondaryUser/AddUser";
import ShiftModal from "../Subscription/ShiftModal";
import CancelPlan from "./CancelPlan";
import PayForNextPlan from "./PayForNextPlan";
import { dateTime } from "../../Features/dateTime";
const DELAY_TIME = 100;

function HPhase({ userType, phase, search, status, setOrderBy, sizeOfPage, subscription, orderBy, refresh, toggle }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [action, setAction] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [shopId, setShopId] = useState();
  const [showEditPlansModal, setShowEditPlansModal] = useState(false);
  const getPaginationLength = useApi(api.getNoOfSubscriptions);
  const getdetails = useApi(api.getSubscriptions);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showShiftModal, setShifModal] = useState(false);
  const [showTrialExpire, setShowTrialExpire] = useState(false);
  const [showPayforModal, setShowPayforModal] = useState(false);

  const [isActive, setIsActive] = useState();
  const [deleteId, setDeleteId] = useState();
  const deactivateplan = useApi(api.deactivatePlans);
  const activateplan = useApi(api.activatePlans);

  const fetchpaginationCount = async () => {
    try {
      const data = await getPaginationLength.request({
        search: search,
        size: sizeOfPage,
        actions: phase,
      });
      setCount(data.data.no_of_items);

      if (data.data.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data.data.no_of_pages);
        if (currentPage > data.data.no_of_pages) {
          setCurrentPage(data.data.no_of_pages);
        }
      }
    } catch {}
  };
  const remaingDays = (d) => {
    const var1 = dateTime(d);
    const var2 = new Date();
    var Difference_In_Time = var2 - var1;
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Math.abs(Math.floor(Difference_In_Days));
  };
  useEffect(() => {
    fetchpaginationCount();
    const pageTimeout = setTimeout(async () => {
      const res = await getdetails.request({
        actions: phase,
        page: currentPage,
        orderBy: orderBy,
        size: sizeOfPage,
        search: search,
      });

      const data = res.data;
      setIsLoading(false);
      const newData = data.map((item) => {
        return {
          id: item.id,
          Shop_Name: item.shop_name,
          user_name: item.username,
          trial_started_on: item.current_plan_start_date,
          trial_ends_on: item.current_plan_end_date,
          status: item.status,
          grace_period_ended_since: remaingDays(item.grace_period_ends_on) + " Days",
        };
      });
      setItems(newData);

      if (user.permissionArray.includes("User")) {
        setAction(true);
      } else {
        setAction(false);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [userType, search, orderBy, currentPage, refresh, sizeOfPage, status]);
  const changePage = (page) => {
    setCurrentPage(page);
  };

  const handleDeactivate = (id) => {
    setDeleteId(id);
    setShowDeactivateModal(true);
    setIsActive(true);
  };
  const handleActivate = (id) => {
    setDeleteId(id);
    setShowDeactivateModal(true);
    setIsActive(false);
  };
  const closeModal = () => {
    setShowDeactivateModal(false);
  };
  const handleStatus = async (id, status) => {
    if (status) {
      try {
        await deactivateplan.request(id);
        closeModal();
        toggle((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await activateplan.request(id);
        closeModal();
        toggle((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const shiftPlan = (data) => {
    setShopId(data["id"]);
    setShifModal(true);
  };
  const cancelExpired = (data) => {
    setShopId(data["id"]);
    setShowTrialExpire(true);
  };
  const nextplan = (data) => {
    setShopId(data["id"]);

    setShowPayforModal(true);
  };
  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult name="" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            columnNotShow={["is_active"]}
            dateColumn={["trial_ends_on", "trial_started_on"]}
            cancelPlan={{
              cancelExpired: cancelExpired,
              label: "Cancel Plan",
              style: { padding: "8px", fontSize: "12x" },
            }}
            sortColumn={[""]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            NextPlan={{
              nextplan: nextplan,
              label: "Pay for Next Plan",
              style: { padding: "8px", fontSize: "12x" },
            }}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>

          <PopUpModal size="md" title="Shift Plan" show={showShiftModal} onHide={() => setShifModal(false)}>
            <ShiftModal close={() => setShifModal(false)} toggle={toggle} id={shopId} userId={user.user_id} />
          </PopUpModal>
          <PopUpModal size="md" title="Cancel Subscription" show={showTrialExpire} onHide={() => setShowTrialExpire(false)}>
            <CancelPlan
              close={() => setShowTrialExpire(false)}
              toggle={toggle}
              id={shopId}
              // confirm={expireFunction}
            />
          </PopUpModal>
          <PopUpModal size="md" title="Pay for Next plan" show={showPayforModal} onHide={() => setShowPayforModal(false)}>
            <PayForNextPlan
              close={() => setShowPayforModal(false)}
              toggle={toggle}
              id={shopId}
              userId={user.user_id}
              // confirm={expireFunction}
            />
          </PopUpModal>
        </>
      )}
    </div>
  );
}

export default HPhase;
