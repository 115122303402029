import React, { useState, useEffect } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";

import * as api from "../../api/getapi";
import * as post from "../../api/postapi";

import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";

import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PopUpModal from "../Modal";
import AddUser from "../../Pages/SecondaryUser/AddUser";
import DisapprovedModal from "./DisapprovedModal";
import { toast } from "react-toastify";
import FileTransferModal from "./FileTransferModal";
import FileTransformModal from "./FileTransformModal";
const DELAY_TIME = 100;

function ImportTable({ userType, salesman, search, status, setOrderBy, sizeOfPage, subscription, orderBy, refresh, toggle }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [action, setAction] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const getPaginationLength = useApi(api.getPointsNoOfPages);
  const deleteCall = useApi(api.DeleteSellerUser);
  const getImport = useApi(api.getImportData);
  const disapprove = useApi(post.disapprovedRequest);
  const transform = useApi(post.transformFile);
  const finalizeFile = useApi(post.finalize);
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [messagefield, setMessagefield] = useState();

  const [requestId, setRequestId] = useState();
  const [disapprovedModalShow, setDisapprovedModalShow] = useState(false);
  const [validateModalShow, setValidateModalShow] = useState(false);
  const [fileTransformModalShow, setFileTransformModalShow] = useState(false);
  const [fileTransform, setFileTransform] = useState(null);

  const [isFinalizingInprogress, setIsFinalizingInprogress] = useState(null);

  useEffect(() => {
    const pageTimeout = setTimeout(async () => {
      const res = await getImport.request({
        search: search,
        orderBy: orderBy,
        size: sizeOfPage,
        page: currentPage,
      });

      const data = res.data;
      setIsLoading(false);
      setItems(
        data.data.map((item) => {
          return {
            request_date: item.requestDate,
            id: item.requestId,
            status: item.requestStatus,
            request_handled_by: item?.requestHandler?.name,
            shopId: item.shop.id,
            shop: item.shop.name,
            message: item.requestMessage,
            file: process.env.REACT_APP_URL_S3 + "/" + item.requestFile,
            shopOwner: item.shop.owner,
          };
        }),
      );
      setNoOfPages(data.paginationData.totalPages);
      setCount(data.paginationData.totalCount);
      if (currentPage > data.data.total) {
        setCurrentPage(data.data.total);
      }
      if (user.permissionArray.includes("User")) {
        setAction(true);
      } else {
        setAction(false);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [userType, search, orderBy, currentPage, refresh, sizeOfPage, status]);
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const edit = () => {
    setDisapprovedModalShow(true);
  };
  const deleteuser = async (id) => {
    try {
      toggle((prev) => !prev);

      await deleteCall.request(id);
    } catch (error) {}
  };

  const disapproved = async (data) => {
    setRequestId(data?.id);
    setDisapprovedModalShow(true);
  };

  const transformFile = async (data) => {
    setFileTransform(data);
    setFileTransformModalShow(true);
    // Transform request for all types except other
    // try {
    //   const { data } = await transform.request(dataa.id, dataa.file, user.user_id);
    //   if (data) {
    //     toggle((prev) => !prev);
    //     toast.success("Transformed successfully", {
    //       position: "bottom-right",
    //       autoClose: 1500,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // } catch (error) {
    //   toast.error(transform?.error?.data?.message, {
    //     position: "bottom-right",
    //     autoClose: 1500,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  const closeFileTransformModal = (response) => {
    setFileTransformModalShow(false);
    setFileTransform(null);
    if (response === "success") {
      toast.success("Transformed successfully", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response === "close") {
      toast.info("You forcefully intrupted your process", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    toggle((prev) => !prev);
  };
  const validateForm = (data) => {
    setValidateModalShow(true);
    setRequestId(data.id);
  };
  const finalize = async (value) => {
    try {
      if (isFinalizingInprogress) {
        toast.error("This action is already in progress! Try again later.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        setIsFinalizingInprogress(value.id);
        const { data } = await finalizeFile.request(value.id, user.user_id);
        if (data) {
          toggle((prev) => !prev);
          toast.success("Finalized successfully", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsFinalizingInprogress(null);
        }
      }
    } catch (error) {
      toast.error(finalizeFile?.error?.data?.message, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      setIsFinalizingInprogress(null);
    }
  };
  const message = (value) => {
    console.log(value["message"], "LLLLLL");
    setMessagefield(value["message"] !== null ? value["message"] : "No Message");
    setMessageModalShow(true);
  };
  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult name="" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            message={message}
            columnNotShow={["id", "fk_shop_id", "fk_location_id", "is_validated", "file", "status", "message"]}
            dateColumn={["request_date"]}
            sortColumn={["location_name", "created_at"]}
            url={true}
            statusData={{
              style: (value) => {
                if (value === "done") {
                  return "text-success";
                } else if (value === "partially done") {
                  return "text-primary";
                } else if (value === "pending") {
                  return "text-warning";
                } else if (value === "in progress") {
                  return "text-info";
                } else if (value === "disapproved") {
                  return "text-danger";
                }
              },
              format: (value) => {
                return value?.charAt(0).toUpperCase() + value?.slice(1);
              },
            }}
            actions={true}
            transformFile={transformFile}
            validateForm={validateForm}
            disapproved={disapproved}
            finalize={finalize}
            // remove={action ? deleteuser : false}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
        </>
      )}
      <PopUpModal title="Disapprove Modal" show={disapprovedModalShow} onHide={() => setDisapprovedModalShow(false)}>
        <DisapprovedModal onClose={() => setDisapprovedModalShow(false)} requestId={requestId} toggle={toggle} />
      </PopUpModal>
      <PopUpModal
        title="Message"
        show={messageModalShow}
        onHide={() => {
          setMessageModalShow(false);
        }}
      >
        <div>
          {messagefield?.split("\n").map((sentence, index) => (
            <React.Fragment key={index}>
              {sentence.trim()}.
              <br />
            </React.Fragment>
          ))}
        </div>
      </PopUpModal>
      <PopUpModal title="Validate Data" size="xl" show={validateModalShow} onHide={() => setValidateModalShow(false)}>
        <FileTransferModal requestId={requestId} />
      </PopUpModal>
      <FileTransformModal show={fileTransformModalShow} close={closeFileTransformModal} file={fileTransform} />
    </div>
  );
}

export default ImportTable;
