import React, { useState } from "react";
import { Button, Col, Dropdown, Modal, Table, Row } from "react-bootstrap";
import Loader from "../Loader";

import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";

import sortAsc from "../../assets/svg/icon.svg";
import sortDesc from "../../assets/svg/Up.svg";

import removeIcon from "../../assets/svg/remove.svg";

import editpencil from "../../assets/svg/editpencil.svg";

import ellipsis from "../../assets/svg/Ellipsis.svg";
import printIcon from "../../assets/svg/print.svg";
import emailIcon from "../../assets/svg/email.svg";
import eyeIcon from "../../assets/svg/union.svg";

import notesIcon from "../../assets/svg/purchase_order.svg";
import paymentIcon from "../../assets/svg/paymentIcon.svg";

import archiveIcon from "../../assets/svg/archive.svg";
import unarchiveIcon from "../../assets/svg/Unarchive.svg";
import duplicateIcon from "../../assets/svg/save.svg";
import settingsIcon from "../../assets/svg/settings.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCheckSquare, faMoneyBillTransfer, faMoneyCheck, faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { dateTime } from "../../Utilities/dateTime";
import Caret from "../Caret";

const transform = (text) => {
  const temp = text.split("_");
  let ret = "";
  temp.forEach((txt) => {
    ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
  });
  return ret;
};

const ThumbnailImage = ({ srcInput }) => {
  const [imgSrc, setImgSrc] = useState(process.env.REACT_APP_URL_S3 + "/" + srcInput);
  return (
    <img
      style={{ borderRadius: "6px" }}
      width="32"
      height="32"
      alt="thumbnail"
      src={imgSrc}
      className="me-2"
      onError={() => {
        // setImgSrc(noImage);
      }}
    />
  );
};

const CustomizeTableview = ({
  data,
  reasons,
  settings = null,
  edit = null,
  remove = null,
  duplicate = null,
  archive = null,
  unarchive = null,
  columnNotShow = [],
  dateColumn = [],
  datetimeColumn = [],
  sortColumn = [],
  thumbnail = null,
  orderBy = null,
  setOrderBy = null,
  status = null,
  className,
  style,
  visitButton,
  loginButton = null,
  print = null,
  view = null,
  email = null,
  notes = null,
  payment = null,
  actionButton = null,
  toggleActionButton = null,
  ActiveDeactive = null,
  actions = true,
  salesman,
  assign,
  cancelPlan,
  ChangePlan,
  expireButton,
  requestDescription,
  NextPlan,
  ActiveDeactiveUser,
  disapproved,
  url,
  transformFile,
  validateForm,
  finalize,
  message,
  statusData,
  checkbox,
  deactivateRedirections,
  assignTo,
  formattedColumn,
  collapsableColumns,
  Description,
  processPlanRequest,
  extension,
  caret = null,
  caretData,
  approve,
  cancel,
  returned,
  link,
}) => {
  const [id, setId] = useState(-1);
  const [modelDeleteShow, setModalDeleteShow] = useState(false);
  const [modelArchiveShow, setModalArchiveShow] = useState(false);
  const [modelUnArchiveShow, setModalUnArchiveShow] = useState(false);
  const [deactivateRedirectionsModal, setDeactivateRedirectionsModal] = useState(false);
  const [caretState, setCaretState] = useState({
    id: null,
  });
  const cancelDeactivateConfirm = () => {
    setDeactivateRedirectionsModal(false);
    setModalDeleteShow(false);
    setModalArchiveShow(false);
    setModalUnArchiveShow(false);
  };
  const deactivateRedirectionConfirm = (id) => {
    deactivateRedirections(id);
    setDeactivateRedirectionsModal(false);
  };
  const deleteConfirm = (id) => {
    remove(id);
    setModalDeleteShow(false);
  };
  const handleCheckbox = (e, id, row) => {
    if (checkbox.isFullRow) {
      if (e.target.checked) {
        checkbox.setter((prev) => [...prev, row]);
      } else {
        checkbox.setter((prev) => prev.filter((item) => (checkbox.selectionColumn ? item[checkbox.selectionColumn] !== row[checkbox.selectionColumn] : item[Object.keys(item)[0]] !== row[Object.keys(row)[0]])));
      }
    } else {
      if (e.target.checked) {
        checkbox.setter((prev) => [...prev, id]);
      } else {
        checkbox.setter((prev) => prev.filter((item) => item !== id));
      }
    }
  };
  const handleCheckboxSelectAll = (e) => {
    if (checkbox.isFullRow) {
      if (e.target.checked) {
        checkbox.setter((prev) => [...prev, ...data.filter((item) => !checkbox.get.some((subItem) => (checkbox.selectionColumn ? subItem[checkbox.selectionColumn] === item[checkbox.selectionColumn] : subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]])))]);
      } else {
        checkbox.setter((prev) => prev.filter((item) => !data.some((subItem) => (checkbox.selectionColumn ? subItem[checkbox.selectionColumn] === item[checkbox.selectionColumn] : subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]]))));
      }
    } else {
      const allIDs = data.map((row) => row["id"]);
      if (e.target.checked) {
        const newIDs = allIDs.filter((id) => !checkbox.get.includes(id));
        checkbox.setter((prev) => [...prev, ...newIDs]);
      } else {
        checkbox.setter((prev) => prev.filter((id) => !allIDs.includes(id)));
      }
    }
  };

  const cancelConfirm = () => {
    setModalDeleteShow(false);
    setModalArchiveShow(false);
    setModalUnArchiveShow(false);
  };
  const archiveConfirm = (id) => {
    archive(id);
    setModalArchiveShow(false);
  };
  const unarchiveConfirm = (id) => {
    unarchive(id);
    setModalUnArchiveShow(false);
  };
  const handleSort = (column) => {
    if (orderBy) {
      const temp = new Object();
      if (Object.keys(orderBy)[0] === column) {
        if (orderBy[column] === "ASC") {
          temp[column] = "DESC";
        } else {
          temp[column] = "ASC";
        }
      } else {
        temp[column] = "ASC";
      }
      setOrderBy(temp);
    }
  };

  const tdNotShow = [...columnNotShow, ...dateColumn, ...datetimeColumn];
  if (thumbnail) {
    tdNotShow.push(thumbnail?.img, thumbnail?.description);
  }

  var statusVar;
  var actionButtonVar;
  const FIELD_LENGTH = 50;

  const TB = ({ data }) => {
    const [full, setFull] = useState(false);
    return (
      <td className={`border-bottom-0  text-wrap`}>
        {full ? `${data}` : data?.length > FIELD_LENGTH ? `${data?.substring(0, FIELD_LENGTH)}` : `${data}`}
        {full ? (
          data?.length > FIELD_LENGTH ? (
            <span style={{ color: "red", cursor: "pointer" }} onClick={() => setFull(false)}>
              (less)
            </span>
          ) : (
            ""
          )
        ) : data?.length > FIELD_LENGTH ? (
          <span style={{ color: "blue", cursor: "pointer" }} onClick={() => setFull(true)}>
            (...)
          </span>
        ) : (
          ``
        )}
      </td>
    );
  };
  return (
    <>
      {!data[0] ? (
        <Loader />
      ) : (
        <Table responsive striped className={`rounded-3 text-center bg-white border-1 border-secondary overflow-hidden m-0 mb-0 text-nowrap ${className}`}>
          <thead className="text-secondary" style={{ fontSize: "13px", fontWeight: "400", lineHeight: "18px" }}>
            <tr>
              {caret && <th className={`py-3 `}>{""}</th>}
              {thumbnail && (
                <th
                  style={{
                    ...(orderBy && Object.keys(orderBy)[0] === thumbnail?.description ? { color: "#9537FF" } : {}),
                    ...(sortColumn.includes(thumbnail?.description) ? { cursor: "pointer" } : {}),
                  }}
                  onClick={() => {
                    if (sortColumn) {
                      if (sortColumn.includes(thumbnail?.description)) {
                        handleSort(thumbnail?.description);
                      }
                    }
                  }}
                >
                  {transform(thumbnail?.title)}
                  {sortColumn && sortColumn.includes(thumbnail?.description) && <img src={orderBy[thumbnail?.description] === "ASC" ? sortDesc : sortAsc} />}
                </th>
              )}
              {checkbox && (
                <th>
                  <input
                    type="checkbox"
                    onClick={handleCheckboxSelectAll}
                    checked={(() => {
                      if (checkbox.isFullRow) {
                        return data.every((item) => checkbox?.get?.some((subItem) => (checkbox.selectionColumn ? subItem[checkbox.selectionColumn] === item[checkbox.selectionColumn] : subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]])));
                      } else {
                        const allIDs = data.map((row) => row["id"]);
                        return allIDs.every((id) => checkbox?.get?.includes(id));
                      }
                    })()}
                    style={{ marginRight: "10px" }}
                  />
                  Select All
                </th>
              )}
              {Object.keys(data[0]).map((s, index) => {
                if (!columnNotShow?.includes(s)) {
                  return (
                    <th
                      key={index}
                      style={{
                        ...(orderBy && Object.keys(orderBy)[0] === s ? { color: "#9537FF" } : {}),
                        ...(sortColumn.includes(s) ? { cursor: "pointer" } : {}),
                      }}
                      onClick={() => {
                        if (sortColumn) {
                          if (sortColumn.includes(s)) {
                            handleSort(s);
                          }
                        }
                      }}
                    >
                      {transform(s)}
                      {sortColumn && sortColumn.includes(s) && <img className="ms-2" src={orderBy[s] === "ASC" ? sortDesc : sortAsc} />}
                    </th>
                  );
                }
              })}

              {requestDescription && <th>Request Description</th>}
              {message && <th className="py-3">Message</th>}
              {url && <th>File</th>}
              {status && <th>Status</th>}
              {statusData && <th>Status</th>}
              {reasons && <th className="border-start text-center">Deactivate Reasons</th>}
              {Description && <th className="border-start text-center">Description</th>}
              {formattedColumn && <th>{formattedColumn.heading}</th>}
              {actions && <th className="border-start text-center">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <>
                <tr className="border-bottom" key={index}>
                  {caret && (
                    <>
                      <td
                        style={{ cursor: "pointer" }}
                        className="text-center"
                        onClick={() => {
                          setCaretState((prev) => {
                            if (prev.id === row.id) {
                              if (prev.caret) {
                                return { ...prev, caret: false };
                              } else {
                                return { ...prev, caret: true };
                              }
                            } else {
                              return {
                                caret: true,
                                id: row.id,
                              };
                            }
                          });
                          caret(row.id);
                        }}
                      >
                        <Caret caret={caretState} rowId={row.id} />
                      </td>
                    </>
                  )}
                  {thumbnail && (
                    <td className={`border-bottom-0 ${thumbnail?.className ? thumbnail?.className : ""}`}>
                      <ThumbnailImage srcInput={row?.thumbnail} />
                      {/* <img style={{ borderRadius: "6px" }} width="32" height="32" alt="primary image" src={row?.thumbnail} /> */}
                      {row[thumbnail?.description]}
                    </td>
                  )}
                  {checkbox ? (
                    row["id"] && (
                      <td>
                        <input
                          type="checkbox"
                          style={{ marginRight: "60px" }}
                          onClick={(e) => handleCheckbox(e, row["id"], row)}
                          checked={
                            checkbox.isFullRow ? (checkbox?.get?.filter((item) => (checkbox.selectionColumn ? item[checkbox.selectionColumn] === row[checkbox.selectionColumn] : item[Object.keys(item)[0]] === row[Object.keys(row)[0]])).length > 0 ? true : false) : checkbox.get.includes(row["id"])
                          }
                        />
                      </td>
                    )
                  ) : (
                    <></>
                  )}
                  {Object.entries(row).map(([k, v], i) => {
                    if (!tdNotShow?.includes(k)) {
                      if (link && link?.column === k) {
                        return (
                          <td>
                            {v ? (
                              <a href={v} target="_blank">
                                {link?.label}
                              </a>
                            ) : (
                              "--"
                            )}
                          </td>
                        );
                      }
                      if (status?.column === k) {
                        statusVar = v;
                      }
                      if (actionButton?.column === k) {
                        actionButtonVar = v;
                      }
                      if (collapsableColumns?.includes(k)) {
                        return <TB data={v} key={i} />;
                      } else {
                        return (
                          <>
                            {row["is_subscription_cancelled"] === 1 ? (
                              <td className="border-bottom-0 overlay" key={i}>
                                {" "}
                                {v}{" "}
                              </td>
                            ) : (
                              <td className="border-bottom-0" key={i}>
                                {" "}
                                {v}{" "}
                              </td>
                            )}
                          </>
                        );
                      }
                    }

                    if (dateColumn?.includes(k)) {
                      if (v) {
                        const dateFormat = dateTime(v);

                        return (
                          <td className="border-bottom-0" key={i}>
                            {dateFormat.toDateString().split(" ").slice(1).join(" ")}
                          </td>
                        );
                      } else {
                        return <td className="border-bottom-0" key={i}></td>;
                      }
                    }
                    if (datetimeColumn?.includes(k)) {
                      if (v) {
                        const dateFormat = dateTime(v);
                        return (
                          <td className="border-bottom-0" key={i}>
                            {dateFormat.toDateString().split(" ").slice(1).join(" ")} {dateFormat.toLocaleTimeString()}
                          </td>
                        );
                      } else {
                        return <td className="border-bottom-0" key={i}></td>;
                      }
                    }
                    // return (<td className='border-bottom-0' key={i}>{v}</td>)
                  })}
                  {message && (
                    <td>
                      <div>
                        <Button style={{ padding: "8px", fontSize: "12px" }} variant={"outline-primary"} onClick={() => message(row)}>
                          Click to show
                        </Button>
                      </div>
                    </td>
                  )}

                  {requestDescription && (
                    <td className="">
                      <TB data={row["request_description"]} />
                    </td>
                  )}
                  {url && (
                    <td>
                      <a className="btn btn-sm btn-primary fs-14 " href={row["file"]}>
                        Download File{" "}
                      </a>
                    </td>
                  )}
                  {status && typeof status === "object" ? (
                    <td className="">
                      <span className={`${status?.getClassName ? status?.getClassName(row) : ""}`}>{status?.format(row)}</span>
                    </td>
                  ) : (
                    status && (
                      <td className="">
                        <span className={`${row["status"] === "trial_expired" ? "text-danger" : "text-warning"}`}>{row["status"]}</span>
                      </td>
                    )
                  )}

                  {statusData && <td className={` ${statusData.style ? statusData.style(row["status"]) : ""}`}>{statusData.format(row["status"])}</td>}
                  {formattedColumn && <td className={` ${formattedColumn.style ? formattedColumn.style(row) : ""}`}>{formattedColumn.format(row)}</td>}

                  {reasons && (
                    <td>
                      <Button
                        style={{ padding: "8px", fontSize: "12px" }}
                        className=" outline-primary"
                        variant="outline-primary"
                        onClick={() => {
                          reasons(row);
                        }}
                      >
                        Reasons
                      </Button>
                    </td>
                  )}
                  {Description && (
                    <td>
                      <Button
                        style={{ padding: "8px", fontSize: "12px" }}
                        className=" outline-primary"
                        variant="outline-primary"
                        onClick={() => {
                          Description(row);
                        }}
                      >
                        Description
                      </Button>
                    </td>
                  )}
                  {actions && (
                    <td className={` border-start border-bottom-0 ${row["is_subscription_cancelled"] === 1 ? "overlay" : ""} `} style={{ maxWidth: "15%", width: "100%" }}>
                      <div className="d-lg-flex align-items-center justify-content-end gap-3 px-2 d-none ">
                        {actionButton && actionButton?.isShow(actionButtonVar, row) && (
                          <div>
                            <Button
                              className={actionButton.className}
                              style={actionButton.style}
                              variant={actionButton.variant}
                              onClick={() => {
                                actionButton.onClick(row["id"], row);
                              }}
                              {...(typeof actionButton?.disabled === "function" ? { disabled: actionButton?.disabled(row) } : actionButton?.disabled ? { disabled: true } : {})}
                            >
                              {typeof actionButton.label === "function" ? actionButton.label(row) : actionButton.label}
                            </Button>
                          </div>
                        )}

                        {ActiveDeactive && (row["is_subscription_cancelled"] === 0 || row["is_active_for_delivery"] !== undefined) && (
                          <div>
                            <Button
                              className={ActiveDeactive.className}
                              style={ActiveDeactive.style}
                              variant={ActiveDeactive.variant(row)}
                              onClick={() => {
                                ActiveDeactive.onClick(row);
                              }}
                            >
                              {ActiveDeactive.label(row)}
                            </Button>
                          </div>
                        )}
                        {ActiveDeactiveUser && (
                          <div>
                            <Button
                              className="outline-primary"
                              style={ActiveDeactiveUser.style}
                              variant={ActiveDeactiveUser.variant(row)}
                              onClick={() => {
                                ActiveDeactiveUser.onClick(row);
                              }}
                            >
                              {ActiveDeactiveUser.label(row)}
                            </Button>
                          </div>
                        )}
                        {visitButton && (
                          <div>
                            <Button
                              className={visitButton.className}
                              style={visitButton.style}
                              variant={visitButton.variant}
                              onClick={() => {
                                visitButton.onClick(row["username"]);
                              }}
                            >
                              {visitButton.label}
                            </Button>
                          </div>
                        )}
                        {salesman && (
                          <div>
                            <Button
                              style={{ padding: "8px", fontSize: "12px" }}
                              className=" outline-primary"
                              variant="outline-primary"
                              onClick={() => {
                                salesman(row);
                              }}
                            >
                              Change Salesman
                            </Button>
                          </div>
                        )}

                        {assign && row["request_status"] === "pending" && (
                          <div>
                            <Button
                              className=" outline-primary"
                              variant="outline-primary"
                              onClick={() => {
                                assign(row);
                              }}
                            >
                              Assign Me
                            </Button>
                          </div>
                        )}
                        {processPlanRequest && row["status"] === "pending" && (
                          <div>
                            <Button
                              className=" outline-primary"
                              variant="outline-primary"
                              onClick={() => {
                                processPlanRequest(row);
                              }}
                            >
                              Process Request
                            </Button>
                          </div>
                        )}
                        {loginButton && loginButton.isShow(row) && (
                          <div>
                            <Button
                              className={loginButton.className}
                              style={loginButton.style}
                              variant={loginButton.variant}
                              onClick={() => {
                                loginButton.onClick(row["username"]);
                              }}
                            >
                              {loginButton.label}
                            </Button>
                          </div>
                        )}
                        {expireButton && row["status"] === "on_trial" && (
                          <Button
                            className=" outline-danger "
                            variant="outline-danger"
                            onClick={() => {
                              expireButton.cancelExpired(row);
                            }}
                          >
                            {expireButton.label}
                          </Button>
                        )}

                        {toggleActionButton && (
                          <div>
                            <Button
                              className={toggleActionButton.className}
                              style={toggleActionButton.style}
                              variant={toggleActionButton.variant(row)}
                              onClick={() => {
                                toggleActionButton.onClick(row);
                              }}
                            >
                              {toggleActionButton.label(row)}
                            </Button>
                          </div>
                        )}

                        {duplicate && (
                          <div className="tooltipp">
                            <img src={duplicateIcon} alt="save" style={{ cursor: "pointer" }} onClick={() => duplicate(row.id)} />
                            <span className="tooltiptext">Duplicate</span>
                          </div>
                        )}
                        {ChangePlan && (
                          <Button
                            className=" outline-primay "
                            variant="outline-primary"
                            onClick={() => {
                              ChangePlan.changeplan(row);
                            }}
                          >
                            {ChangePlan.label}
                          </Button>
                        )}
                        {NextPlan && (
                          <Button
                            className=" outline-primay "
                            variant="outline-primary"
                            onClick={() => {
                              NextPlan.nextplan(row);
                            }}
                          >
                            {NextPlan.label}
                          </Button>
                        )}
                        {cancelPlan && (
                          <Button
                            className=" outline-danger "
                            variant="outline-danger"
                            onClick={() => {
                              cancelPlan.cancelExpired(row);
                            }}
                          >
                            {cancelPlan.label}
                          </Button>
                        )}
                        {settings && (
                          <div className="tooltipp">
                            <img src={settingsIcon} alt="edit" style={{ cursor: "pointer" }} onClick={() => settings(row["id"])} />
                            <span className="tooltiptext">Reset Password</span>
                          </div>
                        )}
                        {edit && (
                          <div className="tooltipp">
                            <img src={editpencil} alt="edit" style={{ cursor: "pointer" }} onClick={() => edit(row)} />
                            <span className="tooltiptext">Edit</span>
                          </div>
                        )}
                        {archive && (
                          <div className="tooltipp">
                            <img
                              src={archiveIcon}
                              alt="archive"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModalArchiveShow(true);
                                setId(row["id"]);
                              }}
                            />
                            <span className="tooltiptext">Archive</span>
                          </div>
                        )}
                        {unarchive && (
                          <div className="tooltipp">
                            <img
                              src={unarchiveIcon}
                              alt="unarchive"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModalUnArchiveShow(true);
                                setId(row["id"]);
                              }}
                            />
                            <span className="tooltiptext">Unarchive</span>
                          </div>
                        )}
                        {remove && (
                          <div className="tooltipp">
                            <img
                              src={removeIcon}
                              alt="removeIcon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setModalDeleteShow(true);
                                setId(row);
                              }}
                            />
                            <span className="tooltiptext">Delete</span>
                          </div>
                        )}
                        {print && (
                          <div className="tooltipp">
                            <img src={printIcon} alt="removeIcon" style={{ cursor: "pointer" }} onClick={() => print(row["id"])} />
                            <span className="tooltiptext">Print</span>
                          </div>
                        )}
                        {view && (
                          <div className="tooltipp">
                            <img src={eyeIcon} alt="removeIcon" style={{ cursor: "pointer" }} onClick={() => view(row["id"], row)} />
                            <span className="tooltiptext">View</span>
                          </div>
                        )}
                        {email && (
                          <div className="tooltipp">
                            <img src={emailIcon} alt="removeIcon" style={{ cursor: "pointer" }} onClick={() => email(row["id"])} />
                            <span className="tooltiptext">Email</span>
                          </div>
                        )}
                        {payment && (row.hasOwnProperty("is_paid") ? row["is_paid"] === 1 : true) && (
                          <div className="tooltipp">
                            <img src={paymentIcon} alt="payment Icon" style={row["status"] === "Cancelled" || row["status"] === "Done" ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }} onClick={() => payment(row["id"])} />
                            <span className="tooltiptext">Payments</span>
                          </div>
                        )}
                        {notes && (
                          <div className="tooltipp">
                            <img src={notesIcon} alt="notesIcon" style={{ cursor: "pointer" }} onClick={() => notes(row)} />
                            <span className="tooltiptext">Notes</span>
                          </div>
                        )}
                        {disapproved && (
                          <div className="tooltipp">
                            <FontAwesomeIcon
                              style={row["status"] === "disapproved" || row["status"] === "done" || row["status"] === "Cancelled" || row["status"] === "Done" ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }}
                              onClick={() => disapproved(row)}
                              icon={faRectangleXmark}
                            />
                            {/* <
                            src={notesIcon}
                            alt="notesIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => disapproved(row)}
                          /> */}
                            <span className="tooltiptext">Disapprove</span>
                          </div>
                        )}
                        {transformFile && (
                          <div className="tooltipp">
                            <FontAwesomeIcon
                              style={row["status"] === "disapproved" || row["status"] === "in progress" || row["status"] === "partially done" || row["status"] === "done" ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }}
                              onClick={() => transformFile(row)}
                              icon={faMoneyBillTransfer}
                            />
                            {/* <
                            src={notesIcon}
                            alt="notesIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => disapproved(row)}
                          /> */}
                            <span className="tooltiptext">Transform file</span>
                          </div>
                        )}
                        {validateForm && (
                          <div className="tooltipp">
                            <FontAwesomeIcon style={row["status"] === "disapproved" || row["status"] === "done" ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }} onClick={() => validateForm(row)} icon={faMoneyCheck} />
                            {/* <
                            src={notesIcon}
                            alt="notesIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => disapproved(row)}
                          /> */}
                            <span className="tooltiptext">Validate</span>
                          </div>
                        )}
                        {finalize && (
                          <div className="tooltipp">
                            <FontAwesomeIcon style={row["status"] === "in progress" || row["status"] === "partially done" ? { cursor: "pointer" } : { pointerEvents: "none", opacity: "0.5" }} onClick={() => finalize(row)} icon={faCheckCircle} />
                            {/* <
                            src={notesIcon}
                            alt="notesIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() => disapproved(row)}
                          /> */}
                            <span className="tooltiptext">finalize</span>
                          </div>
                        )}
                        {deactivateRedirections && row.status === 1 && (
                          <div
                            onClick={() => {
                              setDeactivateRedirectionsModal(true);
                              setId(row);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                            className="tooltipp"
                          >
                            <FontAwesomeIcon
                              icon={faToggleOff}
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Deactivate</span>
                          </div>
                        )}
                        {assignTo && row.status === "UnAssigned" && (
                          <div>
                            <Button
                              className=" outline-primary"
                              variant="outline-primary"
                              onClick={() => {
                                assignTo(row);
                              }}
                            >
                              Assign To
                            </Button>
                          </div>
                        )}
                        {extension && (
                          <div>
                            <Button
                              style={dateTime(row["banner_showing_start_date"]) <= new Date() && dateTime(row["grace_period_end_date"]) >= new Date() ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }}
                              className=" outline-primary"
                              variant="outline-primary"
                              onClick={() => {
                                extension(row);
                              }}
                            >
                              Extend
                            </Button>
                          </div>
                        )}
                        {approve && (
                          <div className="tooltipp">
                            <FontAwesomeIcon style={row["status"] !== "Pending" ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }} onClick={() => approve(row)} icon={faCheckSquare} />
                            <span className="tooltiptext">Approve</span>
                          </div>
                        )}
                        {returned && row.status === "Shipped" && (
                          <div>
                            <Button
                              className=" outline-primary"
                              variant="outline-primary"
                              onClick={() => {
                                returned(row);
                              }}
                            >
                              Returned
                            </Button>
                          </div>
                        )}
                        {cancel && (
                          <div className="tooltipp">
                            <FontAwesomeIcon style={row["status"] === "Delivered" || row["status"] === "Returned" || row["status"] === "Cancelled" ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }} onClick={() => cancel(row)} icon={faRectangleXmark} />
                            <span className="tooltiptext">Cancel</span>
                          </div>
                        )}
                      </div>
                      <Dropdown className="d-flex justify-content-center d-lg-none">
                        <Dropdown.Toggle variant="light" className="border-0 p-0" id="dropdown-basic" style={{ background: "transparent" }}>
                          <img src={ellipsis} alt="ellipsis" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" position-fixed rounded-0 border-0 shadow">
                          {actionButton && actionButton?.isShow(actionButtonVar) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                actionButton.onClick(row["id"]);
                              }}
                            >
                              {actionButton.label}
                            </Dropdown.Item>
                          )}
                          {ActiveDeactive && row["is_subscription_cancelled"] === 0 && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                ActiveDeactive.onClick(row);
                              }}
                            >
                              {ActiveDeactive.label(row)}
                            </Dropdown.Item>
                          )}
                          {toggleActionButton && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                toggleActionButton.onClick(row);
                              }}
                            >
                              {toggleActionButton.label(row)}
                            </Dropdown.Item>
                          )}
                          {duplicate && (
                            <Dropdown.Item className="purple" onClick={() => duplicate(row.id)}>
                              Duplicate
                            </Dropdown.Item>
                          )}
                          {edit && (
                            <Dropdown.Item className="purple" onClick={() => edit(row)}>
                              Edit
                            </Dropdown.Item>
                          )}
                          {unarchive && (
                            <Dropdown.Item className="purple" onClick={() => unarchive(row.id)}>
                              Unarchive
                            </Dropdown.Item>
                          )}
                          {archive && (
                            <Dropdown.Item className="purple" onClick={() => archive(row.id)}>
                              Archive
                            </Dropdown.Item>
                          )}
                          {remove && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                setModalDeleteShow(true);
                                setId(row);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          )}
                          {print && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                print(row["id"]);
                              }}
                            >
                              Print
                            </Dropdown.Item>
                          )}
                          {view && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                view(row["id"], row);
                              }}
                            >
                              View
                            </Dropdown.Item>
                          )}
                          {email && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                email(row["id"]);
                              }}
                            >
                              Email
                            </Dropdown.Item>
                          )}
                          {loginButton && loginButton?.isShow(row) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                loginButton.onClick(row["username"]);
                              }}
                            >
                              Login
                            </Dropdown.Item>
                          )}
                          {visitButton && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                visitButton.onClick(row["username"]);
                              }}
                            >
                              Visit
                            </Dropdown.Item>
                          )}

                          {payment && (row.hasOwnProperty("is_paid") ? row["is_paid"] === 1 : true) && (
                            <Dropdown.Item
                              style={row["status"] === "Cancelled" || row["status"] === "Done" ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }}
                              className="purple"
                              onClick={() => {
                                payment(row["id"]);
                              }}
                            >
                              Payments
                            </Dropdown.Item>
                          )}
                          {notes && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                notes(row);
                              }}
                            >
                              Purchase Order
                            </Dropdown.Item>
                          )}
                          {disapproved && (
                            <Dropdown.Item
                              className="purple"
                              style={row["status"] === "disapproved" || row["status"] === "done" || row["status"] === "Cancelled" || row["status"] === "Done" ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }}
                              onClick={() => {
                                disapproved(row);
                              }}
                            >
                              Disapproved
                            </Dropdown.Item>
                          )}
                          {transformFile && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                transformFile(row);
                              }}
                            >
                              Transform File
                            </Dropdown.Item>
                          )}
                          {validateForm && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                validateForm(row);
                              }}
                            >
                              Validate
                            </Dropdown.Item>
                          )}
                          {deactivateRedirections && row.status === 1 && (
                            <Dropdown.Item className="purple" onClick={() => deactivateRedirections(row)}>
                              Deactivate
                            </Dropdown.Item>
                          )}
                          {assignTo && row.status === "UnAssigned" && (
                            <Dropdown.Item className="purple" onClick={() => assignTo(row)}>
                              Assign To
                            </Dropdown.Item>
                          )}
                          {extension && (
                            <Dropdown.Item style={dateTime(row["banner_showing_start_date"]) <= new Date() && dateTime(row["grace_period_end_date"]) >= new Date() ? { pointerEvents: "none", opacity: "0.5" } : { cursor: "pointer" }} className="purple" onClick={() => extension(row)}>
                              Extend
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  )}
                </tr>

                {caret && caretState.caret && caretState.id === row.id ? (
                  <>
                    {caretData && Object.values(caretData).length > 0 ? (
                      <>
                        <tr className="border-0 colored-row">
                          <td className="border-start border-top py-0" colSpan={"100%"}>
                            <Row>
                              {Object.entries(caretData).map(([k, v], i) => (
                                <Col lg="3" md="6" sm="6" xs="6" className="py-2 border-end border-bottom" style={{ whiteSpace: "normal" }} key={i}>
                                  {transform(k)}: {v === "" || v === "nil" ? "-" : v?.toLocaleString("en-IN")}
                                </Col>
                              ))}
                            </Row>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr className="border-bottom colored-row">
                        <td className="fs-6 fw-3 text-center" colSpan="13">
                          <img src={Loader} width={25} />
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
          </tbody>
        </Table>
      )}
      <Modal show={modelDeleteShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to delete?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            <Button className="w-100" onClick={() => deleteConfirm(id)}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Archive Model */}

      <Modal show={modelArchiveShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Archive?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button>
            <Button className="w-100 rounded-3" onClick={() => archiveConfirm(id)}>
              Archive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* UnArchive Model */}

      <Modal show={modelUnArchiveShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Unarchive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Unarchive?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button>
            <Button className="w-100 rounded-3" onClick={() => unarchiveConfirm(id)}>
              Unarchive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={deactivateRedirectionsModal} onHide={cancelDeactivateConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Deactivate Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to deactivate this Redirection?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelDeactivateConfirm}
            >
              Cancel
            </span>
            <Button className="w-100" onClick={() => deactivateRedirectionConfirm(id)}>
              Deactivate
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomizeTableview;
