import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import DatePicker from "../../Components/DatePicker/DatePicker";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import NoResult from "../../Components/NoResult";
import PaginationBar from "../../Components/PaginationBar";
import customloader from "../../assets/images/RollingLoader.gif";
const UsersDeletionRequests = () => {
  const fetchAllUsersDeletionRequests = useApi(api.fetchAllUsersDeletionRequests);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ request_created_at: "DESC" });
  const [searchInput, setSearchInput] = useState("");
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const fetchAllNotifications = async () => {
    const res = await fetchAllUsersDeletionRequests.request({
      size: sizeOfPages,
      page: currentPage,
      search: searchInput,
      orderBy: orderBy,
      status: status?.value,
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setRequests(data?.data);
      setCount(data?.count?.no_of_items);
      if (data?.count?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.count?.no_of_pages);
        if (currentPage > data?.count?.no_of_pages) {
          setCurrentPage(data?.count?.no_of_pages);
        }
      }
    } else {
      setRequests([]);
    }
  };
  useEffect(() => {
    fetchAllNotifications();
  }, [currentPage, sizeOfPages, searchInput, fromDate, toDate, orderBy, toggle, status]);
  const handleStatus = (e) => {
    if (e.label === "All") {
      setStatus(null);
    } else {
      setStatus({ value: e.value, label: e.label });
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              value={status}
              searchable={false}
              options={[
                { value: "", label: "All" },
                { value: "pending", label: "Pending" },
                { value: "done", label: "Done" },
              ]}
              handleChange={handleStatus}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                <img src={customloader} alt="customloader" width="100" height="100" />
              </div>
            ) : !requests[0] ? (
              <NoResult />
            ) : (
              <>
                <CustomizeTableview
                  data={requests}
                  columnNotShow={["id", "request_deleted_at", "fk_user_id", "remainingDays", "status"]}
                  dateColumn={["request_created_at", "last_date"]}
                  sortColumn={["request_created_at", "user_full_name"]}
                  orderBy={orderBy}
                  collapsableColumns={["deletion_reason"]}
                  setOrderBy={setOrderBy}
                  statusData={{
                    style: (value) => {
                      if (value === "done") {
                        return "text-success";
                      } else if (value === "pending") {
                        return "text-primary";
                      }
                    },
                    format: (value) => {
                      return value?.charAt(0).toUpperCase() + value?.slice(1);
                    },
                  }}
                  formattedColumn={{
                    heading: "Remaining Days",
                    style: (row) => {
                      const daysLeft = row["remainingDays"];
                      if (daysLeft > 3) {
                        return "text-success";
                      } else {
                        return "text-primary";
                      }
                    },
                    format: (row) => {
                      const daysLeft = row["remainingDays"];
                      if (daysLeft > 3) {
                        return daysLeft + " Days Left";
                      } else {
                        return daysLeft + " Days Left";
                      }
                    },
                  }}
                  actions={false}
                />
                <Row className="mt-5">
                  <Col className="d-none d-lg-block" md="6" lg="3">
                    <span className="text-secondary fs-6">{count} Result(s)</span>
                  </Col>
                  <Col xs="12" lg="6">
                    <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{count} Result(s)</span>
                  </Col>
                  <Col xs="6" lg="3" className="text-end mt-3"></Col>
                </Row>
              </>
            )}
          </Row>
        </>
      </Container>
    </div>
  );
};

export default UsersDeletionRequests;
