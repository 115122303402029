import React from "react";
import { toast } from "react-toastify";
import * as post from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { Button } from "react-bootstrap";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ApproveRequestModal = ({ onClose, requestId, isExtensionRequest, toggle }) => {
  const approveExtension = useApi(post.approveExtensionAddonsRequest, { hasCatchError: true });
  const approveWithoutPayment = useApi(post.approveAddonsRequestWithoutPayment, { hasCatchError: true });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data;
      if (isExtensionRequest) {
        data = await approveExtension.request(requestId);
      } else {
        data = await approveWithoutPayment.request(requestId);
      }
      if (data) {
        toast.success("Request Approved Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        onClose();
        toggle((prev) => !prev);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  return (
    <div>
      <hr />
      <div className="d-flex justify-content-center pb-4">
        <FontAwesomeIcon icon={faCheckSquare} style={{ width: "40px", height: "40px", color: "green" }} />
      </div>
      <div>
        <span className="d-flex justify-content-center pb-4">Do you really want to approve this request?</span>
      </div>
      <hr />
      <div className="mt-2 gap-2 d-flex justify-content-end">
        <Button type="submit" onClick={handleSubmit}>
          Approve
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outline-secondary"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ApproveRequestModal;
