import React from "react";
import { Col, Row } from "react-bootstrap";
import NoResult from "./NoResult";
import CustomizeTableview from "./TableViews/CustomizeTableview";
import PopUpModal from "./Modal";
import style from "./../styles/Notification.module.css";

const RecipientsModal = ({ show, close, data }) => {
  return (
    <>
      <PopUpModal size="xl" title="Recipients" show={show} onHide={close} fullscreen="md-down" dialogClassName={style.notificationModal}>
        {!data.recipient_list ? (
          <NoResult name="Recipents" />
        ) : (
          <div className={`${style.modalBody}`}>
            <CustomizeTableview
              data={data?.recipient_list}
              columnNotShow={
                data.is_for_shops
                  ? ["id", "cancelledOn", "currentPlanBillingFrequency", "currentPlanEndDate", "currentPlanName", "currentPlanStartDate", "isShopActive", "isShopDeactivated", "isSubscriptionActive", "isSubscriptionCancelled", "onPayment", "onTrial", "registrationDate", "subscriptionStatus"]
                  : ["user_id"]
              }
              actions={false}
            />
            <Row className="mt-5"></Row>
          </div>
        )}
      </PopUpModal>
    </>
  );
};

export default RecipientsModal;
