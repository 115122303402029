import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopUpModal from "../../Components/Modal";
import ForcedRedirectionsModal from "../../Components/ForcedRedirectionsModal";
import { toast } from "react-toastify";
import axios from "axios";
import { FormBuilder } from "@ginkgo-bioworks/react-json-schema-form-builder";
import validator from "@rjsf/validator-ajv8";
import FromOfFormBuilder from "@rjsf/core";
import RedirectionsTable from "./RedirectionsTable";
import { useNavigate } from "react-router-dom";
import Markdown from "../../Components/MarkdownEditor/Markdown";
import style from "../../styles/ForcedRedirections.module.css";
const ForcedRedirections = () => {
  const navigate = useNavigate();
  const [refresh, toggle] = useState(false);
  const [showDataModal, setShowDataModal] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [formInput, setFormInput] = useState(false);
  const [modalInput, setModalInput] = useState(false);
  const [schema, setSchema] = useState("{}");
  const [uischema, setUischema] = useState("{}");
  const [formData, setFormData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [title, setTitle] = useState("");
  const [heading, setHeading] = useState("");
  const [textbody, setTextBody] = useState("");
  const [validated, setValidated] = useState(false);
  const [icon, setIcon] = useState("");
  const [userConsentrequired, setUserConsentRequired] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [textBodyText, setTextBodyText] = useState("");

  useEffect(() => {}, [refresh]);
  const getShopsUsers = async () => {
    try {
      const responseData = await axios.get(process.env.REACT_APP_API_URL + `/shops/fetch-shops-all-users`, { withCredentials: true });
      const data = responseData?.data;
      if (data) {
        setSelectedUsers(data);
        setSelectedRows([]);
      }
    } catch (error) {
      setSelectedUsers([]);
    }
  };
  const handleSchemaChange = (newSchema, newUiSchema) => {
    setSchema(newSchema);
    setUischema(newUiSchema);
  };

  const handleFormChange = ({ formData: newFormData }) => {
    setFormData(newFormData);
  };
  let mergedObjectString;
  const handleFormSubmit = async (e) => {
    const UIschema = JSON.parse(uischema);
    const Schema = JSON.parse(schema);

    // Merge the objects using the spread operator
    const mergedObject = { ...Schema, ...UIschema };

    // Convert the merged object back to a JSON string
    mergedObjectString = JSON.stringify(mergedObject);

    try {
      e.preventDefault();
      setValidated(true);
      if (title?.length > 0 && (selectedRows.length > 0 || selectedUsers.length > 0) && formInput && mergedObjectString !== "{}" && JSON.parse(mergedObjectString).title !== "" && JSON.parse(mergedObjectString).hasOwnProperty("properties")) {
        setIsClicked(true);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + `/forced-redirections`,
          {
            users_id: selectedRows.length > 0 ? selectedRows : selectedUsers.length > 0 ? selectedUsers : [],
            title,
            json_object: mergedObjectString,
          },
          { withCredentials: true }
        );
        if (res) {
          setValidated(false);
          toast.success(`${res?.data?.message}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          setSchema("{}");
          setSelectedRows("");
          setSelectedUsers("");
          setTitle("");
          setModalInput(false);
          setFormInput(false);
          setHeading("");
          setTextBody("");
          setIcon("");
          setUserConsentRequired("");
          setTimeout(() => {
            navigate("/forced-redirections");
          }, 1000);
        }
      }
    } catch (error) {
      setIsClicked(true);
      toast.error(`${error.response.data.error.message}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
        className: "toastMsg",
      });
      setIsClicked(false);
    }
  };
  const handleModalFormSubmit = async (e) => {
    try {
      e.preventDefault();
      setValidated(true);
      if (title?.length > 0 && (selectedRows.length > 0 || selectedUsers.length > 0) && modalInput && heading.length > 0 && textbody?.length > 0 && icon && userConsentrequired) {
        setIsClicked(true);
        const dataObject = {
          heading,
          textbody,
          icon,
          userConsentrequired,
        };
        const json_object = JSON.stringify(dataObject);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + `/forced-redirections`,
          {
            users_id: selectedRows.length > 0 ? selectedRows : selectedUsers.length > 0 ? selectedUsers : [],
            title,
            json_object,
          },
          { withCredentials: true }
        );
        if (res) {
          setValidated(false);
          toast.success(`${res?.data?.message}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          setSelectedRows("");
          setSelectedUsers("");
          setTitle("");
          setModalInput(false);
          setFormInput(false);
          setHeading("");
          setTextBody("");
          setIcon("");
          setUserConsentRequired("");
          setTimeout(() => {
            navigate("/forced-redirections");
          }, 1000);
        }
      }
    } catch (error) {
      setIsClicked(true);
      toast.error(`${error.response.data.error.message}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
        className: "toastMsg",
      });
      setIsClicked(false);
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="mb-4">
          <Col xs="6" lg="2" className="">
            <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={getShopsUsers}>
              <FontAwesomeIcon icon={faPlus} /> <span className="d-none d-lg-inline">Global Redirection</span>{" "}
            </Button>
          </Col>
          <Col xs="2" lg="2">
            <Button
              className="h-100 w-100"
              style={{ fontSize: "0.75rem" }}
              size="sm"
              type="submit"
              onClick={() => {
                setShowDataModal(true);
                setSelectedUsers([]);
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> <span className="d-none d-lg-inline">Selective Redirection</span>{" "}
            </Button>
          </Col>
        </Row>
        <Form noValidate validated={validated}>
          {selectedUsers.length > 0 || selectedRows.length > 0 ? (
            <div className="rounded-3 text-center bg-white border-1 border-secondary overflow-hidden m-0 mb-0 mt-4 text-nowrap mx-auto" style={{ width: "70%" }}>
              <Row
                className="m-5 px-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col>
                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label className="mb-0 d-flex justify-content-start">
                      Title<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Write Title"
                      name="title"
                      value={title}
                      onChange={(e) => {
                        if (e.target.value.length < 50) setTitle(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">Please Write Title</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-around">
                    <Form.Check
                      type="radio"
                      label="Modal Input"
                      name="radioGroup"
                      disabled={title.length === 0}
                      onClick={() => {
                        setModalInput(true);
                        setFormInput(false);
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label="Form Input"
                      name="radioGroup"
                      disabled={title.length === 0}
                      onClick={() => {
                        setFormInput(true);
                        setModalInput(false);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}

          {modalInput ? (
            <div className="rounded-3 text-center bg-white border-1 border-secondary overflow-hidden m-0 mb-0 mt-4 text-nowrap mx-auto" style={{ width: "70%" }}>
              <Row className="m-5 px-3">
                <Col>
                  <Form.Group className="mb-3" controlId="additionalInput">
                    <Form.Label className="mb-0 d-flex justify-content-start">
                      Heading<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Heading"
                      name="heading"
                      value={title.length === 0 ? "" : heading}
                      disabled={title.length === 0}
                      onChange={(e) => {
                        if (e.target.value.length < 50) {
                          setHeading(e.target.value);
                        }
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">Please Enter Heading</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} lg="12" md="12" sm="12" className="mb-3" controlId="description">
                    <Markdown label={"Text"} value={textbody} setValue={setTextBody} setText={setTextBodyText} placeholder={"Enter Text (5,000 Characters)"} />
                    <Form.Control
                      hidden
                      as="textarea"
                      placeholder="Enter Text (5,000 Characters)"
                      value={textBodyText.trim()}
                      onChange={(e) => {
                        setTextBody(e.target.value);
                      }}
                      maxLength={5000}
                      required
                    />
                    <Form.Control.Feedback type="invalid">Please Enter the Text.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between">
                      <Form.Check
                        type="radio"
                        label="Info"
                        name="iconGroup"
                        onClick={() => {
                          setIcon("info");
                        }}
                      />
                      <Form.Check
                        type="radio"
                        label="Warning"
                        name="iconGroup"
                        onClick={() => {
                          setIcon("warning");
                        }}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid" style={{ display: validated && icon === "" ? "block" : "none" }}>
                      Please Select an Option
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between">
                      <Form.Check
                        type="radio"
                        label="Continue Anyway"
                        name="consentGroup"
                        onClick={() => {
                          setUserConsentRequired("Continue Anyway");
                        }}
                      />
                      <Form.Check
                        type="radio"
                        label="Continue if Accepted"
                        name="consentGroup"
                        onClick={() => {
                          setUserConsentRequired("Continue if Accepted");
                        }}
                      />
                    </div>

                    <Form.Control.Feedback type="invalid" style={{ display: validated && userConsentrequired === "" ? "block" : "none" }}>
                      Please Select an Option
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-center mt-4">
                    <Button type="submit" className=" col-lg-2 col-6" onClick={handleModalFormSubmit}>
                      Save Form
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </Form>
        {formInput ? (
          <div className="d-flex justify-content-center align-items-center rounded-3 text-center bg-white border-1 border-secondary overflow-hidden m-0 mb-0 mt-4 mx-auto" style={{ width: "70%" }}>
            <div className={`${style.formBuilderDiv} m-5 px-3 w-100`}>
              <FormBuilder schema={schema} uischema={uischema} onChange={handleSchemaChange} />
              <div className="d-flex justify-content-center mt-4">
                <Button disabled={isClicked || !JSON.parse(schema)?.hasOwnProperty("properties")} type="submit" className=" col-lg-2 col-6" onClick={handleFormSubmit}>
                  Save Form
                </Button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {schema !== "{}" ? (
          <div className="d-flex justify-content-center align-items-center rounded-3 text-center bg-white border-1 border-secondary overflow-hidden m-0 mb-0 mt-4 mx-auto" style={{ width: "70%" }}>
            <div className={` m-5 px-3 w-100`}>
              <FromOfFormBuilder schema={JSON.parse(schema)} uiSchema={JSON.parse(uischema)} formData={formData} onChange={handleFormChange} validator={validator} />
            </div>
          </div>
        ) : (
          ""
        )}
      </Container>
      <PopUpModal
        size="lg"
        title="Selective Redirections"
        show={showDataModal}
        onHide={() => {
          setShowDataModal(false);
          setSelectedRows([]);
        }}
      >
        <ForcedRedirectionsModal close={() => setShowDataModal(false)} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
      </PopUpModal>
    </>
  );
};

export default ForcedRedirections;
