import { useState } from "react";
import Topbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar/Sidebar";
import style from "./../styles/Sidebar.module.css";
import { Outlet } from "react-router-dom";
import BreadCrumb from "../Components/BreadCrumb/BreadCrumb";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DashboardLayout = ({ onClick }) => {
  const [isSandwichOpen, setIsSandwichOpen] = useState(true);

  const toggle = (e) => {
    setIsSandwichOpen((prev) => {
      return !prev;
    });
  };
  return (
    <div className={`${style.dashboardLayout}`}>
      <Topbar toggle={toggle} isSandwichOpen={isSandwichOpen} />
      <div className={`${isSandwichOpen ? style.sidebarLayoutClose : style.sidebarLayout}`} style={{ zIndex: "2", position: "relative" }}>
        <Sidebar setIsSandwichOpen={setIsSandwichOpen} isSandwichOpen={isSandwichOpen} toggle={toggle} />
      </div>
      <div
        className={`
         ${isSandwichOpen ? style.contentClose : style.content}`}
      >
        <div className={`${style.contentDiv} ${style.scroll}  `}>
          <div className="mt-1">
            <BreadCrumb />
          </div>
          <div className={`mt-3 px-2`}>
            <Outlet />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DashboardLayout;
