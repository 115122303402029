import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import { ToastContainer } from "react-toastify";
import moment from "moment/moment";
import NoResult from "../../Components/NoResult";
import customloader from "../../assets/images/RollingLoader.gif";
import DatePicker from "../../Components/DatePicker/DatePicker";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
import RequestSubmissionModal from "./RequestSubmissionModal";

const PlanRequests = () => {
  let [plansRequests, setPlansRequests] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [searchInput, setSearchInput] = useState("");
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [status, setStatus] = useState({ value: null, label: "All" });
  const [requestProcessModal, setRequestProcessModal] = useState(false);
  const [RequestId, setRequestId] = useState();

  const fetchAllPlanRequestsCall = useApi(api.fetchPlanRequests);
  const processPlanRequestCall = useApi(postapi.processPlanRequestCall);
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const handleStatus = (e) => {
    if (e.label === "All") {
      setStatus({ value: null, label: "All" });
    } else {
      setStatus({ value: e.value, label: e.label });
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setStatus({ value: null, label: "All" });
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const fetchPlanRequests = async () => {
    const res = await fetchAllPlanRequestsCall.request({
      size: sizeOfPages,
      page: currentPage,
      search: searchInput,
      order: orderBy,
      date: { from: fromDate, to: toDate },
      status: status?.value,
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setPlansRequests(data?.data);
      setCount(data?.count?.no_of_items);
      if (data?.count?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.count?.no_of_pages);
        if (currentPage > data?.count?.no_of_pages) {
          setCurrentPage(data?.count?.no_of_pages);
        }
      }
    } else {
      setPlansRequests([]);
    }
  };
  useEffect(() => {
    fetchPlanRequests();
  }, [currentPage, refresh, sizeOfPages, searchInput, fromDate, toDate, toggle, orderBy, status]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const processPlanRequest = (data) => {
    setRequestId(data);
    setRequestProcessModal(true);
  };
  const handleProcessPlanRequest = async (status) => {
    const res = await processPlanRequestCall.request(RequestId, status);
    if (res) {
      setRequestProcessModal(false);
      fetchPlanRequests();
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="2" lg="2" xl="2" className="d-none d-lg-inline mb-3"></Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              value={status}
              searchable={false}
              options={[
                { value: null, label: "All" },
                { value: "pending", label: "Pending" },
                { value: "approved", label: "Approved" },
                { value: "rejected", label: "Rejected" },
              ]}
              handleChange={handleStatus}
            />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : !plansRequests ? (
            <NoResult />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview
                data={plansRequests}
                columnNotShow={["plan_request_id", "is_active", "is_deleted", "fk_shop_id", "locations", "secondary_users"]}
                dateColumn={["created_at", "updated_at"]}
                sortColumn={["created_at", "updated_at", "shop_name"]}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                processPlanRequest={processPlanRequest}
                status={{
                  column: "status",
                  getClassName: (value) => {
                    if (value["status"] === "approved") {
                      return "text-success";
                    } else if (value["status"] === "pending") {
                      return "text-warning";
                    } else {
                      return "text-danger";
                    }
                  },
                  format: (value) => {
                    if (value["status"] === "approved") {
                      return "Approved";
                    } else if (value["status"] === "pending") {
                      return "Pending";
                    } else {
                      return "Rejected";
                    }
                  },
                }}
              />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
          {/* <Modal
            show={requestProcessModal}
            onHide={() => {
              setRequestProcessModal(false);
            }}
            animation={true}
            centered
          >
            <Modal.Header>
              <Modal.Title>Plan Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <span className="d-flex justify-content-center pb-4">What do you want to do with this plan request?</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gap-3">
                <Button className="w-100 rounded-3" onClick={() => handleProcessPlanRequest("approved")}>
                  Approve
                </Button>
                <Button className="w-100 rounded-3" onClick={() => handleProcessPlanRequest("rejected")}>
                  Reject
                </Button>
                <span
                  className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
                  style={{
                    cursor: "pointer",
                    border: "2px solid rgba(151, 156, 162, 1)",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    setRequestProcessModal(false);
                  }}
                >
                  Cancel
                </span>
              </div>
            </Modal.Footer>
          </Modal> */}
          <RequestSubmissionModal refresh={refresh} RequestId={RequestId} show={requestProcessModal} onHide={() => setRequestProcessModal(false)} />
        </>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default PlanRequests;
