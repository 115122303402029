import axios from "axios";
const API_URL = '/auth/';


// Login User

const getUserPermissions = async (id)=> {
    try{
        const res = await axios.get(process.env.REACT_APP_API_URL + `/user/get-tech-user-permissions/${id}`,{withCredentials: true});
        return res.data
    }catch(err){
        throw err.response?.data.error;
    }
}

const login = async (userData)=> {
    try{
        const res = await axios.post(process.env.REACT_APP_API_URL + API_URL + 'login', userData, { withCredentials: true});
        const userType = res.data.user.user_type;
        if(userType === 'admin' || userType === 'super-admin'){
            localStorage.setItem('refreshToken',res.data.refreshToken)
            const permission = await getUserPermissions(res.data.user.user_id);
            return {...res.data.user,...permission};
        }else{
            throw new Error("This user is not allowed to loged in!")
        }
    }catch(err){

        throw new Error(err.response?.data.error.message)
    }
}
const getUser = async (refreshToken)=> {
    try{
        const res = await axios.get(process.env.REACT_APP_API_URL +'/user/get-tech-user-by-accesstoken', { withCredentials: true, headers:{
            "Authorization": refreshToken
        }});
        const permission = await getUserPermissions(res.data.user_id);
        return {...res.data,...permission};
    }catch(err){
        throw new Error(err.response?.data.error.message)
    }
}


const authService = {
    login,
    getUser,
    getUserPermissions
}
export default authService;