import customloader from './../../assets/images/RollingLoader.gif'

const Loading = ({name}) => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
            <img src={customloader} alt='customloader' width='100' height="100" />
            <h3>{name}</h3>
        </div>
    );
}

export default Loading;