import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useApi from "../../Hooks/useApi";
import * as postapi from "../../api/postapi";
import axios from "axios";

function RequestSubmissionModal({ show, onHide, RequestId, refresh }) {
  const [payable, setPayable] = useState(0);
  const [validated, setValidated] = useState(false);
  const [paymentObj, setPaymentObj] = useState({
    transactionId: "",
    transDate: "",
    imageURl: "",
  });
  const [error, setError] = useState(false);
  const processPlanRequestCall = useApi(postapi.processPlanRequestCall, { hasCatchError: true });

  const handleImageUpload = (e) => {
    setPaymentObj({ ...paymentObj, [e.target.name]: e.target.files[0] });
  };

  const handleChange = (e) => {
    setPaymentObj({ ...paymentObj, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (RequestId?.billing_frequency == "Monthly") {
      setPayable(RequestId?.monthly_grand_total);
    } else if (RequestId?.billing_frequency == "Yearly") {
      setPayable(RequestId?.yearly_grand_total);
    } else {
      setPayable(RequestId?.quarterly_grand_total);
    }
  }, [RequestId]);

  const handleProcessPlanRequest = async (status) => {
    try {
      setValidated(true);
      if (RequestId.is_payment_done === 1 && status === "approved") {
        const res = await processPlanRequestCall.request(RequestId?.plan_request_id, {
          plan: {
            status: status,
          },
        });
        if (res) {
          onHide();
          setPayable();
          setValidated(false);
          setPaymentObj({
            transactionId: "",
            transDate: "",
            frequency: {},
            imageURl: "",
          });
          refresh((prev) => !prev);
          //   fetchPlanRequests()
        }
      }
      const formData = new FormData();
      formData.append("riciept", paymentObj.imageURl);
      const imgRes = status !== "approved" ? null : await axios.post(process.env.REACT_APP_URL_IMAGE_CDN + `/images/transactions/${RequestId?.fk_shop_id}`, formData, { withCredentials: true });
      const trans = {
        plan: {
          status: status,
        },
        ...(RequestId?.is_payment_done !== 1 && {
          transaction: {
            transaction_image_url: imgRes?.data?.files[0],
            credit: payable,
            arrears: 0,
            transaction_id: paymentObj.transactionId,
            transaction_date: paymentObj.transDate,
          },
        }),
      };
      if (status === "approved" ? Object.values(paymentObj).every((item) => item !== "" || item === {}) : true) {
        const res = await processPlanRequestCall.request(RequestId?.plan_request_id, trans);
        if (res) {
          onHide();
          setPayable();
          setValidated(false);
          setPaymentObj({
            transactionId: "",
            transDate: "",
            frequency: {},
            imageURl: "",
          });
          refresh((prev) => !prev);
        }
      }
    } catch (error) {
      if (error.response.data?.error?.message) {
        setError(error.response.data?.error?.message);
      }
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} animation={true} centered>
        <Form validated={validated}>
          <Modal.Header>
            <Modal.Title>Plan Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            {RequestId?.is_payment_done === 0 && (
              <Row>
                <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                  <label>
                    Transaction Id <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={paymentObj.transactionId}
                    name="transactionId"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter transaction Id"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please Enter Id.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                  <label>
                    Transaction Date<span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    name="transDate"
                    value={paymentObj.transDate}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                    type="date"
                  />

                  <Form.Control.Feedback type="invalid">Please select Date.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                  <label>
                    Upload Receipt<span className="text-danger">*</span>
                  </label>
                  <Form.Control type="file" accept="image/*" name="imageURl" onChange={(e) => handleImageUpload(e)} required />
                  {paymentObj?.imageURl === "" && validated && <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="price">
                  <label>
                    Payable <span className="text-danger">*</span>
                  </label>
                  <Form.Control value={Math.round(payable)} type="number" placeholder="Rs.1000" disabled />
                  <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                </Form.Group>
                <Col lg="12" className="text-center mt-3">
                  {paymentObj?.imageURl && (
                    <div style={{ maxWidth: "100%", maxHeight: "400px" }} className=" ">
                      {" "}
                      <img src={URL.createObjectURL(paymentObj?.imageURl)} height="350" width="250" alt="reciept" />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex gap-3">
              <Button className="w-100 rounded-3" onClick={() => handleProcessPlanRequest("approved")}>
                Approve
              </Button>
              <Button className="w-100 rounded-3" onClick={() => handleProcessPlanRequest("rejected")}>
                Reject
              </Button>
              <span
                className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
                style={{
                  cursor: "pointer",
                  border: "2px solid rgba(151, 156, 162, 1)",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  onHide();
                  setValidated(false);
                  setPayable();
                  setPaymentObj({
                    transactionId: "",
                    transDate: "",
                    frequency: {},
                    imageURl: "",
                  });
                }}
              >
                Cancel
              </span>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default RequestSubmissionModal;
