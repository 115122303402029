import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import useMarkedFields from "../../Hooks/validationHook";
import style from "./../../styles/ResetPassword.module.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
import ReactSelect from "../ReactSelect";
import Loader from "../Loader";
import Select from "react-select";
const AddRequest = ({ id, data, close, toggle }) => {
  const [title, setTitle] = useState();
  const [shop, setShop] = useState([]);
  const [plan, setPlan] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [frequency, setFrequency] = useState([]);

  const [description, setDescription] = useState();
  const [handledBy, setHandledBy] = useState("");
  const [cancelCheq, setCancelCheq] = useState(false);
  const [requestType, setRequestType] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [priority, setPriority] = useState([]);
  const [planOPtions, setPlanOptions] = useState([]);
  const [error, setError] = useState("");
  const dropdownShops = useApi(api.getDropdownShops);
  const dropdownPlans = useApi(api.getPlansDropDown);
  const addreq = useApi(postapi.AddRequest);
  const [filterInput, setFilterInput] = useState("".replace(/\W/g, ""));
  const [shopId, setShopId] = useState();
  const [validated, setValidated] = useState(false);

  const getPlans = async () => {
    return new Promise((resolve, reject) => {
      dropdownPlans
        .request({
          plan_type: "fixed",
          shop_id: shop?.value?.id,
          is_active: true,
        })
        .then((res) => {
          const result = res.data.filter((cat) =>
            cat.plan_name
              .concat("-", cat.plan_name)
              .toLowerCase()
              .includes(filterInput.toLowerCase())
          );
          setPlanOptions(res.data);
          const getData = result.map((cat) => ({
            value: cat.id,
            label: (
              <>
                <strong className="fs-14" name={cat.plan_name}>
                  {cat.plan_name}
                </strong>
                <p className="fs-12">
                  {cat.plan_type} <br />
                  {/* {cat.email} */}
                  {/* <br />
                  {cat.username} */}
                </p>
              </>
            ),
            name: cat.plan_name,
          }));
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };

  useEffect(() => {
    getPlans();
    setTitle(
      requestType?.value === "plan-cancellation-request"
        ? `${requestType?.value?.replace("-", "_")}`
        : frequency?.value && requestType?.value
        ? `[${frequency?.value}]-${requestType?.value?.replace("-", "_")}`
        : ""
    );
  }, [frequency, requestType, shop]);
  const getShops = async () => {
    return new Promise((resolve, reject) => {
      dropdownShops
        .request()
        .then((res) => {
          const result = res.data.filter((cat) =>
            cat.shop_name
              .concat("-", cat.shop_name)
              .toLowerCase()
              .includes(filterInput.toLowerCase())
          );
          const getData = result.map((cat) => ({
            value: cat,
            label: (
              <>
                <strong className="fs-12" name={cat.shop_name}>
                  {cat.shop_name}
                </strong>
                <p className="fs-10 text-lowercase">
                  {cat.whatsapp} <br />
                  {/* {cat.email} */}
                  {cat.username}
                </p>
              </>
            ),
            name: cat.shop_name,
          }));
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };

  async function submit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    try {
      setValidated(true);
      if (title.length > 0 && shop?.value.id > 0 && description) {
        if (!(requestType.value !== "plan-cancellation-request" && !plan)) {
          setIsClicked(true);
          const obj = {
            request_title: title,
            shop_id: shop.value.id,
            plan_id:
              requestType.value === "plan-cancellation-request" ? -1 : plan,
            request_type: requestType.value,
            request_description: description,
            handled_by: user.user_id,
            request_status: "in_progress",
            priority: priority.value,
          };
          const data = await addreq.request(obj);
          if (data.data.error) {
          } else {
            toast.success("Request Added successfully ", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            close();
            setValidated(false);
          }
          setIsClicked(false);

          toggle((prev) => !prev);
        }
      } else {
        setIsClicked(false);
      }
    } catch (error) {
      setError(error.response.data.error.message);
      setIsClicked(false);
    }
  }

  return (
    <>
      {addreq?.data?.error && (
        <div class="alert alert-danger text-center" role="alert">
          {addreq?.data?.error?.message}
        </div>
      )}

      <Form noValidate validated={validated} onSubmit={submit}>
        <Row>
          <Form.Group
            className="my-2"
            as={Col}
            lg="6"
            md="6"
            sm="12"
            controlId="formBasicShopName"
          >
            <label>
              Title <span className="text-danger">*</span>
            </label>
            <Form.Control
              className="p-2"
              name="Title"
              type="text"
              placeholder="Title"
              value={title}
              disabled
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              * Please Enter Title
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
            <label>
              Shop <span className="text-danger">*</span>
            </label>
            <ReactSelect
              placeholder="Select shop"
              value={shop}
              searchable={true}
              options={getShops}
              handleChange={(e) => {
                setShop({
                  value: e.value,
                  label: e.name,
                });
              }}
              onInputChange={(newInput) => {
                const inputValue = newInput.replace(/\s/g, "");
                setFilterInput(newInput);
                return inputValue;
              }}
            />
            <Form.Control
              className="p-2"
              name="shop"
              hidden
              type="text"
              value={Object.keys(shop)?.length > 0 ? shop?.value : null}
              onChange={() => {}}
              required
            />
            <Form.Control.Feedback type="invalid">
              * Please Select Shop
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
            <label>
              Request Type <span className="text-danger">*</span>
            </label>
            <SearchableSelect
              placeholder="Select Request Type"
              selectClassName={"bg-white"}
              defaultValue={requestType}
              value={requestType}
              searchable={false}
              options={
                shop?.value?.on_payment === 1 && shop?.value?.on_trial === 0
                  ? [
                      {
                        value: "plan-cancellation-request",
                        label: "plan Cancellation Request",
                      },
                      {
                        value: "reactivate-plan",
                        label: "Reactivate Plan",
                      },
                      {
                        value: "change-plan-request",
                        label: "change plan request",
                      },
                    ]
                  : [
                      {
                        value: "paid-plan-request",
                        label: "Paid Plan Request",
                      },
                    ]
              }
              handleChange={(e) => {
                setRequestType(e);
                if (e.value === "plan-cancellation-request") {
                  setTitle("plan Cancellation Request");
                }
              }}
            />
            <Form.Control
              className="p-2"
              name="priority"
              hidden
              type="text"
              value={
                Object.keys(requestType)?.length > 0 ? requestType?.value : null
              }
              onChange={() => {}}
              required
            />
            <Form.Control.Feedback type="invalid">
              * Please Select request type
            </Form.Control.Feedback>
          </Form.Group>
          {requestType.value !== "plan-cancellation-request" && (
            <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
              <label>
                Plan <span className="text-danger">*</span>
              </label>
              <Form.Select
                onChange={(e) => {
                  setPlan(e.target.value);
                }}
              >
                <option hidden style={{ color: "#808080 !important" }}>
                  Select Plan
                </option>
                {planOPtions.map((data) => (
                  <option value={data.id}>{data.plan_name}</option>
                ))}
              </Form.Select>
              <Form.Control
                className="p-2"
                name="plan"
                hidden
                type="text"
                value={plan?.length > 0 ? plan : null}
                onChange={() => {}}
                required
              />
              <Form.Control.Feedback type="invalid">
                * Please Select Plan
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {requestType.value !== "plan-cancellation-request" && (
            <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
              <label>
                Frequency <span className="text-danger">*</span>
              </label>
              <SearchableSelect
                placeholder="Select frequency "
                selectClassName={"bg-white"}
                defaultValue={frequency}
                value={frequency}
                searchable={false}
                options={[
                  { value: "Monthly", label: "Monthly" },
                  { value: "Quarterly", label: "Quarterly" },
                  { value: "Yearly", label: "Yealy" },
                ]}
                handleChange={(e) => {
                  setFrequency(e);
                  setTitle(`[${e.value}]-`);
                }}
              />
              <Form.Control
                className="p-2"
                name="city"
                hidden
                type="text"
                value={
                  Object.keys(frequency)?.length > 0 ? frequency?.value : null
                }
                onChange={() => {}}
                required
              />
              <Form.Control.Feedback type="invalid">
                * Please Select priority
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
            <label>
              Priority <span className="text-danger">*</span>
            </label>
            <SearchableSelect
              placeholder="Select Priority "
              selectClassName={"bg-white"}
              defaultValue={priority}
              value={priority}
              searchable={false}
              options={[
                { value: "high", label: "high" },
                { value: "medium", label: "medium" },
                { value: "low", label: "low" },
              ]}
              handleChange={(e) => {
                setPriority(e);
              }}
            />
            <Form.Control
              className="p-2"
              name="city"
              hidden
              type="text"
              value={Object.keys(priority)?.length > 0 ? priority?.value : null}
              onChange={() => {}}
              required
            />
            <Form.Control.Feedback type="invalid">
              * Please Select priority
            </Form.Control.Feedback>
          </Form.Group>
          <Col className="mt-2 mb-4" lg="12" md="6" sm="12">
            <Form.Group>
              <label>
                Description <span className="text-danger">*</span>
              </label>
              <Form.Control
                required
                value={description}
                onChange={(e) => {
                  // setCheckValidation(e.target.checked);
                  setDescription(e.target.value);
                }}
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className={`mb-4 ${style.fullWidth}`}>
          <Button className="w-100" type="submit">
            {isClicked ? <Loader /> : "Add Request"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddRequest;
