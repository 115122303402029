import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";

import SearchableSelect from "../../Components/SearchableSelect";

import Plans from "../../Components/Plans/Plans";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const PlansPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const { user } = useSelector((state) => state.auth);

  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [addUserShow, setAddUserShow] = useState(true);
  const [refresh, toggle] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userTypeOptions, setUserTypeOptions] = useState([
    { value: "super-admin", label: "Super Admin" },
    { value: "admin", label: "Admin" },
    { value: "marketing", label: "marketing" },
    { value: "data", label: "data" },
  ]);
  const [status, setStatus] = useState({ value: true, label: "only Active" });

  // shops on  payment

  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({ plan_id: "ASC" });
  // INCOMPLETE ------------------------------------

  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const resetFilterHandle = () => {
    setUserType(null);
    setSearchInput("");
    setStatus({ value: true, label: "Active" });
  };
  
  useEffect(() => {}, [refresh]);

  const handleTabs = (e) => {
    if (e === "SellerUser") {
      setAddUserShow(false);
      setUserTypeOptions([
        { value: "primary", label: "Primary" },
        { value: "secondary", label: "Secondary" },
      ]);
    } else {
      setAddUserShow(true);
      setUserTypeOptions([
        { value: "super-admin", label: "Super Admin" },
        { value: "admin", label: "Admin" },
        { value: "marketing", label: "marketing" },
        { value: "data", label: "data" },
      ]);
    }
    resetFilterHandle();
  };
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  return (
    <>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>

          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs lg="2" className="d-none d-lg-inline">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              defaultValue={{ value: true, label: "only Active" }}
              value={status}
              searchable={false}
              options={[
                { value: true, label: "only Active" },
                { value: false, label: "All" },
              ]}
              handleChange={(e) => {
                setStatus(e);
              }}
            />
          </Col>
          {(user.user_type === "super-admin" || user.user_type === "admin") && (
            <Col xs="2" lg="2">
              <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={() => navigate("/subscription/addplans")}>
                <span className="d-none d-lg-inline">Add Plan</span> <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          )}
          {/* For Mobile screen End */}
        </Row>

        <>
          <div className="pt-2">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Plans search={searchInput} status={status} orderBy={orderBy} setOrderBy={setOrderBy} sizeOfPage={sizeOfPages} userType={userType} refresh={refresh} toggle={toggle} />
            </Col>
          </Row>
        </>
      </Container>
    </>
  );
};
export default PlansPage;
