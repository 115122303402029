import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import useMarkedFields from "../../Hooks/validationHook";
import style from "./../../styles/ResetPassword.module.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";
import { toast } from "react-toastify";

import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
const AddNotes = ({ id, data, close, toggle }) => {
  const [note, setNote] = useState("");

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [bindField, setAllFieldsTouched] = useMarkedFields();
  const postNote = useApi(postapi.AddNote);
  const getNote = useApi(api.getNote);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNote.request(id);
        setNote(data?.data?.success?.data?.notes);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (note) {
      try {
        await postNote.request(id, note);
        close();
        toggle((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Form autoComplete="nope" onSubmit={handleSubmit}>
        {error && <p className="text-danger p-2 text-center my-3 rounded-1">*{error}</p>}
        {success && <p className="text-success p-2 text-center my-3 rounded-1">*{success}</p>}

        <Row className="mb-2">
          <Form.Label className="mb-1">
            Note <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Col lg={"12"}>
            <Form.Group>
              <Form.Control
                required
                value={note}
                onChange={(e) => {
                  // setCheckValidation(e.target.checked);
                  setNote(e.target.value);
                }}
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          type="submit"
          className="w-100"
          onClick={() => {
            setAllFieldsTouched();
          }}
        >
          Save
        </Button>
      </Form>
    </>
  );
};

export default AddNotes;
