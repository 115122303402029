import React from "react";
import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import { toast } from "react-toastify";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import PopUpModal from "../../Components/Modal";
import EditConsumable from "../../Components/ConsumableItems/EditConsumable";
import AddCharges from "../../Components/order/AddCharges";
function DeliveryCharges() {
  let [consumableItems, setConsumableItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "ASC" });
  const [addChargesModalShow, setAddChargesModalShow] = useState(false);
  const [consumableItemData, setConsumableItemData] = useState({});
  const [editModal, setEditModal] = useState(false);
  const fetchAllCharges = useApi(api.getDeliveryCharges);
  const resetFilterHandle = () => {
    setSizeOfPages(10);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const fetchConsumableItems = async () => {
    const res = await fetchAllCharges.request({
      size: sizeOfPages,
      page: currentPage,
      orderBy: orderBy,
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setConsumableItems(data?.data);
      setCount(data?.count?.no_of_items);
      if (data?.count?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.count.no_of_pages);
        if (currentPage > data?.count?.no_of_pages) {
          setCurrentPage(data?.count?.no_of_pages);
        }
      }
    } else {
      setConsumableItems([]);
    }
  };
  useEffect(() => {
    console.log("");
    fetchConsumableItems();
  }, [currentPage, addChargesModalShow, sizeOfPages, toggle, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const edit = (data) => {
    setEditModal(true);
    setConsumableItemData(data);
  };
  const closeModalEdit = () => {
    setAddChargesModalShow(false);
    setEditModal(false);
  };

  return (
    <div>
      <div>
        <Container fluid>
          <Row className="">
            <Col xs="6" lg="2" className="">
              <SearchableSelect
                selectClassName={"bg-white"}
                placeholder={`Showing ${sizeOfPages}`}
                defaultValue={`Showing ${sizeOfPages}`}
                value={`Showing ${sizeOfPages}`}
                searchable={false}
                options={[
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "30", label: "30" },
                ]}
                handleChange={handlePageSize}
              />
            </Col>
            <Col>
              <Button variant="secondary" className=" mx-3 " size="sm" type="submit" style={{ fontSize: "14px" }} onClick={() => setAddChargesModalShow(true)}>
                <span className="d-flex ">Add Charges</span>{" "}
              </Button>
            </Col>
          </Row>
          <>
            <div className="pt-2">
              <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
                <span className="d-flex ">Reset Filters</span>{" "}
              </Button>
            </div>

            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                <img src={customloader} alt="customloader" width="100" height="100" />
              </div>
            ) : !consumableItems ? (
              <NoResult />
            ) : (
              <Row style={{ marginBottom: "-3rem" }} className="mt-3">
                <CustomizeTableview data={consumableItems} columnNotShow={["id"]} dateColumn={["created_at", "updated_at"]} sortColumn={["created_at", "updated_at", "name"]} orderBy={orderBy} setOrderBy={setOrderBy} edit={edit} />
                <Row className="mt-5">
                  <Col className="d-none d-lg-block" md="6" lg="3">
                    <span className="text-secondary fs-6">{count} Result(s)</span>
                  </Col>
                  <Col xs="12" lg="6">
                    <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{count} Result(s)</span>
                  </Col>
                  <Col xs="6" lg="3" className="text-end mt-3"></Col>
                </Row>
              </Row>
            )}
            <PopUpModal title="Update Price" show={addChargesModalShow} onHide={() => setAddChargesModalShow(false)}>
              <AddCharges toggle={refresh} close={closeModalEdit} consumableItemData={consumableItemData} edit={false} />
            </PopUpModal>
            <PopUpModal title="Update Price" show={editModal} onHide={() => setEditModal(false)}>
              <AddCharges toggle={refresh} close={closeModalEdit} consumableItemData={consumableItemData} edit={true} />
            </PopUpModal>
          </>
        </Container>
      </div>
    </div>
  );
}

export default DeliveryCharges;
