import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";

import SearchableSelect from "../../Components/SearchableSelect";

import Plans from "../../Components/Plans/Plans";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import PopUpModal from "../../Components/Modal";
import EditThemeForm from "../../Components/Themes/EditTheme";
import PaginationBar from "../../Components/PaginationBar";
import Loader from "../../Components/Loader";
import Loading from "../../Components/Loading/Loading";
import NoResult from "../../Components/NoResult";

const Themes = () => {
  const [searchInput, setSearchInput] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [action, setAction] = useState(true);
  const [ThemeId, setThemeId] = useState(null);
  const [showEditThemeModal, setShowEditThemeModal] = useState(false);
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [addUserShow, setAddUserShow] = useState(true);
  const [refresh, toggle] = useState(false);
  const [userType, setUserType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [description, setDescription] = useState();
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [userTypeOptions, setUserTypeOptions] = useState([
    { value: "super-admin", label: "Super Admin" },
    { value: "admin", label: "Admin" },
    { value: "marketing", label: "marketing" },
    { value: "data", label: "data" },
  ]);

  const [status, setStatus] = useState({ value: true, label: "only Active" });
  const DeleteTheme = useApi(postapi.DeleteTheme);
  const EditTheme = useApi(postapi.EditTheme);
  // shops on  payment

  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({ plan_id: "ASC" });
  // INCOMPLETE ------------------------------------

  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const edit = async (data) => {
    setThemeId(data);
    setShowEditThemeModal(true);
    await EditTheme.request(data["id"], data).then(() => {
      toggle((prev) => !prev);
    });
  };

  const remove = async (data) => {
    setThemeId(data);
    setShowRemoveConfirmationModal(true);
    await DeleteTheme.request(data["id"]).then(() => {
      toggle((prev) => !prev);
    });
  };
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const closeModal = () => {
    setShowDeactivateModal(false);
  };

  const handleStatus = async (id, status) => {
    if (status) {
      try {
        // await deactivateplan.request(id);
        closeModal();
        toggle((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        // await activateplan.request(id);
        closeModal();
        toggle((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const resetFilterHandle = () => {
    setUserType(null);
    setSearchInput("");
    setStatus({ value: true, label: "Active" });
  };
  useEffect(() => {}, [refresh]);

  const handleTabs = (e) => {
    if (e === "SellerUser") {
      setAddUserShow(false);
      setUserTypeOptions([
        { value: "primary", label: "Primary" },
        { value: "secondary", label: "Secondary" },
      ]);
    } else {
      setAddUserShow(true);
      setUserTypeOptions([
        { value: "super-admin", label: "Super Admin" },
        { value: "admin", label: "Admin" },
        { value: "marketing", label: "marketing" },
        { value: "data", label: "data" },
      ]);
    }
    resetFilterHandle();
  };
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const fetchThemes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `/themes?size=${sizeOfPages}&page=${currentPage}&name=${searchInput}`, { withCredentials: true });
      const themes = response.data.data;

      // Map and transform the themes data
      const transformedThemes = themes.map((theme) => ({
        id: theme.id,
        thumbnail: theme.thumbnail,
        Theme_Name: theme.theme_name,
        Description: theme.description,
        number_of_Sections: theme.num_sections,
        Maximum_Products_Per_Section: theme.max_products_per_section,
        Top_Categories: theme.isTopCategories === 1 ? "Yes" : "No",
        Primary_Banner_Limit: theme.primaryBannerLimit,
        Secondary_Banner_Limit: theme.secondaryBannerLimit ? theme.secondaryBannerLimit : 0,
      }));

      setData(transformedThemes);
      setCount(response?.data?.count?.no_of_items);
      setNoOfPages(response?.data?.count?.no_of_pages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching themes:", error);
    }
  };

  const handleDescriptionModal = (row) => {
    setDescription(row["Description"]);
    setDescriptionModal(true);
  };

  useEffect(() => {
    fetchThemes();
  }, [refresh, sizeOfPages, currentPage]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Container fluid>
          <Row className="">
            <Col xs="2" lg="2" className="d-none d-lg-inline">
              <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
            </Col>

            <Col xs="6" lg="2" className="">
              <SearchableSelect
                selectClassName={"bg-white"}
                placeholder={`Showing ${sizeOfPages}`}
                defaultValue={`Showing ${sizeOfPages}`}
                value={`Showing ${sizeOfPages}`}
                searchable={false}
                options={[
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "30", label: "30" },
                ]}
                handleChange={handlePageSize}
              />
            </Col>
            {/* <Col xs lg="2" className="d-none d-lg-inline">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              defaultValue={{ value: true, label: "only Active" }}
              value={status}
              searchable={false}
              options={[
                { value: true, label: "only Active" },
                { value: false, label: "All" },
              ]}
              handleChange={(e) => {
                setStatus(e);
              }}
            />
          </Col> */}
            {(user.user_type === "super-admin" || user.user_type === "admin") && (
              <Col xs="2" lg="2">
                <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={() => navigate("/theme/add")}>
                  <span className="d-none d-lg-inline">Add Theme</span> <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            )}
            {/* For Mobile screen End */}
          </Row>

          <>
            <div className="pt-2">
              <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
                <span className="d-none d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>
            {data?.length > 0 ? (
              <>
                <Row style={{ marginBottom: "-3rem" }} className="mt-3">
                  <Col>
                    <CustomizeTableview
                      data={data}
                      columnNotShow={["id", "HasSections", "isPrimaryBanner", "IsSecondaryBanner", "isDeleted", "thumbnail", "Description"]}
                      thumbnail={{
                        title: "Thumbnail",
                        img: "img0",
                        description: "title",
                      }}
                      Description={handleDescriptionModal}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      edit={(action && user.user_type === "super-admin") || user.user_type === "admin" ? edit : false}
                      remove={(action && user.user_type === "super-admin") || user.user_type === "admin" ? remove : false}
                      // toggleActionButton={
                      //   (user.user_type === "super-admin" || user.user_type === "admin") && {
                      //     label: (data) => {
                      //       if (data["is_active"] === 1) {
                      //         return "Deactivate";
                      //       } else {
                      //         return "Activate";
                      //       }
                      //     },
                      //     onClick: (data) => {
                      //       if (data["is_active"] === 1) {
                      //         handleDeactivate(data["id"]);
                      //       } else {
                      //         handleActivate(data["id"]);
                      //       }
                      //     },
                      //     variant: (data) => {
                      //       if (data["is_active"] === 1) {
                      //         return "outline-danger";
                      //       } else {
                      //         return "outline-primary";
                      //       }
                      //     },
                      //     style: { padding: "8px", fontSize: "12px" },
                      //     column: "is_active",
                      //   }
                      // }
                    />

                    {/* <Plans
                search={searchInput}
                status={status}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                sizeOfPage={sizeOfPages}
                userType={userType}
                refresh={refresh}
                toggle={toggle}
              /> */}
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col className="d-none d-lg-block" md="6" lg="3">
                    <span className="text-secondary fs-6">{count} results</span>
                  </Col>
                  <Col xs="12" lg="6">
                    <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{count} Result</span>
                  </Col>
                  <Col xs="6" lg="3" className="text-end mt-3"></Col>
                </Row>
              </>
            ) : (
              <NoResult />
            )}
          </>
        </Container>
      )}

      <PopUpModal size="lg" title="Edit Theme" show={showEditThemeModal} onHide={() => setShowEditThemeModal(false)}>
        <EditThemeForm id={ThemeId?.id} close={() => setShowEditThemeModal(false)} toggle={toggle} />
      </PopUpModal>
      <PopUpModal size="lg" title="Description" show={descriptionModal} onHide={() => setDescriptionModal(false)}>
        <Modal.Body>
          {description?.split(",")?.map((description, index) => (
            <ul className="text-break">
              <li key={index}>{description}</li>
            </ul>
          ))}
        </Modal.Body>
      </PopUpModal>

      <Modal show={showDeactivateModal} onHide={closeModal} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Confirm {isActive ? "Deactivate" : "Activate"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            {/* <img src={triangle} alt="triangle" />
            <img
              src={dangerline}
              alt="dangerline"
              style={{ position: "absolute", right: "auto", top: "2.2rem" }}
            /> */}
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to {isActive ? "Deactivate" : "Activate"}?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button className="w-100 rounded-3" onClick={closeModal} variant="secondary">
              Cancel
            </Button>
            <Button className="w-100 rounded-3" onClick={() => handleStatus(deleteId, isActive)} variant={`${isActive ? "danger" : "primary"}`}>
              {isActive ? "Deactivate" : "Activate"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <PopUpModal size="lg" title="Description" show={descriptionModal} onHide={() => setDescriptionModal(false)}>
        <Modal.Body>
          {description?.split(",")?.map((description, index) => (
            <ul>
              <li key={index}>{description}</li>
            </ul>
          ))}
        </Modal.Body>
      </PopUpModal>
    </>
  );
};

export default Themes;
