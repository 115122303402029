import FormCard from "../Components/FormCard";
import style from "./../styles/Login.module.css";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Features/authSlice";
import useMarkedFields from "../Hooks/validationHook";
import Loading from "./../Components/Loading/Loading";
import Loader from "../Components/Loader";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [user_email, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [appError, setErrors] = useState("");
  const [bindField, setAllFieldsTouched] = useMarkedFields();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setTimeout(() => setLoading(false), 2000);
    };
    loadData();
    setErrors("");
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, error, message } = useSelector((state) => state.auth);
  useEffect(() => {
    if (error) {
      setErrors(message);
      console.log(error);
    } else {
    }
    if (user) {
      setLoading(false);
      setErrors("");
      navigate("/");
    }
  }, [user, error, message, navigate]);
  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const userData = { user_email, password };

    try {
      const data = await dispatch(login(userData));
      setLoading(false);
    } catch {
      setLoading(false);
    }

    // dispatch(login(userData));
  };

  return (
    <>
      <div className={`d-flex justify-content-center align-items-center flex-column ${style.wrapper}`}>
        <div className="d-flex justify-content-center mt-2 ">
          <h2>Admin Portal</h2>
        </div>
        <FormCard>
          <h4 className="mt-5 mb-4 text-center fw-bold">Welcome Back</h4>
          {appError && <span className="text-danger my-2 text-center">*{appError}</span>}

          <form noValidate onSubmit={onSubmit}>
            <Form.Group className="mb-4" md="4" controlId="validationCustomEmail">
              <Form.Control type="email" name="user_email" placeholder="Enter Email" className="p-2" aria-describedby="inputGroupPrepend" {...bindField("user_email")} required onChange={(e) => setUserEmail(e.target.value)} />
              <span className="invalid-text" type="invalid">
                * Email is Required
              </span>
            </Form.Group>
            <Form.Group className={`mb-4 ${style.passwordInput}`} md="4" controlId="validationCustomPassword">
              <Form.Control className="p-2" type={showPassword ? "text" : "password"} name="password" {...bindField("password")} placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} required />
              <div className={`${style.passwordField}`}>
                <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
              </div>
              <span className="invalid-text" type="invalid">
                * Password is Required
              </span>
            </Form.Group>

            <div className="mb-4">
              <Button className="w-100" onClick={() => setAllFieldsTouched()} type="submit">
                {/* Log In */}
                {loading ? <Loader /> : "login"}
              </Button>
            </div>
          </form>
        </FormCard>
      </div>
    </>
  );
};
export default Login;
