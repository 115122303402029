import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import LoaderPage from "../Loader/loaderPage";
import * as api from "../../api/getapi";
import * as post from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import SearchableSelect from "../SearchableSelect";
const transformLabel = (text) => {
  const temp = text.split("_");
  let ret = "";
  temp.forEach((txt) => {
    ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
  });
  return ret;
};
function FileTransformModal({ show, close, file }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isFormMode, setIsFormMode] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [techbazaarMappingData, setTechbazaarMappingData] = useState({});
  const [mappingData, setMappingData] = useState({});
  const [dropdownData, setDropdownData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const optionalFields = useMemo(() => ["category", "condition_item", "sale_price", "cost_price"], []);
  const user = useSelector((state) => state.auth?.user);
  const transform = useApi(post.transformFile, { hasCatchError: true });
  const sampleDataRequest = useApi(api.fetchSampleDataOfDataRequest, { hasCatchError: true });

  const callTheClose = (pass) => {
    setIsLoading(true);
    setIsFormMode(false);
    close(pass);
  };

  const transformWithoutMapping = async (fileData) => {
    try {
      const { data } = await transform.request(fileData.id, user.user_id);
      if (data) {
        //Timeout and then close with message to display here
        setIsLoading(false);
        setTimeout(() => {
          callTheClose("success");
        }, 5000);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong during tranformation", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      callTheClose();
    }
  };
  useEffect(() => {
    if (file?.file) {
      const fileParts = file.file.split("/");
      const fileName = fileParts.pop();
      const fileNameParts = fileName.split(".")[0].split("_");
      const posType = fileNameParts.pop();
      if (posType === "Other") {
        fetchDataForMapping(file?.id);
      } else {
        transformWithoutMapping(file);
      }
    }
  }, [file]);

  const fetchDataForMapping = async (id) => {
    try {
      const { data } = await sampleDataRequest.request(id);
      setTableData(data.data);
      setTechbazaarMappingData(data.techbazaarMapping);
      setMappingData(() => {
        const temp = {};
        for (let key in data.techbazaarMapping) {
          temp[key] = null;
        }
        return temp;
      });
      if (data?.data[0]) {
        setDropdownData(Object.keys(data.data[0]).map((key) => ({ label: transformLabel(key), value: key })));
      }
      setIsLoading(false);
      setIsFormMode(true);
    } catch (error) {
      toast.error("Error in fetching Mapping data", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      callTheClose();
    }
  };
  const transformWithMapping = async () => {
    try {
      for (const mapKey in mappingData) {
        if (mappingData[mapKey] === null) {
          setErrorMessage("Please map all the keys before procede.");
          return;
        }
      }
      if (mappingData["cost_price"].value === null && mappingData["sale_price"].value === null) {
        setErrorMessage("There must be Sale or Cost Price.");
        return;
      }
      setErrorMessage("");
      const mapResData = {};
      for (const mapKey in mappingData) {
        if (mappingData[mapKey].value !== null) {
          mapResData[mappingData[mapKey].value] = mapKey;
        }
      }

      const { data } = await transform.request(file.id, user.user_id, mapResData);
      if (data) {
        setIsLoading(false);
        setIsFormMode(false);
        setTimeout(() => {
          callTheClose("success");
        }, 5000);
      }
    } catch (error) {
      toast.error(transform?.error?.data?.message, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleDropdownChange = (e, key) => {
    setMappingData((prev) => ({
      ...prev,
      [key]: e,
    }));
  };
  return (
    <Modal size="xl" show={show} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>Transform File</Modal.Header>
      <Modal.Body
        style={{
          minHeight: "300px",
        }}
      >
        {isLoading ? (
          <LoaderPage />
        ) : isFormMode ? (
          <div className="w-100">
            <div>
              <h3>Import File Data Sample</h3>
              <CustomizeTableview data={tableData} actions={false} />
            </div>
            <hr />
            <div>
              <Form>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto",
                    columnGap: "15px",
                    rowGap: "15px",
                  }}
                >
                  {Object.keys(techbazaarMappingData).map((key) => (
                    <>
                      <div>
                        <strong>{transformLabel(key)}</strong>
                      </div>
                      <SearchableSelect name={key} value={mappingData[key]} searchable={false} options={optionalFields.includes(key) ? [{ label: "None", value: null }, ...dropdownData] : dropdownData} handleChange={(e) => handleDropdownChange(e, key)} />
                    </>
                  ))}
                </div>
              </Form>
            </div>
          </div>
        ) : (
          <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-warning" style={{ minHeight: "200px" }}>
            <div>The Data has been transformed. {"(This modal will close in 5 second.)"}</div>
            <Button variant="secondary" onClick={() => callTheClose("success")}>
              CLOSE
            </Button>
          </div>
        )}
      </Modal.Body>
      {isFormMode ? (
        <Modal.Footer>
          {errorMessage && errorMessage.length > 0 ? <div className="text-danger">{errorMessage}</div> : <></>}
          <Button variant="secondary" onClick={() => callTheClose("close")}>
            Close
          </Button>
          <Button variant="primary" onClick={transformWithMapping}>
            Save
          </Button>
        </Modal.Footer>
      ) : (
        <></>
      )}
    </Modal>
  );
}

export default FileTransformModal;
