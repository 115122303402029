import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import style from "./../../styles/ResetPassword.module.css";
import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";
import { toast } from "react-toastify";

import useApi from "../../Hooks/useApi";

import Loader from "../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faRemove } from "@fortawesome/free-solid-svg-icons";
import SearchableSelect from "../../Components/SearchableSelect";
import { useEffect } from "react";
import { isEmptyObject } from "jquery";

// const type = (type) => {
//   if (type === "number") {
//     return { value: "number", label: "Number" };
//   } else if (type === "text") {
//     return { value: "text", label: "String" };
//   } else if (type === "boolean") {
//     return { value: "boolean", label: "Boolean" };
//   }
// };
const AddPage = ({ close, cid, editData, toggle }) => {
  const [IntialValues, setIntialValues] = useState({
    componentName: "",
    // componentUnit: "",
    buttonLabel: "",
    modalInputLabel: "",
    modalInputPlaceholder: "",
    modalInputType: {
      value: "",
      lable: "",
    },
    modalAddButtonLabel: "",
    tabelName: "",
    modalUpdateButtonLabel: "",
  });
  const [componentUnit, setComponentUnit] = useState();
  const [oldFilteers, setOldFilteers] = useState();
  const [labelerror, setLabelError] = useState();
  const [keyerror, setKeyError] = useState();

  const [isFilters, setIsFilters] = useState(false);
  const [filters, setFilters] = useState([
    {
      label: "",
      editable: true,
      key: "",
    },
  ]);
  const handelChange = (e) => {
    const { value, name } = e.target;
    setIntialValues({ ...IntialValues, [name]: value });
  };
  const [isClicked, setIsClicked] = useState(false);
  const addreq = useApi(postapi.PostData);
  const edit = useApi(postapi.EditComponent);

  const [validated, setValidated] = useState(false);

  async function submit(e) {
    const obj = {
      name: IntialValues.componentName,
      unit: componentUnit,
      button_label: IntialValues.buttonLabel,
      modal_input_label: IntialValues.modalInputLabel,
      modal_input_placeholder: IntialValues.modalInputPlaceholder,
      modal_input_type: IntialValues.modalInputType.value,
      modal_add_button_label: IntialValues.modalAddButtonLabel,
      modal_update_button_label: IntialValues.modalUpdateButtonLabel,
      associated_table: IntialValues.tabelName,
      is_filters: isFilters ? true : false,

      filters: filters.some((item) => item.key && item.label)
        ? filters.reduce(
            (obj, filter) => ({
              ...obj,
              [filter.key]: {
                type: "boolean",
                label: filter.label,
                editable: false,
              },
            }),
            {},
          )
        : null,
    };

    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    try {
      if (editData) {
        if (
          Object.values(IntialValues).every((x) => {
            return x;
          }) &&
          (!isFilters || (isFilters && filters.every((item) => item.key && item.label))) &&
          !(labelerror?.length > 0) &&
          !(keyerror?.length > 0)
        ) {
          const data = await edit.request(cid, obj);
          if (data.data.success.message) {
            toast.success("Data Edited successfully ", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            close();
            toggle((prev) => !prev);
          }
        }
      } else {
        if (
          Object.values(IntialValues).every((x) => {
            return x;
          }) &&
          (!isFilters || (isFilters && filters.some((item) => item.key && item.label)))
        ) {
          const data = await addreq.request(obj);

          if (data.data.success.message) {
            toast.success("Data added successfully ", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            close();
            toggle((prev) => !prev);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  const addfilters = () => {
    setFilters([
      ...filters,
      {
        label: "",
        // type: "",
        editable: true,

        key: "",
      },
    ]);
  };
  useEffect(() => {
    if (editData) {
      IntialValues.buttonLabel = editData.button_label;
      IntialValues.componentName = editData.name;
      setComponentUnit(editData.unit);
      IntialValues.tabelName = editData.associated_table;
      IntialValues.modalAddButtonLabel = editData.modal_add_button_label;
      IntialValues.modalInputLabel = editData.modal_input_label;
      IntialValues.modalInputPlaceholder = editData.modal_input_placeholder;
      IntialValues.modalUpdateButtonLabel = editData.modal_update_button_label;
      IntialValues.modalInputType.value = editData.modal_input_type;
      if (editData.is_filters === 1) {
        setIsFilters(true);
        setOldFilteers(Object.entries(editData.filters).reduce((obj, [key, value]) => [...obj, { key: key, type: value.type, label: value.label }], []));
        setFilters(Object.entries(editData.filters).reduce((obj, [key, value]) => [...obj, { key: key, type: value.type, label: value.label }], []));
      }
    }
  }, [editData, isFilters]);

  const removeFilter = (index) => {
    const List = [...filters];
    List.splice(index, 1);
    setFilters(List);
    setLabelError();
    setKeyError();
  };
  const handleFilter = (i, e, name) => {
    let values = [...filters];

    if (name === "type") {
      IntialValues.modalInputType = { value: e.value, label: e.label };
    } else {
      if (e.target.value.length > 0) {
        const labelCheck = oldFilteers?.some((obj) => obj.label.toLowerCase() === e.target.value.toLowerCase());

        const keyCheck = oldFilteers?.some((obj) => obj.key.toLowerCase() === e.target.value.toLowerCase());
        // console.log("------", labelCheck);

        if (labelCheck) {
          setLabelError("Filters label should be different from previous ones");
        } else if (keyCheck) {
          setKeyError("Filters key should be different from previous ones");
        } else {
          setLabelError();
          setKeyError();
        }
      }
      values[i][name] = e.target.value.replace(/\s/g, "_");
    }
    setFilters(values);
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={submit}>
        <Row>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Component Name <span className="text-danger">*</span>
            </label>
            <Form.Control className="p-2" name="componentName" type="text" placeholder="Component name" value={IntialValues.componentName} onChange={handelChange} required />
            <Form.Control.Feedback type="invalid">* Please Enter component Name</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>Component Unit</label>
            <Form.Control
              className="p-2"
              name="componentUnit"
              type="text"
              placeholder="Component unit"
              value={componentUnit}
              onChange={(e) => {
                setComponentUnit(e.target.value);
              }}
            />
            {/* <Form.Control.Feedback type="invalid">
              * Please Enter component Unit
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Button Label <span className="text-danger">*</span>
            </label>
            <Form.Control className="p-2" name="buttonLabel" type="text" placeholder="buttonLabel" value={IntialValues.buttonLabel} onChange={handelChange} required />
            <Form.Control.Feedback type="invalid">* Please Enter Label</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Modal input label <span className="text-danger">*</span>
            </label>
            <Form.Control className="p-2" name="modalInputLabel" type="text" placeholder="modal Label" value={IntialValues.modalInputLabel} onChange={handelChange} required />
            <Form.Control.Feedback type="invalid">* Please Enter input label</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2 " as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Modal Input placeholder <span className="text-danger">*</span>
            </label>
            <Form.Control className="p-2" name="modalInputPlaceholder" type="text" placeholder="input placeholder" value={IntialValues.modalInputPlaceholder} onChange={handelChange} required />
            <Form.Control.Feedback type="invalid">* Please Enter Placeholder</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Modal Input type <span className="text-danger">*</span>
            </label>

            <SearchableSelect
              disabled={editData?.modal_input_type ? true : false}
              selectClassName={"bg-white"}
              placeholder={"Enter type"}
              name={`type`}
              value={
                IntialValues.modalInputType.value
                  ? {
                      value: IntialValues.modalInputType.value,
                      label: IntialValues.modalInputType.value,
                    }
                  : ""
              }
              searchable={false}
              options={[
                { value: "decimal", label: "decimal" },
                { value: "text", label: "String" },
                { value: "number", label: "Number" },
              ]}
              handleChange={(e) => handleFilter(1, e, "type")}
            />
            <Form.Control className="p-2" name="plan" hidden type="text" value={Object.keys(IntialValues.modalInputType)?.length > 0 ? IntialValues.modalInputType?.value : null} onChange={() => {}} required />
            <Form.Control.Feedback type="invalid">* Please Enter input type</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Modal add button label <span className="text-danger">*</span>
            </label>
            <Form.Control className="p-2" name="modalAddButtonLabel" type="text" placeholder="Modal add button label" value={IntialValues.modalAddButtonLabel} onChange={handelChange} required />
            <Form.Control.Feedback type="invalid">* Please Enter modal button label</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Table name <span className="text-danger">*</span>
            </label>
            <Form.Control
              className="p-2"
              name="tabelName"
              type="text"
              onKeyUp={(e) => {
                if (e.target.value.match(/\s/g)) {
                  e.target.value = e.target.value.replace(/\s/g, "_");
                }
              }}
              placeholder="Enter table name"
              value={IntialValues.tabelName}
              onChange={handelChange}
              required
              disabled={editData ? true : false}
            />
            <Form.Control.Feedback type="invalid">* Please Enter table Name</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Modal update button label <span className="text-danger">*</span>
            </label>
            <Form.Control className="p-2" name="modalUpdateButtonLabel" type="text" placeholder="Modal update button label" value={IntialValues.modalUpdateButtonLabel} onChange={handelChange} required />
            <Form.Control.Feedback type="invalid">* Please Enter modal button</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            {" "}
          </Form.Group>
          <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
            <label>
              Is Filters Enabled <span className="text-danger"></span>
            </label>
            <Form.Check
              type="switch"
              id={`default-`}
              name="isFiltersEnabled"
              // value={isFilters}
              disabled={editData?.is_filters === 1 ? true : false}
              checked={isFilters}
              onChange={(e) => {
                setIsFilters((prev) => !prev);
              }}
            />
            <Form.Control.Feedback type="invalid">* Please Enter component Name</Form.Control.Feedback>
          </Form.Group>
        </Row>
        {isFilters && (
          <>
            <div className="d-flex flex-column row">
              <a href="#" type="other" onClick={addfilters} className="btn btn-primary col-1 mb-2  ms-2 ">
                <FontAwesomeIcon icon={faAdd} />{" "}
              </a>
            </div>

            {filters.map((data, index) => (
              <>
                <Row className="align-items-baseline">
                  <Form.Group className="my-2" as={Col} lg="4" md="6" sm="12" controlId="formBasicShopName">
                    <Form.Control className="p-2" name={`${index} label`} type="text" placeholder="label" value={data.label} disabled={data.editable ? false : true} onChange={(e) => handleFilter(index, e, "label")} required />
                    <Form.Control.Feedback type="invalid">* Please Enter component Name</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="my-2" as={Col} lg="3" md="6" sm="12" controlId="formBasicShopName">
                    <Form.Control className="p-2" name={`${index}key`} type="text" disabled={data.editable ? false : true} placeholder="Enter key" value={data.key} onChange={(e) => handleFilter(index, e, "key")} required />

                    <Form.Control.Feedback type="invalid">* Please Enter component Name</Form.Control.Feedback>
                  </Form.Group>
                  {filters.length > 1 ? (
                    <>
                      <Col className="" lg="1">
                        <button disabled={data.editable ? false : true} href="#" type="button" onClick={() => removeFilter(index)} className="btn mb-1 btn-primary">
                          <FontAwesomeIcon icon={faRemove} />{" "}
                        </button>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </>
            ))}
          </>
        )}
        {labelerror && (
          <div class="mt-3 alert alert-danger text-center" role="alert">
            {labelerror}
          </div>
        )}
        {keyerror && (
          <div class="mt-3 alert alert-danger text-center" role="alert">
            {keyerror}
          </div>
        )}
        <div className={`mb-4 mt-4  ${style.fullWidth}`}>
          <Button className="w-100" type="submit">
            {isClicked ? <Loader /> : editData ? "Edit Page" : "Add Page"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddPage;
