import "react-toastify/dist/ReactToastify.css";

import { useEffect } from "react";
const ReasonsModal = ({ reasons }) => {
  useEffect(() => {
    // const reader = new FileReader();
    // reader.readAsDataURL(image.files);
  }, []);
  const re = /\s*(?:,|$)\s*/;
  const splitReasons = reasons?.split(re);
  return (
    <>
      <>
        {splitReasons?.map((data, i) => (
          <>
            <ul>
              <div className="mb-2  text-start">{data}</div>
            </ul>
          </>
        ))}
      </>
    </>
  );
};

export default ReasonsModal;
