import { faCity, faCog, faCreditCard, faDatabase, faExternalLink, faFile, faImages, faMoneyBill, faReceipt, faRegistered, faShippingFast, faShop, faSubscript, faSuitcase, faUniversity, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dashboard from "./../../assets/svg/dashboard.svg";

import customer from "./../../assets/svg/Customer.svg";
import reports from "./../../assets/svg/Reports.svg";
import expense from "./../../assets/svg/expenseIcon.svg";
import bellIcon from "../../assets/svg/bellIcon.svg";
import Addons from "../../assets/images/Addons.png";
const SideNavData = [
  {
    identifier: "Dashboard",
    title: "Dashboard",
    path: "/",
    icon: <img src={dashboard} alt="dashboard" />,
  },
  {
    identifier: "Shopkeeper",
    title: "Shopkeepers ",
    path: "/Shopkeepers",
    icon: <FontAwesomeIcon icon={faShop} />,
    iconClose: <FontAwesomeIcon icon={faShop} />,
    iconOpen: <FontAwesomeIcon icon={faShop} />,
  },
  {
    identifier: "Subscription",
    title: "Subscriptions Page",
    path: "/subscription",
    icon: <img src={expense} alt="Subscriptions" />,
    subNav: [
      {
        subnavigation: true,
        identifier: "Plan",
        title: "Plans",
        path: "/subscription/plans",
        icon: <FontAwesomeIcon icon={faSubscript} />,
      },
      {
        subnavigation: true,
        identifier: "SubscriptionPage",
        title: "Subscriptons ",
        path: "/subscription",
        icon: <FontAwesomeIcon icon={faSuitcase} />,
      },
      {
        subnavigation: true,
        identifier: "Request",
        title: "Requests ",
        path: "/subscription/request-Details",
        icon: <FontAwesomeIcon icon={faReceipt} />,
      },
      {
        subnavigation: true,
        identifier: "Modules",
        title: "Modules ",
        path: "/subscription/modules",
        icon: <FontAwesomeIcon icon={faFile} />,
      },
      {
        subnavigation: true,
        identifier: "Plan Request",
        title: "Plan Requests ",
        path: "/subscription/plan-requests",
        icon: <FontAwesomeIcon icon={faReceipt} />,
      },
    ],
  },
  {
    identifier: "DeliveryOrder",
    title: "Delivery For You",
    path: "/delivery-for-you/orders",
    icon: <FontAwesomeIcon icon={faShippingFast} />,
    subNav: [
      {
        subnavigation: true,
        identifier: "Delivery For You Orders",
        title: "Delivery For You",
        path: "/delivery-for-you/orders",
        icon: <FontAwesomeIcon icon={faShippingFast} />,
      },
      {
        subnavigation: true,
        identifier: "Orders Stats",
        title: "Orders Stats",
        path: "/delivery-for-you/stats",
        icon: <FontAwesomeIcon icon={faSuitcase} />,
      },
    ],
  },
  {
    identifier: "Pricing",
    title: "Pricing",
    path: "/pricing/consumable-items",
    icon: <FontAwesomeIcon icon={faMoneyBill} />,
    subNav: [
      {
        subnavigation: true,
        identifier: "Consumable",
        title: "Consumable Items Pricing",
        path: "/pricing/consumable-items",
        icon: <FontAwesomeIcon icon={faSubscript} />,
      },
      {
        subnavigation: true,
        identifier: "Modules Pricing",
        title: "Modules Pricing",
        path: "/pricing/modules-pricing",
        icon: <FontAwesomeIcon icon={faSuitcase} />,
      },
      {
        subnavigation: true,
        identifier: "Themes Pricing",
        title: "Themes Pricing",
        path: "/pricing/themes-pricing",
        icon: <FontAwesomeIcon icon={faImages} />,
      },
      {
        subnavigation: true,
        identifier: "Delivery Charges",
        title: "Delivery Charges",
        path: "/pricing/delivery-charges",
        icon: <FontAwesomeIcon icon={faMoneyBill} />,
      },
    ],
  },
  {
    identifier: "AddonsRequests",
    title: "Addons Requests",
    path: "/addons-requests",
    icon: <img height="20px" width="20px" src={Addons} alt="Addons" />,
  },
  {
    identifier: "RegistrationRequests",
    title: "Seller Registration Requests",
    path: "/seller-registration",
    icon: <FontAwesomeIcon icon={faRegistered} />,
  },
  {
    identifier: "Themes",
    title: "Themes",
    path: "/theme",
    icon: <FontAwesomeIcon style={{ marginRight: "0 !important" }} icon={faImages} />,
    icon: <FontAwesomeIcon icon={faImages} />,
  },
  {
    identifier: "MarketplaceOptions",
    title: "Marketplace Options",
    path: "/marketplace-options/shop-features",
    icon: <FontAwesomeIcon icon={faCog} />,
    subNav: [
      {
        subnavigation: true,
        identifier: "Shop Features",
        title: "Shop Features",
        path: "/marketplace-options/shop-features",
        icon: <FontAwesomeIcon icon={faShop} />,
      },
      {
        subnavigation: true,
        identifier: "Delivery Cities",
        title: "Delivery Cities",
        path: "/marketplace-options/delivery-cities",
        icon: <FontAwesomeIcon icon={faCity} />,
      },
    ],
  },
  {
    identifier: "Notifications",
    title: "Notifications / SMS",
    path: "/notifications",
    icon: <img src={bellIcon} alt="Notifications" />,
  },
  {
    identifier: "PointSystem",
    title: "Point System",
    path: "/Pointsystem",
    icon: <img src={reports} alt="reports" />,
  },
  {
    identifier: "User",
    title: "Users",
    path: "/user",
    icon: <img src={customer} alt="customer" />,
  },

  {
    identifier: "Payment",
    title: "Payments",
    path: "/Payment",
    icon: <FontAwesomeIcon icon={faCreditCard} />,
  },
  {
    identifier: "DataImport",
    title: "Data Import Requests",
    path: "/ImportRequests",
    icon: <FontAwesomeIcon icon={faUpload} />,
  },
  {
    identifier: "UserDeletionRequests",
    title: "Users Deletion Requests",
    path: "/users-deletion-requests",
    icon: <FontAwesomeIcon icon={faDatabase} />,
  },
  {
    identifier: "ForcedRedirections",
    title: "Forced Redirections",
    path: "/forced-redirections",
    icon: <FontAwesomeIcon icon={faExternalLink} />,
  },
  {
    identifier: "Component",
    title: "Components",
    path: "/data",
    icon: <FontAwesomeIcon icon={faDatabase} />,
  },
  {
    identifier: "Bank",
    title: "Banks",
    path: "/bank",
    icon: <FontAwesomeIcon icon={faUniversity} />,
  },
];

export default SideNavData;
