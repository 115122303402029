import React from 'react'
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as post from "../../api/postapi";
import useApi from '../../Hooks/useApi';

function DisapprovedModal({requestId,onClose,toggle}) {
    const [message, setMessage] = useState()
    const { user } = useSelector((state) => state.auth);
    const disapprove = useApi(post.disapprovedRequest);
 const handleSubmit=async(e)=>{
    e.preventDefault()
    const payload={
        status: "disapproved",
        message: message,
        userId: user.user_id
    }
    try{
        const data = await disapprove.request(requestId,payload);
        if(data){
            toast.success("Request Disapproved Successfully", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            onClose()
            toggle((prev) => !prev);
        }
    }
    catch(error){
        console.log(error.response)

    }

 }
    return (
    <div>  
        <Form onSubmit={handleSubmit} >
         <Form.Group>
        <label>Message</label>
    <Form.Control
      required
      value={message}
      onChange={(e) => {
        setMessage(e.target.value);
      }}
      as="textarea"
      rows={3}
    />
    <Form.Control.Feedback type="invalid">
      Fill Reason.
    </Form.Control.Feedback>
  </Form.Group>
  <div className='mt-2 gap-2 d-flex justify-content-end'>
    <Button onClick={()=>{onClose()}} variant='outline-primary' >Cancel</Button>
    <Button type='submit' >Submit</Button>

  </div>
  </Form>
  </div>


  )
}

export default DisapprovedModal