import React, { useEffect, useState } from "react";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import { Col, Row } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import NoResult from "../../Components/NoResult";
import customloader from "../../assets/images/RollingLoader.gif";
import PopUpModal from "../../Components/Modal";
import RecipientsModal from "../../Components/RecipientsModal";
const AllNotifications = ({ sizeOfPages, searchInput, fromDate, toDate, currentTab }) => {
  const fetchAllNotification = useApi(api.fetchAllNotifications);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, toggle] = useState(false);
  const [recipientsModal, setRecipientsModal] = useState(false);
  const [recipientsList, setRecipientsList] = useState([]);

  const fetchAllNotifications = async () => {
    const res = await fetchAllNotification.request({
      size: sizeOfPages,
      page: currentPage,
      search: searchInput,
      order: orderBy,
      date: { from: fromDate, to: toDate },
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setNotifications(data?.data);
      setCount(data?.pagination?.total);
      if (data?.pagination?.totalPages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.pagination?.totalPages);
        if (currentPage > data?.pagination?.totalPages) {
          setCurrentPage(data?.pagination?.totalPages);
        }
      }
    } else {
      setNotifications([]);
    }
  };
  useEffect(() => {
    if (currentTab === "Notifications") {
      fetchAllNotifications();
    }
  }, [currentPage, sizeOfPages, searchInput, fromDate, toDate, orderBy, refresh, currentTab]);
  useEffect(() => {
    if (currentPage === 1) {
      toggle((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const changePage = (page) => {
    setCurrentPage(page);
  };

  const view = async (id, row) => {
    setRecipientsModal(true);
    setRecipientsList(row);
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !notifications[0] ? (
        <NoResult name="Notifications" />
      ) : (
        <>
          <CustomizeTableview
            data={notifications}
            columnNotShow={["id", "image", "recipient_list", "is_for_shops", "is_for_users"]}
            dateColumn={["created_at"]}
            sortColumn={["created_at", "title", "type"]}
            orderBy={orderBy}
            collapsableColumns={["title", "link", "message"]}
            setOrderBy={setOrderBy}
            view={view}
            formattedColumn={{
              heading: "Notification For",
              style: (row) => {
                const isShop = row["is_for_shops"];
                if (isShop > 0) {
                  return "text-success";
                } else {
                  return "text-primary";
                }
              },
              format: (row) => {
                const isShop = row["is_for_shops"];
                if (isShop > 0) {
                  return "For Shops";
                } else {
                  return "For Users";
                }
              },
            }}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} Result(s)</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result(s)</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
        </>
      )}

      <RecipientsModal
        show={recipientsModal}
        close={() => {
          setRecipientsModal(false);
          setRecipientsList([]);
        }}
        data={recipientsList}
        isLoading={isLoading}
      />
    </>
  );
};

export default AllNotifications;
