import React, { useEffect, useState } from "react";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useRef } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import customloader from "../../assets/images/RollingLoader.gif";
import { type } from "jquery";

const AddonsPaymentModal = ({ onClose, requestId, toggle }) => {
  const [request, setRequest] = useState({});
  const [consumableItems, setConsuableItems] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [paidAmount, setPaidAmount] = useState();
  const [transDate, setTransDate] = useState(new Date().toISOString().split("T")[0]);
  const [transactionId, settransactionId] = useState();
  const [paidCheck, setPaidCheck] = useState(false);
  const [show, setShow] = useState(false);
  const [selectImage, setSelectImag] = useState();
  const [selectImageURL, setSelectImagURL] = useState({
    url: "",
    type: "file",
  });
  const { user } = useSelector((state) => state.auth);
  const [isClicked, setIsClicked] = useState(false);
  const imageUpload = useApi(postapi.UPloadImage);
  const getRequest = useApi(api.getRequestById);

  useEffect(() => {
    const fetchReq = async () => {
      try {
        setIsLoading(true);
        const res = await getRequest.request(requestId);
        setRequest(res.data.data);
        const items = Object.entries(res.data.data).reduce((acc, [key, value]) => {
          if (["emails_limit", "sms_limit", "marketplace_items_limit"].includes(key) && value !== null) {
            const name = key.split("_");
            name.pop();
            acc.push({ name: name.join(" "), ...value });
          }
          return acc;
        }, []);
        setConsuableItems(items);
        setPaymentType(res?.data.data.transaction?.payment_type ?? "");
        setPaidAmount(res?.data.data.transaction?.amount_received ?? 0);
        settransactionId(res?.data.data.transaction?.transaction_id);
        setTransDate(res?.data.data.transaction?.transaction_date.split("T")[0] ?? new Date().toISOString().split("T")[0]);
        setSelectImagURL(res?.data.data.transaction?.transaction_image_url ? { url: res?.data.data.transaction?.transaction_image_url, type: "url" } : { url: "", type: "file" });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw new Error(error);
      }
    };
    fetchReq();
  }, [requestId]);
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (paymentType === "cash") {
      setTransDate();
      setSelectImag();
      setSelectImagURL();
      settransactionId();
    }
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    if (request.shop_name && (request.is_paid === 1 ? paidAmount && paymentType && (paymentType === "transfer" ? transactionId && transDate && selectImageURL : true) : true)) {
      if (+paidAmount !== +request.amount_payable) {
        setPaidCheck(true);
      } else {
        try {
          let imgData;
          setValidated(true);
          if (selectImage) {
            imgData = await imageUpload.request(selectImage);
          }

          const [date, time] = new Date().toISOString().split("T");
          const curDate = date.split("-");
          const curTime = time.split(".")[0].replaceAll(":", "");
          const dateB32 = Number(curDate[2] + curDate[1] + curDate[0])
            .toString(32)
            .toUpperCase();
          const timeB32 = Number(curTime).toString(32).toUpperCase();
          const dateNow = new Date().toISOString().split("T")[0];
          const timeNow = new Date().toLocaleTimeString().split(" ")[0];
          const currentDate = dateNow + " " + timeNow;
          if (request.is_paid === 1 && !paidAmount) {
            setIsClicked(false);
          } else {
            setIsClicked(true);
            let output = {
              marketplace_items_limit: null,
              emails_limit: null,
              sms_limit: null,
            };
            consumableItems?.forEach((item) => {
              let formattedName = item.name.replace(/\s+/g, "_") + "_limit";
              output[formattedName] = item.current_quantity;
            });
            let obj = {};
            if (request.is_paid === 1) {
              if (request.transaction === null) {
                obj = {
                  transaction: {
                    amount_received: paidAmount,
                    transaction_image_url: imgData ? imgData.data.files[0] : null,
                    transaction_date: transDate ? transDate : currentDate,
                    transaction_id: transactionId ? transactionId : requestId + user.user_id + dateB32 + timeB32,
                    payment_type: paymentType,
                  },
                };
              }
            }
            const res = await postapi.approveAddonsRequest(requestId, obj);
            if (res) {
              toast.success("Request Approved Successfully", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setIsClicked(false);
              setValidated(false);
              onClose();
              toggle((prev) => !prev);
            }
          }
        } catch (error) {}
      }
    }
  };

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0], e.target.files[0].name);
    setSelectImag(formData);
    setSelectImagURL({ url: URL.createObjectURL(e.target.files[0]), type: "file" });
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <Form noValidate validated={validated} onSubmit={handlePaymentSubmit}>
          <Row>
            <>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                <label>
                  Shop Name<span className="text-danger">*</span>
                </label>
                <Form.Control className="p-2 text-capitalize" name="province" placeholder="Plan" type="text" value={request?.shop_name} onChange={() => {}} required disabled />
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Amount Payable <span className="text-danger">*</span>
                </label>
                <Form.Control value={Math.round(request?.amount_payable)} type="number" placeholder="Rs.1000" disabled />
              </Form.Group>

              {consumableItems?.length && (
                <Table bordered striped>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Requested Quantity</th>
                      <th>New Quantity</th>
                      <th>Price of Item</th>
                    </tr>
                  </thead>
                  <tbody>
                    {consumableItems?.map((item) => (
                      <tr>
                        <td className="text-capitalize">{item?.name}</td>
                        <td>{item?.requested_quantity}</td>
                        <td>{item?.current_quantity}</td>
                        <td>{item?.whole_price}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {request?.is_paid !== 0 ? (
                <>
                  {" "}
                  <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>Payment Type</label>
                    <Form.Select
                      onChange={(e) => {
                        setPaymentType(e.target.value);
                      }}
                      value={paymentType}
                      required
                      disabled={request.is_paid === 1 && request?.transaction !== null ? true : false}
                    >
                      <option value="" selected disabled>
                        Select Payment Type
                      </option>
                      <option value="cash">Cash</option>
                      <option value="transfer">Transfer</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Please Select Payment Type</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="price">
                    <label>
                      Paid Amount <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      value={paidAmount ? paidAmount : ""}
                      placeholder="Rs.1000"
                      required
                      onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                      min={Number(request.amount_payable)}
                      max={Number(request.amount_payable)}
                      onChange={(e) => {
                        if (Number(e.target.value) > 0) {
                          setPaidAmount(e.target.value);
                          if (Number(request.amount_payable) === Number(e.target.value)) {
                            setPaidCheck(false);
                          } else {
                            setPaidCheck(true);
                          }
                        } else {
                          setPaidAmount(0);
                        }
                      }}
                      disabled={request.is_paid === 1 && request?.transaction !== null ? true : false}
                    />

                    <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                  </Form.Group>
                  {paymentType === "transfer" && (
                    <>
                      <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                        <label>
                          Transaction Id <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          value={transactionId}
                          name="contact_person_name"
                          onChange={(e) => {
                            settransactionId(e.target.value);
                          }}
                          placeholder="eg.AA11"
                          required={paymentType === "transfer" ? true : false}
                          disabled={request.is_paid === 1 && request?.transaction !== null ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">Please Enter Transaction Id.</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                        <label>
                          Transaction Date<span className="text-danger">*</span>
                        </label>
                        <Form.Control value={transDate} name="trans_date" disabled={true} required={paymentType === "transfer" ? true : false} type="date" />

                        <Form.Control.Feedback type="invalid">Please select Date.</Form.Control.Feedback>
                      </Form.Group>

                      {selectImageURL.type !== "url" ? (
                        <Form.Group className="mt-1" as={Col} lg="12" md="12" sm="12" controlId="formBasicShopName">
                          <label className="mb-1">
                            Upload Image<span className="text-danger">*</span>
                          </label>
                          <Form.Control type="file" size="sm" accept="image/*" name="contact_person_name" onChange={(e) => handleImageUpload(e)} required={paymentType === "transfer" ? true : false} />
                          <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>
                          <div className="mt-2 d-flex align-items-center justify-content-center w-100 ">
                            <div style={{ width: "150px" }}>
                              <img src={selectImageURL.url} alt="" width="100%" height="auto" />
                            </div>
                          </div>
                        </Form.Group>
                      ) : (
                        <div className="mt-2 d-flex align-items-center justify-content-center w-100 ">
                          <div style={{ width: "150px" }}>
                            <img src={process.env.REACT_APP_URL_S3 + "/" + selectImageURL.url} alt="" width="100%" height="auto" />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}

              <Button className="w-100 mt-3" type="submit">
                Approve
              </Button>
              {paidCheck && (
                <div class="alert alert-danger  text-center w-100 mt-2" role="alert">
                  Paid amount must be equal to payable
                </div>
              )}
            </>
          </Row>
        </Form>
      )}
    </>
  );
};

export default AddonsPaymentModal;
