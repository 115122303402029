import { useState } from "react";
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import { useEffect } from "react";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { toast } from "react-toastify";
import ReactSelect from "./../../Components/ReactSelect";

const AddonsRequestModal = ({ toggle, close }) => {
  const [addons, setAddons] = useState({
    marketplace_items_limit: {
      quantity: "",
      whole_price: 0,
    },
    emails_limit: {
      quantity: "",
      whole_price: 0,
    },
    sms_limit: {
      quantity: "",
      whole_price: 0,
    },
  });
  const [isFree, setIsFree] = useState(false);
  const [validatedVendor, setValidatedVendor] = useState(false);
  const dropdownShops = useApi(api.getDropdownShops);
  const [shop, setShop] = useState(null);
  const [error, setError] = useState("");
  const [item, setItem] = useState("");
  const [shopConsumable, setShopConsumable] = useState({});

  const fetchAllConsumableItemsCall = useApi(api.fetchAllConsumableItems);
  const addonsRequests = useApi(postapi.addonsRequest, { hasCatchError: true });
  const fetchShopConsumableItems = useApi(api.getShopConsumableItems, { hasCatchError: true });

  const fetchConsumableItems = async () => {
    try {
      const res = await fetchAllConsumableItemsCall.request();
      const data = res?.data?.data;
      if (data) {
        const convertedObject = data?.consumableItems?.reduce((acc, item) => {
          acc[item.name] = +item.per_unit_price;
          return acc;
        }, {});
        setItem(convertedObject);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchConsumableItems();
  }, []);

  // Get Shops
  const getShops = async (searchInput) => {
    return new Promise((resolve, reject) => {
      dropdownShops
        .request(searchInput)
        .then((res) => {
          // const result = res.data.filter((cat) => cat.shop_name.concat("-", cat.shop_name).toLowerCase().includes(filterInput.toLowerCase()));
          const getData = res?.data?.map((cat) => ({
            value: cat,
            label: (
              <>
                <strong className="fs-12" name={cat.shop_name}>
                  {cat.id}-{cat.shop_name}
                </strong>
                <p className="fs-10 text-lowercase">
                  {cat.whatsapp} <br />
                  {cat.username}
                </p>
              </>
            ),
            name: cat.shop_name + " [" + cat.id + "]",
          }));
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };

  const handleRequestSubmit = async (e) => {
    e.preventDefault();

    if (shop && validateLimits(addons)) {
      try {
        const requestedItems = [];
        for (const [key, value] of Object.entries(addons)) {
          if (value?.quantity !== "") requestedItems.push({ name: key, quantity: value.quantity, whole_price: value.whole_price });
        }
        let finalObject = {
          shop_id: shop?.value?.id,
          requested_items: requestedItems,
          is_paid: !isFree,
        };
        const res = await addonsRequests.request(finalObject);
        if (res) {
          toast.success("Request Added Successfully", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          toggle((prev) => !prev);
          close();
        }
        if (addonsRequests.error) {
          setError(addonsRequests.error.data.error.message);
        }
      } catch (err) {
        setError(err.response.data.error.message);
      }
    }
  };

  function validateLimits(limits) {
    return Object.values(limits).some((limit) => limit.quantity !== "");
  }

  const handleChangeCount = (e) => {
    if (Number(e.target.value) < 0) {
      setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: "", whole_price: 0 } }));
    } else {
      if (e.target.value === "" || e.target.value === "0") {
        setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: "", whole_price: 0 } }));
      } else {
        if (isFree) {
          setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: e.target.value, whole_price: 0 } }));
        } else {
          setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: e.target.value, whole_price: Number(item[e.target.name] * e.target.value).toFixed(2) } }));
        }
      }
    }
  };
  const handleChangeIsFree = (e) => {
    setIsFree(e.target.checked);
    setAddons((prev) => {
      const temp = {};
      for (let key in prev) {
        if (e.target.checked) {
          temp[key] = { quantity: prev[key].quantity, whole_price: 0 };
        } else {
          temp[key] = { quantity: prev[key].quantity, whole_price: Number(item[key] * prev[key].quantity).toFixed(2) };
        }
      }
      return temp;
    });
  };

  const fetchShopData = async (id) => {
    try {
      const res = await fetchShopConsumableItems.request(id);
      setShopConsumable(res.data?.data?.current);
    } catch (error) {
      console.error(error);
    }
  };
  const handleShopChange = (e) => {
    setShop({
      value: e.value,
      label: e.name,
    });
    fetchShopData(e.value.id);
  };
  return (
    <>
      <Form noValidate validated={validatedVendor} onSubmit={handleRequestSubmit}>
        {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

        <Row className="">
          <Col md={12} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>
                Shop<span className="text-danger">*</span>
              </label>
              <ReactSelect
                placeholder="Select shop"
                value={shop}
                searchable={true}
                options={getShops}
                handleChange={handleShopChange}
                onInputChange={(newInput) => {
                  return newInput;
                }}
              />
              <Form.Control className="p-2" name="shop" hidden type="text" value={shop ? shop?.value : null} onChange={() => {}} required />
              <Form.Control.Feedback type="invalid">* Please Select Shop</Form.Control.Feedback>
            </Form.Group>
          </Col>

          {shop && shopConsumable ? (
            <Table bordered striped>
              <thead>
                <tr>
                  <th>Current Emails Limit</th>
                  <th>Current Marketplace ItemsLimit</th>
                  <th>Current SMS Limit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-capitalize">{shopConsumable?.emails_limit}</td>
                  <td>{shopConsumable?.marketplace_items_limit}</td>
                  <td>{shopConsumable?.sms_limit}</td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <></>
          )}
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Marketplace Listings</label>
              <Form.Control
                value={addons.marketplace_items_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="marketplace_items_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                min={1}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Marketplace Litings Amount</label>
              <Form.Control value={addons.marketplace_items_limit.whole_price} name="marketplace_items_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Emails Limit</label>
              <Form.Control
                value={addons.emails_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="emails_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Emails Amount</label>
              <Form.Control value={addons.emails_limit.whole_price} name="emails_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col className="py-2" md={6}>
            <Form.Group className="mb-3" controlId="price">
              <label>SMS Limits</label>
              <Form.Control
                value={addons.sms_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="sms_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>SMS Amount</label>
              <Form.Control value={addons.sms_limit.whole_price} name="sms_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Form.Group className="d-flex gap-3">
              <Form.Check value={isFree} onChange={handleChangeIsFree} />
              <Form.Label>As Gift</Form.Label>
            </Form.Group>
          </Col>
          <div className="d-flex ">
            <strong>Amount Payable: </strong>
            <p className="ms-1">
              {" "}
              {Math.floor(Number(addons.marketplace_items_limit.whole_price) + Number(addons.emails_limit.whole_price) + Number(addons.sms_limit.whole_price)) > 0
                ? Math.floor(Number(addons.marketplace_items_limit.whole_price) + Number(addons.emails_limit.whole_price) + Number(addons.sms_limit.whole_price))
                : 0}
            </p>
          </div>
        </Row>
        <Row className="py-3">
          <Col>
            <Button className="w-100" onClick={handleRequestSubmit} disabled={!(shop && validateLimits(addons))}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddonsRequestModal;
