import { Button, Modal } from "react-bootstrap";
import styleModal from "./../styles/Modal.module.css";

const PopUpModal = (props) => {
  return (
    <Modal {...props} size={props.size ? props.size : "md"} aria-labelledby="contained-modal-title-vcenter" centered className={`border-0`} backdrop="static">
      <div className={`${props.type}`}>
        <Modal.Header className="border-0" {...(props.closeButton === false ? {} : { closeButton: true })}>
          <Modal.Title id="contained-modal-title-vcenter fs-5 fw-2">{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3 px-5">
          <div className="text-center">
            <div className={`${styleModal.modalIcon} ${props.iconModal}`}>{props.icon}</div>
            <h6>{props.text}</h6>
          </div>
          <div className="">{props.children}</div>
        </Modal.Body>
        <div>
          {props.footer ? (
            <Modal.Footer className="border-0">
              <Button variant="outline-secondary" onClick={props.onHide}>
                Close
              </Button>
              <Button onClick={props.onHide}>Delete</Button>
            </Modal.Footer>
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PopUpModal;
