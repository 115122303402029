import { useState } from "react";
import { useLocation } from "react-router-dom";
import style from "./../../styles/Sidebar.module.css";
import MenuItem from "./MenuItem";

const Menu = ({ setIsSandwichOpen, isSandwichOpen, navData }) => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const [activeParent, setActiveParent] = useState(""); // we need to set initaial state .. we do it later after observing behavior
  const [activeCaret, setActiveCaret] = useState("");
  return (
    <>
      {navData.map((item, index) => {
        return (
          <MenuItem
            subnavigation={item.subnavigation}
            setIsSandwichOpen={setIsSandwichOpen}
            item={item}
            key={index}
            isSandwichOpen={isSandwichOpen}
            active={active}
            setActive={setActive}
            activeParent={activeParent}
            setActiveParent={setActiveParent}
            activeCaret={activeCaret}
            setActiveCaret={setActiveCaret}
          />
        );
      })}
    </>
  );
};

export default Menu;
