import { useState } from "react";
import { Container, Row, Modal, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchInput from "../Components/SearchInput";
import SearchableSelect from "../Components/SearchableSelect";
import Banks from "../Components/Banks/Banks";
import PopUpModal from "../Components/Modal";
import BankForm from "../Components/Banks/BankForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const BanksPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [refresh, toggle] = useState(false);

  // shops on  payment

  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({ full_name: "ASC" });
  // INCOMPLETE ------------------------------------

  //Add modal
  const [modalShow, setModalShow] = useState(false);

  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const resetFilterHandle = () => {
    setSearchInput("");
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  return (
    <>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>

          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs="2" lg="2">
            <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={() => setModalShow(true)}>
              <span className="d-none d-lg-inline">Add Bank</span> <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Banks search={searchInput} orderBy={orderBy} setOrderBy={setOrderBy} sizeOfPage={sizeOfPages} refresh={refresh} toggle={toggle} />
            </Col>
          </Row>
        </>
      </Container>
      <PopUpModal title="Add Bank" show={modalShow} onHide={() => setModalShow(false)}>
        <BankForm close={() => setModalShow(false)} toggle={toggle} />
      </PopUpModal>
    </>
  );
};
export default BanksPage;
