import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import { toast } from "react-toastify";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import PopUpModal from "../../Components/Modal";
import EditThemePricing from "../../Components/EditThemePricing/EditThemePricing";
import SearchInput from "../../Components/SearchInput";

const ThemesPricing = () => {
  let [themes, setThemes] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [editThemesPriceModalShow, setEditThemePriceModalShow] = useState(false);
  const [themesData, setThemesData] = useState({});

  const fetchAllThemesCall = useApi(api.fetchAllThemes);
  const resetFilterHandle = () => {
    setSizeOfPages(10);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const fetchThemesData = async () => {
    const res = await fetchAllThemesCall.request({
      size: sizeOfPages,
      page: currentPage,
      search: searchInput,
      orderBy: orderBy,
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setThemes(
        data?.data?.map((m) => ({
          id: m.id,
          theme_name: m.theme_name,
          price: m.price,
          description: m.description,
          Number_of_sections: m.num_sections,
          maximum_products_per_section: m.max_products_per_section,
          primary_banner_limit: m.primaryBannerLimit,
          secondary_banner_limit: m.secondaryBannerLimit,
          created_at: m.created_at,
          updated_at: m.updated_at,
        }))
      );
      setCount(data?.count?.no_of_items);
      if (data?.count?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.count?.no_of_pages);
        if (currentPage > data?.count?.no_of_pages) {
          setCurrentPage(data?.count?.no_of_pages);
        }
      }
    } else {
      setThemes([]);
    }
  };
  useEffect(() => {
    fetchThemesData();
  }, [currentPage, searchInput, sizeOfPages, toggle, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const edit = (data) => {
    setEditThemePriceModalShow(true);
    setThemesData(data);
  };
  const closeModalEdit = () => {
    setEditThemePriceModalShow(false);
  };
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : themes.length === 0 ? (
            <NoResult />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview data={themes} columnNotShow={["id"]} dateColumn={["created_at", "updated_at"]} sortColumn={["created_at", "updated_at", "theme_name"]} orderBy={orderBy} setOrderBy={setOrderBy} edit={edit} collapsableColumns={["description"]} />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
          <PopUpModal title="Update Price" show={editThemesPriceModalShow} onHide={() => setEditThemePriceModalShow(false)}>
            <EditThemePricing toggle={refresh} close={closeModalEdit} data={themesData} />
          </PopUpModal>
        </>
      </Container>
    </div>
  );
};

export default ThemesPricing;
