import React, { useState, useEffect } from "react";
import { Row, Modal, Col } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";

import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";

import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import { toast } from "react-toastify";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PopUpModal from "../Modal";

import AddNotes from "./AddNotes";

const DELAY_TIME = 100;

function Requests({ userType, handleby, search, status, setOrderBy, sizeOfPage, subscription, orderBy, refresh, toggle, statusType, priority, dates }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [action, setAction] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [noteId, setNoteId] = useState();

  const getPaginationLength = useApi(api.getRequestNoOfPages);
  const getRequ = useApi(api.getRequest);
  const [showNotesModal, setShowNotesModal] = useState(false);

  const assignme = useApi(postapi.AssignMee);
  const fetchpaginationCount = async () => {
    try {
      const data = await getPaginationLength.request({
        search: search,
        size: sizeOfPage,
        priority: priority,
        user_id: handleby,
        status: statusType,
        orderBy: orderBy,
        dates: dates,
      });
      setCount(data.data.no_of_items);
      if (data.data.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data.data.no_of_pages);
        if (currentPage > data.data.no_of_pages) {
          setCurrentPage(data.data.no_of_pages);
        }
      }
    } catch {}
  };
  useEffect(() => {
    fetchpaginationCount();
    const pageTimeout = setTimeout(async () => {
      const res = await getRequ.request({
        search: search,
        status: statusType,
        // orderBy: orderBy,
        page: currentPage,
        size: sizeOfPage,
        priority: priority,
        user_id: handleby,
        dates: dates,
      });

      const data = res.data;
      setIsLoading(false);
      setItems(data);
      if (user.permissionArray.includes("User")) {
        setAction(true);
      } else {
        setAction(false);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [userType, statusType, search, orderBy, currentPage, refresh, sizeOfPage, status, priority, dates]);
  const changePage = (page) => {
    setCurrentPage(page);
  };

  const closeModal = () => {
    setShowNotesModal(false);
  };
  const addNotes = (data) => {
    setNoteId(data["id"]);
    setShowNotesModal(true);
  };
  const assignMe = async (data) => {
    let id = data["id"];
    try {
      await assignme.request(id, user.user_id);
      toast.success("Request successfully assigned", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      toggle((prev) => !prev);
    } catch (error) {}
  };

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult name="" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            columnNotShow={["id", "username", "is_active", "plan_id", "request_description"]}
            dateColumn={["trial_till", "request_date_and_time"]}
            sortColumn={[""]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            notes={addNotes}
            assign={assignMe}
            requestDescription={true}

            // remove={action ? deleteuser : false}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
          <PopUpModal size="md" title="" show={showNotesModal} onHide={() => setShowNotesModal(false)}>
            <AddNotes close={() => setShowNotesModal(false)} id={noteId} toggle={toggle} />
          </PopUpModal>
        </>
      )}
    </div>
  );
}

export default Requests;
