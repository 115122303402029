import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import { toast } from "react-toastify";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import PopUpModal from "../../Components/Modal";
import EditConsumable from "../../Components/ConsumableItems/EditConsumable";
const ConsumableItems = () => {
  let [consumableItems, setConsumableItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "ASC" });
  const [editConsumableItemsModalShow, setEditConsumableItemsModalShow] = useState(false);
  const [consumableItemData, setConsumableItemData] = useState({});

  const fetchAllConsumableItemsCall = useApi(api.fetchAllConsumableItems);
  const resetFilterHandle = () => {
    setSizeOfPages(10);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const fetchConsumableItems = async () => {
    const res = await fetchAllConsumableItemsCall.request({
      size: sizeOfPages,
      page: currentPage,
      orderBy: orderBy,
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setConsumableItems(data?.data?.consumableItems);
      setCount(data?.data?.pagination?.no_of_items);
      if (data?.data?.pagination?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.data?.pagination?.no_of_pages);
        if (currentPage > data?.data?.pagination?.no_of_pages) {
          setCurrentPage(data?.data?.pagination?.no_of_pages);
        }
      }
    } else {
      setConsumableItems([]);
    }
  };
  useEffect(() => {
    fetchConsumableItems();
  }, [currentPage, refresh, sizeOfPages, toggle, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const edit = (data) => {
    setEditConsumableItemsModalShow(true);
    setConsumableItemData(data);
    console.log(data);
  };
  const closeModalEdit = () => {
    setEditConsumableItemsModalShow(false);
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : !consumableItems ? (
            <NoResult />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview data={consumableItems} columnNotShow={["id"]} dateColumn={["created_at", "updated_at"]} sortColumn={["created_at", "updated_at", "name"]} orderBy={orderBy} setOrderBy={setOrderBy} edit={edit} />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
          <PopUpModal title="Update Price" show={editConsumableItemsModalShow} onHide={() => setEditConsumableItemsModalShow(false)}>
            <EditConsumable toggle={refresh} close={closeModalEdit} data={consumableItemData} />
          </PopUpModal>
        </>
      </Container>
    </div>
  );
};

export default ConsumableItems;
