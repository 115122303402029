import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import useMarkedFields from "../../Hooks/validationHook";

import style from "./../../styles/ResetPassword.module.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as postapi from "../../api/postapi";
import * as api from "../../api/getapi";
import { toast } from "react-toastify";

import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
const EditPassword = ({ id, data, close, toggle }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [bindField, setAllFieldsTouched] = useMarkedFields();
  const [confirmError, setConfirmError] = useState(false);
  const [error, setError] = useState("");
  const changePass = useApi(postapi.changePassword, { hasCatchError: true });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      try {
        await changePass.request(id, password);
        toast.success("Password Change Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        toggle((prev) => !prev);
        close();
      } catch (error) {
        console.log(error);
        setError(error?.response?.data?.error?.message);
      }
    } else {
      setConfirmError(true);
    }
  };
  const handleConfirmPass1 = (e) => {
    setPassword(e.target.value);
    if (e.target.value !== confirmPassword) {
      setConfirmError(true);
    } else {
      setConfirmError(false);
    }
  };
  const handleConfirmPass2 = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setConfirmError(true);
    } else {
      setConfirmError(false);
    }
  };

  return (
    <>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        {error && <p className="text-danger p-2 text-center my-3 rounded-1">*{error}</p>}
        {/* {success && <p className="text-success p-2 text-center my-3 rounded-1">*{success}</p>} */}

        <Form.Group className={`mb-4 ${style.passwordInput}`}>
          <Form.Label>Password {<span style={{ color: "red" }}>*</span>}</Form.Label>
          <Form.Control className="p-2" name="pass" {...bindField("pass")} type={showPassword ? "text" : "password"} placeholder="******" required value={password} onChange={handleConfirmPass1} />
          <div className={`${style.passwordField}`}>
            <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
          </div>
          <span className="invalid-text" type="invalid">
            * Please enter password
          </span>
          <Form.Label className="mt-2">Confirm Password {<span style={{ color: "red" }}>*</span>}</Form.Label>
          <Form.Control className="p-2" name="pass2" {...bindField("pass2")} type={showCPassword ? "text" : "password"} placeholder="******" required value={confirmPassword} onChange={handleConfirmPass2} />
          <div className={`${style.passwordField}`}>
            <FontAwesomeIcon className="text-secondary" icon={showCPassword ? faEye : faEyeSlash} onClick={() => setShowCPassword((a) => !a)} />
          </div>
          <span className="invalid-text" type="invalid">
            * Please enter confirm password
          </span>
          {confirmError && <span style={{ color: "tomato" }}>password should match</span>}
        </Form.Group>

        <Button
          type="submit"
          className="w-100"
          onClick={() => {
            setAllFieldsTouched();
          }}
        >
          Edit
        </Button>
      </Form>
    </>
  );
};

export default EditPassword;
