import React, { useState } from "react";
import ReactSelect from "../ReactSelect";
import SearchableSelect from "../SearchableSelect";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { Button, Form } from "react-bootstrap";
function SelectForTable({ value, id, toggle, close }) {
  const SalesManCall = useApi(api.getSalesman);
  const [salesman, setSalesman] = useState({ value: value, label: value });
  const [filterInput, setFilterInput] = useState("".replace(/\W/g, ""));
  const changemoderator = useApi(postapi.ChangeModerator);
  const handleChange = async () => {
    close();
    try {
      await changemoderator.request(id, salesman.value);
      toggle((pre) => !pre);
    } catch {}
  };
  const getSalesman = async (newInput) => {
    const inputValue = newInput.replace(/\W/g, "");
    setFilterInput(inputValue);
    return new Promise((resolve, reject) => {
      SalesManCall.request().then((res) => {
        const result = res.data.filter((cat) =>
          cat.user_full_name
            .concat("-", cat.user_email)
            .toLowerCase()
            .includes(filterInput.toLowerCase())
        );
        const getData = result.map((cat) => ({
          value: cat.user_id,
          label: cat.user_full_name,
          name: cat.user_full_name,
        }));
        resolve(getData);
      });
    });
  };
  return (
    <>
      <label>Change Moderator</label>
      <div className="d-flex flex-column justify-content-center align-items-center">
        {" "}
        <ReactSelect
          placeholder="Filter Salesman"
          value={salesman}
          searchable={true}
          options={getSalesman}
          handleChange={(e) => {
            setSalesman({
              value: e.value,
              label: e.label,
            });
          }}
          // onInputChange={handleChange}
          menuPortalTarget={document.body}
        />
        <Button onClick={handleChange} className="mt-3 w-50">
          Update
        </Button>
      </div>
    </>
  );
}

export default SelectForTable;
