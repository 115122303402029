import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import * as postapi from "../../api/postapi";
import "react-toastify/dist/ReactToastify.css";
import useApi from "../../Hooks/useApi";
import { toast } from "react-toastify";
import { useEffect } from "react";

const EditThemePricing = ({ data, toggle, close }) => {
  const [name, setName] = useState(data?.theme_name);
  const [perUnitPrice, SetPerUnitPrice] = useState(data?.price);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const editItem = useApi(postapi.editThemePrice);

  useEffect(() => {
    console.log(data);
    setName(data?.Theme_name);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    try {
      if (name.length > 0 && data?.id && Number(perUnitPrice) > 0) {
        const response = await editItem.request(data?.id, perUnitPrice);
        if (response?.data?.status === "success") {
          toggle((prev) => !prev);
          toast.success("Price Updated Successfully", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          close();
        }
      }
    } catch (error) {
      setError(error?.response?.data.error.message);
    }
  };
  return (
    <>
      <Form noValidate validated={validated} autoComplete="nope" onSubmit={handleSubmit}>
        {error && <p className="text-danger p-2 text-center my-3 rounded-1">*{error}</p>}
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Name <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control className="p-2" type="text" name="Name" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} required disabled={true} />
          <Form.Control.Feedback type="invalid">Please Add Item's Name.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Per Unit Price <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control className="p-2" type="number" name="PerUnitPrice" placeholder="e.g. 2" value={perUnitPrice} onChange={(e) => SetPerUnitPrice(e.target.value)} required />
          <Form.Control.Feedback type="invalid">Please Add Item's Per Unit Price.</Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" className="w-100">
          Save
        </Button>
      </Form>
    </>
  );
};

export default EditThemePricing;
