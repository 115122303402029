import React from "react";
import { useState } from "react";
import { Form, Row, Col, Button, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect } from "react";
import customloader from "../assets/images/RollingLoader.gif";
import PaginationBar from "./PaginationBar";
import CustomizeTableview from "./TableViews/CustomizeTableview";
import axios from "axios";
import SearchInput from "./SearchInput";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchableSelect from "./SearchableSelect";
const ShopsListModal = ({ close, selectedRows, setSelectedRows, mode, state }) => {
  const [search, setSearch] = useState("");
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [shops, setShops] = useState([]);
  const [totalShops, setTotalShops] = useState(0);
  const [status, setStatus] = useState({ value: "", label: "All" });
  const [orderBy, setOrderBy] = useState({ registrationDate: "DESC" });
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const orderByFormatForQueryString = (obj) => {
    const [[key, value]] = Object.entries(obj);
    return `${key}:${value}`;
  };
  const getShops = async () => {
    try {
      let responseData;
      if (mode === "isForShops") {
        const orderByString = orderBy ? orderByFormatForQueryString(orderBy) : "";
        const queryData = {
          ...(search.length > 0 ? { search: search } : {}),
          ...(status.value !== "" ? { status: status.value } : {}),
          ...(orderBy ? { order: orderByString } : {}),
          page: currentPage,
          size: sizeOfPages,
        };
        const searchParams = new URLSearchParams(queryData);
        setIsLoading(true);
        responseData = await axios.get(
          process.env.REACT_APP_API_URL +
            `/shops/fetch-shops/?${searchParams.toString()}
  `,
          { withCredentials: true },
        );
      } else {
        const orderByString = orderBy ? orderByFormatForQueryString(orderBy) : "";
        const queryData = {
          ...(search.length > 0 ? { search: search } : {}),
          ...(status.value !== "" ? { status: status.value } : {}),
          ...(orderBy ? { order: orderByString } : {}),
          page: currentPage,
          size: sizeOfPages,
          ...(state?.type === "sms" ? {} : { "for-notification": true }),
        };
        const searchParams = new URLSearchParams(queryData);
        setIsLoading(true);
        responseData = await axios.get(process.env.REACT_APP_API_URL + `/user/get-all-seller-users?${searchParams.toString()}`, { withCredentials: true });
      }
      if (responseData) {
        setIsLoading(false);
        setShops(responseData?.data?.data);
        setNoOfPages(responseData?.data?.pagination?.totalPages);
        setTotalShops(responseData?.data?.pagination?.total);
      }
    } catch (error) {
      setIsLoading(false);
      setShops([]);
      setNoOfPages(1);
      setTotalShops(0);
    }
  };
  useEffect(() => {
    getShops();
  }, [currentPage, search, sizeOfPages, noOfPages, status, orderBy]);
  const statusChange = (e) => {
    if (e.value) {
      setStatus({ value: e.value, label: e.label });
      setCurrentPage(1);
    } else {
      setStatus({ value: "", label: "All" });
    }
  };

  return (
    <>
      <Row className="">
        <Col
          xs="4"
          lg="4"
          // className="d-none d-lg-inline"
        >
          <SearchInput searchlogo handleChange={handleChange} value={search} />
        </Col>
        <Col
          xs="4"
          lg="4"
          // className={`d-none d-lg-inline mb-3`}
        >
          <SearchableSelect
            placeholder="Filter Shops"
            searchable={false}
            value={status}
            options={
              mode === "isForUsers"
                ? [
                    { value: "", label: "All" },
                    { value: "trial", label: "On Trial" },
                    { value: "payment", label: "On Payment" },
                  ]
                : [
                    { value: "", label: "All" },
                    { value: "active", label: "Active" },
                    { value: "inActive", label: "Inactive" },
                    { value: "trial", label: "On Trial" },
                    { value: "payment", label: "On Payment" },
                    { value: "awaitingActivation", label: "Awaiting Activation" },
                    { value: "deactivated", label: "Deactivated" },
                    { value: "cancelled", label: "Cancelled" },
                  ]
            }
            handleChange={statusChange}
          />
        </Col>
      </Row>
      <div className="position-absolute top-0 end-0 p-3">
        <FontAwesomeIcon icon={faCheck} /> {selectedRows.length} items Selected
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "auto" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : shops.length > 0 ? (
        <div
          style={{
            maxHeight: "70vh",
            overflow: "auto",
            display: "block",
          }}
          className="mb-4"
        >
          <CustomizeTableview
            className={"customerSalesModal mb-2 mt-3"}
            checkbox={{ get: selectedRows, setter: setSelectedRows, isFullRow: (state.type === "sms" || state.type === "notify") && (mode === "isForUsers" || mode === "isForShops"), selectionColumn: "id" }}
            // checkbox={{ get: selectedRows, setter: setSelectedRows, isFullRow: state.type === "sms" && mode === "isForUsers", selectionColumn: "id" }}
            data={shops}
            columnNotShow={
              mode === "isForUsers"
                ? ["id", "hasGlobal", "userId", "shopId"]
                : ["id", "onPayment", "onTrial", "isShopActive", "isShopDeactivated", "currentPlanName", "subscriptionStatus", "currentPlanName", "currentPlanStartDate", "currentPlanEndDate", "currentPlanBillingFrequency", "isSubscriptionCancelled", "cancelledOn", "isSubscriptionActive"]
            }
            dateColumn={mode === "isForUsers" ? [] : ["registrationDate"]}
            sortColumn={mode === "isForUsers" ? ["shopName"] : ["registrationDate"]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            actions={false}
          />
        </div>
      ) : (
        <div className="m-5 d-flex justify-content-center text-center">
          <h3>No Result Found</h3>
        </div>
      )}
      {totalShops > 10 && (
        <Row className="mt-5 align-items-center">
          <Col className="d-none d-md-block" md="3" lg="3">
            <span className="text-secondary fs-6"> {totalShops} Results</span>
          </Col>
          <Col xs="12" md="6" lg="6">
            <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
          </Col>
        </Row>
      )}
      <Row className="py-3">
        <Col>
          <Button
            type="clear"
            className="w-100 mt-2"
            variant="secondary"
            onClick={() => {
              close(true);
              setSelectedRows([]);
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="submit" className="w-100 mt-2" disabled={selectedRows.length === 0} onClick={() => close(true)}>
            Proceed
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ShopsListModal;
