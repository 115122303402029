import React, { useState, useEffect } from "react";
import { Container, Overlay, Popover, Row, Col, Button, Tabs, Tab, Modal } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";
import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";

import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";

import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PopUpModal from "../Modal";
import { toast } from "react-toastify";
import AddUser from "../../Pages/SecondaryUser/AddUser";
import { useRef } from "react";
import EditPassword from "./EditPassword";
const DELAY_TIME = 100;

function TechUser({ status, userType, search, sizeOfPage, setOrderBy, orderBy, refresh, toggle }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [action, setAction] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [modalAddUserShow, setAddUserModalShow] = useState(false);
  const [userId, setUserId] = useState();
  const [passId, setPassId] = useState();
  const [isActiveUser, setIsActiveUser] = useState();
  const [passwordModal, setPasswordModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const getPaginationLength = useApi(api.geTechUserNoOfPages);
  const getusers = useApi(api.getTechUsers);
  const deleteCall = useApi(api.DeleteTechUser);
  const getUserById = useApi(api.getTechUserbyId);
  const techDeactivate = useApi(postapi.DeactivateTechUser);
  const techActivate = useApi(postapi.ActivateTechUser);

  const [userData, setUserData] = useState();
  const [addUserModal, setAddUserModal] = useState(false);
  const fetchpaginationCount = async () => {
    try {
      const data = await getPaginationLength.request({
        search: search,
        orderBy: orderBy,
        size: sizeOfPage,
        isActive: status.value,
        userType: userType?.value,
      });
      setCount(data.data.no_of_items);
      if (data.data.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data.data.no_of_pages);
        if (currentPage > data.data.no_of_pages) {
          setCurrentPage(data.data.no_of_pages);
        }
      }
    } catch {}
  };
  useEffect(() => {
    fetchpaginationCount();
    const pageTimeout = setTimeout(async () => {
      const res = await getusers.request({
        search: search,
        orderBy: orderBy,
        size: sizeOfPage,
        isActive: status.value,
        page: currentPage,
        userType: userType?.value,
      });
      const data = res.data;
      setIsLoading(false);

      setItems(data);
      if (user.permissionArray.includes("User")) {
        setAction(true);
      } else {
        setAction(false);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [search, orderBy, currentPage, sizeOfPage, refresh, status, userType]);
  const changePage = (page) => {
    setCurrentPage(page);
  };

  const edit = async (data) => {
    setUserData(null);
    if (data["user_type"] === "super-admin" && user.user_type === "admin") {
      toast.error("Cannot Edit Super Admin", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setAddUserModalShow(true);
      setUserId(data["id"]);

      try {
        const res = await getUserById.request(data["id"]);
        setUserData(res?.data?.data);
      } catch (error) {}
    }
  };

  const closeModalAdd = () => {
    setAddUserModalShow(false);
  };
  const closeModalAddUser = () => {
    setAddUserModal(false);
  };
  const openPasswordModal = (data) => {
    if (data === "super-admin" && user.user_type === "admin") {
      toast.error("Cannot Change Super Admin Password", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setPasswordModal(true);
    }
  };
  const closePasswordModal = () => {
    setPasswordModal(false);
  };
  const deactivateUser = async () => {
    if (isActiveUser === 1) {
      try {
        const data = await techDeactivate.request(userId);

        if (data?.data?.success) {
          toast.success("User Deactivated Successfully ", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          toggle((prev) => !prev);

          setDeactivateModal(false);
        } else {
          setDeactivateModal(false);

          toast.error(data.data.error.message, {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const data = await techActivate.request(userId);
        if (data?.data) {
          toast.success("User Activated Successfully ", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          toggle((prev) => !prev);

          setDeactivateModal(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult onClick={() => setAddUserModal(true)} name="User" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            columnNotShow={["id", "username", "is_active"]}
            edit={action ? edit : false}
            dateColumn={["trial_till"]}
            sortColumn={["user_full_name", "user_email", "user_type"]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            toggleActionButton={{
              label: () => {
                return "Change Password";
              },
              onClick: (data) => {
                setPassId(data["id"]);
                openPasswordModal(data["user_type"]);
              },
              variant: () => {
                return "outline-primary";
              },
              style: { padding: "8px", fontSize: "12px" },
            }}
            ActiveDeactiveUser={{
              label: (data) => {
                if (data["is_active"] === 1) {
                  return "Deactivate";
                } else {
                  return "Activate";
                }
              },
              onClick: (data) => {
                if (data["is_active"] === 1) {
                  if (data["user_type"] === "super-admin") {
                    toast.error("Cannot Deactivate SuperAdmin ", {
                      position: "bottom-right",
                      autoClose: 1500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else {
                    setIsActiveUser(data["is_active"]);
                    setDeactivateModal(true);
                    setUserId(data["id"]);
                  }
                } else {
                  // handleActivate(data["id"]);

                  if (data["user_type"] === "super-admin") {
                    toast.error("Cannot Deactivate SuperAdmin ", {
                      position: "bottom-right",
                      autoClose: 1500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else {
                    setIsActiveUser(data["is_active"]);
                    setDeactivateModal(true);
                    setUserId(data["id"]);
                  }
                }
              },
              variant: (data) => {
                if (data["is_active"] === 1) {
                  return "outline-danger";
                } else {
                  return "outline-primary";
                }
              },
              style: { padding: "8px", fontSize: "12px" },
              column: "is_active",
            }}
            editPassword={() => {}}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} result(s)</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result(s)</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
        </>
      )}
      <PopUpModal title="Add User" show={addUserModal} onHide={() => setAddUserModal(false)}>
        <AddUser toggle={toggle} close={closeModalAddUser} />
      </PopUpModal>
      <PopUpModal title="Edit User" show={modalAddUserShow} onHide={() => setAddUserModalShow(false)}>
        <AddUser toggle={toggle} data={userData} id={userId} close={closeModalAdd} />
      </PopUpModal>
      {/* POPOVER */}
      <PopUpModal title="Change Password" show={passwordModal} onHide={() => setPasswordModal(false)}>
        <EditPassword toggle={toggle} id={passId} close={closePasswordModal} />
      </PopUpModal>
      <Modal
        show={deactivateModal}
        onHide={() => {
          setDeactivateModal(false);
        }}
        animation={true}
        centered
      >
        <Modal.Header>
          <Modal.Title>{isActiveUser === 0 ? "Activate" : "Deactivate"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to {isActiveUser === 0 ? "Activate" : "Deactivate"}?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <Button
              className="w-100 rounded-3"
              onClick={() => {
                setDeactivateModal(false);
              }}
            >
              Cancel
            </Button>
            <Button className="w-100 rounded-3" onClick={() => deactivateUser()}>
              {isActiveUser === 0 ? "Activate" : "Deactivate"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TechUser;
