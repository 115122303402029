import { useSelector } from "react-redux";
import { Container, Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import { getActiveListingsInsights, getPlatformShopsInsights, getRegisteredShopsOverTime, getShopSalesInsights, getDataInsights, getTechbazaarSalesInsights } from "../api/getapi";
import useApi from "../Hooks/useApi";
import calender from "./../assets/svg/os-icon-calendar.svg";
import moment from "moment";
import { useState, useEffect } from "react";
import CardsSection from "../Components/DashboardCards/CardsSection/CardsSection";
import StatCard from "../Components/DashboardCards/StatCard/StatCard";
import BarChart from "../Components/Charts/BarChart";
import { formatNumber } from "../Utilities/numbers";
import GroupedBarChart from "../Components/Charts/GroupedBarChart";

const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);

  const [platformShopsInsights, setplatformShopsInsights] = useState({});
  const [registeredShopsOverTime, setRegisteredShopsOverTime] = useState({});
  const [shopSalesInsightsOverallSalesCount, setShopSalesInsightsOverallSalesCount] = useState({});
  const [shopSalesInsightsOverallSalesValue, setShopSalesInsightsOverallSalesValue] = useState({});

  const [activeListingsInsightsOverall, setActiveListingsInsightsOverall] = useState({});
  const [subscriptionPaymentsReceived, setSubscriptionPaymentsReceived] = useState({});
  const [successfulConversions, setSuccessfulConversions] = useState({});
  const [shopRegistrationsByUser, setShopRegistrationsByUser] = useState({});
  const [modelDataInsights, setModelDataInsights] = useState({});
  const [variantsDataInsights, setVariantsDataInsights] = useState({});

  const currentDate = new Date().toDateString();
  const today = new Date();
  const currentTime = today.getHours();
  let showtime;
  if (currentTime < 12) {
    showtime = "Good Morning";
  } else if (currentTime < 18) {
    showtime = "Good Afternoon";
  } else {
    showtime = "Good Evening";
  }

  const fetchPlatformShopsInsights = async () => {
    try {
      const response = await getPlatformShopsInsights();
      setplatformShopsInsights(response.data);
    } catch {}
  };

  const fetchRegisteredShopsOverTime = async () => {
    try {
      const response = await getRegisteredShopsOverTime();
      setRegisteredShopsOverTime(response.data);
    } catch {}
  };

  const fetchShopSalesInsights = async () => {
    try {
      const response = await getShopSalesInsights();
      const { general, perShop } = response.data;
      const overallSalesCount = {};
      const overallSalesValue = {};
      Object.entries(general).forEach(([period, data]) => {
        overallSalesCount[period] = data[0].total_sales;
        overallSalesValue[period] = data[0].total_sales_amount;
      });
      setShopSalesInsightsOverallSalesCount(overallSalesCount);
      setShopSalesInsightsOverallSalesValue(overallSalesValue);
    } catch {}
  };

  const fetchActiveListingsInsights = async () => {
    try {
      const response = await getActiveListingsInsights();
      const { general, perShop } = response.data;
      setActiveListingsInsightsOverall(general);
    } catch {}
  };

  const fetchTechbazaarSalesInsights = async () => {
    try {
      const response = await getTechbazaarSalesInsights();
      const { general, perShop, registeredShopByUser, trialToPaidPlan } = response.data;
      setSubscriptionPaymentsReceived(general.subscriptionPaymentsReceived);
      setSuccessfulConversions(trialToPaidPlan);
      // Extract all time periods
      const periods = Object.keys(registeredShopByUser);
      // Extract all unique user names
      const users = Object.values(registeredShopByUser)
        .flat()
        .reduce((acc, cur) => {
          if (!acc.includes(cur.user_full_name)) {
            acc.push(cur.user_full_name);
          }
          return acc;
        }, []);

      // Transform the object
      const transformedShopRegistrationsByUser = users.reduce((acc, user) => {
        acc[user] = {};
        periods.forEach((period) => {
          const userData = registeredShopByUser[period].find((entry) => entry.user_full_name === user);
          acc[user][period] = userData ? userData.total_regsitered_shops : 0;
        });
        return acc;
      }, {});
      setShopRegistrationsByUser(transformedShopRegistrationsByUser);
    } catch {}
  };

  const fetchModelDataInsights = async () => {
    try {
      const response = await getDataInsights("model");
      const { models, variants } = response.data;
      setModelDataInsights(models);
      setVariantsDataInsights(variants);
    } catch {}
  };

  useEffect(() => {
    fetchPlatformShopsInsights();
    fetchRegisteredShopsOverTime();
    fetchShopSalesInsights();
    fetchActiveListingsInsights();
    fetchTechbazaarSalesInsights();
    fetchModelDataInsights();
  }, []);

  return (
    <Container fluid className="px-xl-5">
      <Row>
        <Col lg="6">
          <h4>
            Hello <span className="text-capitalize">{user && user.user_full_name}</span> 👋🏼,
          </h4>
        </Col>
        <Col lg="6" className="text-start text-md-end py-2">
          <img src={calender} alt="calender" /> <span className="ms-1">{currentDate}</span>
        </Col>
      </Row>
      <p>{showtime}!</p>
      <Row className="py-2 g-2">
        <CardsSection sectionTitle="Platform Shops Insights">
          <Col md="12" lg="5" sm="12" xl="5" className="order-md-2 order-lg-1">
            <Row className="g-1">
              <Col md="12" lg="12" sm="12" xl="12">
                <StatCard title="Total" count={platformShopsInsights.totalShops} />
              </Col>
              <Col md="6" lg="6" sm="6" xl="6">
                <StatCard title="Active" count={platformShopsInsights.activeShops} />
              </Col>
              <Col md="6" lg="6" sm="6" xl="6">
                <StatCard title="Deactivated" count={platformShopsInsights.deactivatedShops} />
              </Col>
              <Col md="6" lg="6" sm="6" xl="6">
                <StatCard title="Awaiting Activation" count={platformShopsInsights.awaitingActivationShops} />
              </Col>
              <Col md="6" lg="6" sm="6" xl="6">
                <StatCard title="On Trial" count={platformShopsInsights.shopsOnTrial} />
              </Col>
              <Col md="6" lg="6" sm="6" xl="6">
                <StatCard title="On Subscription" count={platformShopsInsights.shopsOnSubscription} />
              </Col>
              <Col md="6" lg="6" sm="6" xl="6">
                <StatCard title="Cancelled Subscription" count={platformShopsInsights.shopsInCancelledSubscription} />
              </Col>
            </Row>
          </Col>
          <Col md="12" lg="7" sm="12" xl="7" className="order-md-1 order-lg-2">
            <BarChart data={registeredShopsOverTime} label="No. of Shops" titleText="Registered Shops over different Time Periods" />
          </Col>
        </CardsSection>
      </Row>
      <Row className="py-2 g-2">
        <CardsSection sectionTitle="Shop Sales Insights">
          <Col md="6" lg="6" sm="12" xl="6">
            <StatCard title="Total Sales" count={formatNumber(shopSalesInsightsOverallSalesCount["all-time"])} />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <StatCard title="Total Sales Value" count={formatNumber(shopSalesInsightsOverallSalesValue["all-time"])} />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <BarChart data={shopSalesInsightsOverallSalesCount} label="No. of Sales" titleText="Shop Sales over different Time Periods" />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <BarChart data={shopSalesInsightsOverallSalesValue} label="Sales Value" titleText="Shop Sales Value over different Time Periods" />
          </Col>
        </CardsSection>
      </Row>
      <Row className="py-2 g-2">
        <CardsSection sectionTitle="Listings Insights">
          <Col md="12" lg="12" sm="12" xl="12">
            <StatCard title="Active Listings" count={activeListingsInsightsOverall["all-time"]} />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <BarChart data={activeListingsInsightsOverall} label="No. of Listings" titleText="Active Listings over different Time Periods" />
          </Col>
        </CardsSection>
      </Row>
      <Row className="py-2 g-2">
        <CardsSection sectionTitle="Techbazaar Sales / Marketing Insights">
          <Col md="6" lg="6" sm="12" xl="6">
            <StatCard title="Subscriptions Payment Receievd" count={formatNumber(subscriptionPaymentsReceived["all-time"])} />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <StatCard title="Trial → Paid Plan Conversions" count={successfulConversions["all-time"]} />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <BarChart data={subscriptionPaymentsReceived} label="Amount" titleText="Subscriptions Payment Received over different Time Periods" />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <BarChart data={successfulConversions} label="No. of Shops" titleText="Successful Conversions over different Time Periods" />
          </Col>
          <Col md="4" lg="4" sm="12" xl="4">
            <Row className="flex-column g-2">
              {shopRegistrationsByUser &&
                Object.keys(shopRegistrationsByUser).map((user, index) => (
                  <Col md="12" lg="12" sm="12" xl="12" key={index}>
                    <StatCard title={`Leads by ${user}`} count={shopRegistrationsByUser[user]["all-time"]} />
                  </Col>
                ))}
            </Row>
          </Col>
          <Col md="8" lg="8" sm="12" xl="8">
            <GroupedBarChart data={shopRegistrationsByUser} title="Shop Registrations by Sales Team over different Time Periods" />
          </Col>
        </CardsSection>
      </Row>
      <Row className="py-2 g-2">
        <CardsSection sectionTitle="Data Insights">
          <Col md="6" lg="6" sm="12" xl="6">
            <StatCard title="Total Models Inserted" count={modelDataInsights["all-time"]} />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <StatCard title="Total Variants Inserted" count={variantsDataInsights["all-time"]} />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <BarChart data={modelDataInsights} label="No. of Models" titleText="Models Inserted over different Time Periods" />
          </Col>
          <Col md="6" lg="6" sm="12" xl="6">
            <BarChart data={variantsDataInsights} label="No. of Variants" titleText="Variants Inserted over different Time Periods" />
          </Col>
        </CardsSection>
      </Row>
    </Container>
  );
};

export default Dashboard;
