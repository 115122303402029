import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";

import SearchableSelect from "../../Components/SearchableSelect";

import Points from "../../Components/Pointsystem/Points";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Data from "../../Components/data/Data";
import AddPage from "./AddPage";
import PopUpModal from "../../Components/Modal";

const DataPage = () => {
  const [searchInput, setSearchInput] = useState("");

  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [addUserShow, setAddUserShow] = useState(true);
  const [refresh, toggle] = useState(false);
  const [userType, setUserType] = useState(null);
  const [showDataModal, setShowDataModal] = useState();
  const [status, setStatus] = useState({ value: true, label: "Active" });

  // shops on  payment

  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({ shop_name: "ASC" });
  // INCOMPLETE ------------------------------------

  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const resetFilterHandle = () => {
    setUserType(null);
    setSearchInput("");
    setStatus({ value: true, label: "Active" });
  };
  useEffect(() => {}, [refresh]);

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  return (
    <>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs="2" lg="2">
            <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={() => setShowDataModal(true)}>
              <span className="d-none d-lg-inline">Add Page</span> <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
          {/* For Mobile screen End */}
        </Row>

        <>
          <div className="pt-2">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Data search={searchInput} status={status} orderBy={orderBy} setOrderBy={setOrderBy} sizeOfPage={sizeOfPages} userType={userType} refresh={refresh} toggle={toggle} />
            </Col>
            <PopUpModal size="lg" title="Add Modal" show={showDataModal} onHide={() => setShowDataModal(false)}>
              <AddPage close={() => setShowDataModal(false)} toggle={toggle} />
            </PopUpModal>
          </Row>
        </>
      </Container>
    </>
  );
};
export default DataPage;
