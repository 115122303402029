import { useState, useEffect } from "react";
import NavOption from "./NavOption";

const MenuItem = ({ setIsSandwichOpen, subnavigation, item, isSandwichOpen, active, setActive, activeParent, parentIdentifier, setActiveParent, activeCaret, setActiveCaret }) => {
  const [isOpen, setIsOpen] = useState(activeCaret === item.identifier);
  return (
    <>
      <NavOption
        subnavigation={subnavigation}
        setIsSandwichOpen={setIsSandwichOpen}
        isSandwichOpen={isSandwichOpen}
        title={item.title}
        icon={item.icon}
        activeIcon={item.activeIcon}
        path={item.path}
        Subnav={item.subNav}
        isSubNav={item.subNav?.length > 0}
        navPath={item.path}
        identifier={item.identifier}
        active={active}
        setActive={setActive}
        activeParent={activeParent}
        parentIdentifier={parentIdentifier}
        setActiveParent={setActiveParent}
        activeCaret={activeCaret}
        setActiveCaret={setActiveCaret}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {activeCaret === item.identifier &&
        isOpen &&
        item.subNav?.map((subItem, index) => {
          return (
            <MenuItem
              subnavigation={subItem.subnavigation}
              isSandwichOpen={isSandwichOpen}
              key={index}
              item={subItem}
              active={active}
              setActive={setActive}
              activeParent={activeParent}
              parentIdentifier={item.identifier}
              setActiveParent={setActiveParent}
              activeCaret={activeCaret}
              setActiveCaret={setActiveCaret}
            />
          );
        })}
    </>
  );
};

export default MenuItem;
