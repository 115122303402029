import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { useSelector } from "react-redux";
import useApi from "../../Hooks/useApi";
import customloader from "../../assets/images/RollingLoader.gif";

function CancelPlan({ close, id, toggle }) {
  const [isLoading, setIsLoading] = useState(false);
  const getReq = useApi(api.getRequestforsubs);
  const getrefund = useApi(api.GetRefundDetails);
  const cancelSubscription = useApi(api.cancelSubscription);
  const { user } = useSelector((state) => state.auth);
  const [checkReq, setCheckReq] = useState();
  const fetchRefundDetails = async () => {
    try {
      const data = await getrefund.request(id);
    } catch (error) {
      console.log(error);
    }
  };
  const confirm = async () => {
    try {
      const requestId = getReq.data[0].id;
      const data = await cancelSubscription.request(requestId, id);
      toggle((prev) => !prev);
      close();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setIsLoading(true);
        const data = await getReq.request({
          shop_id: id,
          user_id: user.user_id,
          request_type: "plan-cancellation-request",
          status: "in_progress",
        });
        setCheckReq(data?.data?.length);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchRefundDetails();
    fetchRequests();
  }, []);
  return (
    <>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <div>
          {checkReq > 0 ? (
            <>
              <div>
                <div className="d-flex flex-column ms-5 mb-4">
                  {getrefund?.data?.success?.data?.refund_amount && (
                    <span>
                      <strong>Amount Refund: </strong>
                      {getrefund?.data?.success?.data?.refund_amount}
                    </span>
                  )}
                  {getrefund?.data?.success?.data?.amount_receivable && (
                    <span>
                      <strong>Amount Due: </strong>
                      {getrefund?.data?.success?.data?.amount_receivable}
                    </span>
                  )}
                </div>
                <span className="d-flex justify-content-center me-4 pb-4">
                  Do you really want to Cancel Subscription?
                </span>
              </div>
              <div className="d-flex gap-3">
                <Button
                  variant="secondary"
                  className="w-100 rounded-3"
                  onClick={close}
                >
                  Cancel
                </Button>
                <Button className="w-100 rounded-3" onClick={confirm}>
                  Confirm
                </Button>
              </div>
            </>
          ) : (
            <div className="text-center">
              {" "}
              There is no request for this shop
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CancelPlan;
