import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";

import SearchableSelect from "../../Components/SearchableSelect";

import Points from "../../Components/Pointsystem/Points";

const PointSystem = () => {
  const [searchInput, setSearchInput] = useState("");

  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [addUserShow, setAddUserShow] = useState(true);
  const [refresh, toggle] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userTypeOptions, setUserTypeOptions] = useState([
    { value: "super-admin", label: "Super Admin" },
    { value: "admin", label: "Admin" },
    { value: "marketing", label: "marketing" },
    { value: "data", label: "data" },
  ]);
  const [status, setStatus] = useState({ value: true, label: "Active" });

  // shops on  payment

  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({ shop_name: "ASC" });
  // INCOMPLETE ------------------------------------

  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const resetFilterHandle = () => {
    setUserType(null);
    setSearchInput("");
    setStatus({ value: true, label: "Active" });
  };
  useEffect(() => {}, [refresh]);

  const deleteInventory = (id) => {};
  const handleTabs = (e) => {
    if (e === "SellerUser") {
      setAddUserShow(false);
      setUserTypeOptions([
        { value: "primary", label: "Primary" },
        { value: "secondary", label: "Secondary" },
      ]);
    } else {
      setAddUserShow(true);
      setUserTypeOptions([
        { value: "super-admin", label: "Super Admin" },
        { value: "admin", label: "Admin" },
        { value: "marketing", label: "marketing" },
        { value: "data", label: "data" },
      ]);
    }
    resetFilterHandle();
  };
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  return (
    <>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput
              searchlogo
              handleChange={searchFilter}
              value={searchInput}
            />
          </Col>

          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>

          {/* For Mobile screen End */}
        </Row>

        <>
          <div className="pt-2">
            <Button
              size="sm"
              type="submit"
              style={{ fontSize: "14px" }}
              onClick={resetFilterHandle}
            >
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Points
                search={searchInput}
                status={status}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                sizeOfPage={sizeOfPages}
                userType={userType}
                refresh={refresh}
                toggle={toggle}
              />
            </Col>
          </Row>
        </>
      </Container>
    </>
  );
};
export default PointSystem;
