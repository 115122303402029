import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import * as postapi from "../../api/postapi";

import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
const ActivateShopsModal = ({
  id,
  subsType,
  shopType,
  data,
  close,
  toggle,
}) => {
  const [checkValidation, setCheckValidation] = useState(false);
  const [validactive, setvalidactive] = useState(false);
  const [reason, setReason] = useState("");
  const [reason1, setReason1] = useState("");
  const [reason2, setReason2] = useState("");
  const [reason3, setReason3] = useState("");
  const [otherReason, setOtherReason] = useState(false);
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [trialTill, setTrialTill] = useState(null);
  const [error, setError] = useState("");
  const activateUser = useApi(postapi.ActivateShops);
  const changeDate = (date, number) => {
    const currDate = new Date(date);
    let addedDate;
    if (number > 0) {
      addedDate = currDate.setDate(currDate.getDate() + number);
    } else {
      const positive = Math.abs(number);
      addedDate = currDate.setDate(currDate.getDate() - positive);
    }
    const formattedDate = new Date(addedDate).toISOString();
    return formattedDate;
  };
  const banner_date = changeDate(trialTill, -3);
  const grace_date = changeDate(trialTill, 5);

  useEffect(() => {}, [
    reason,
    reason1,
    reason2,
    reason3,
    otherReason,
    message,
  ]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (reason || reason1 || reason2 || reason3 || otherReason) {
      setCheckValidation(false);
    } else {
      setCheckValidation(true);
    }
    if (shopType === "onTrial") {
      if (trialTill) {
        if (reason || reason1 || reason2 || reason3 || otherReason) {
          if (otherReason && !message.length > 0) {
            setValidated(true);
          } else {
            const data = {
              reason: {
                shop_id: id,
                activation_reasons:
                  reason + reason1 + reason2 + reason3 + message,
                activated_on: new Date()
                  .toISOString()
                  .replace("T", " ")
                  .replace("Z", "")
                  .split(".")[0],
              },
              subscription: {
                current_plan_end_date: trialTill
                  .replace("T", " ")
                  .replace("Z", "")
                  .split(".")[0],
                banner_showing_start_date: banner_date
                  .replace("T", " ")
                  .replace("Z", "")
                  .split(".")[0],
                grace_period_ends_on: grace_date
                  .replace("T", " ")
                  .replace("Z", "")
                  .split(".")[0],
              },
            };

            try {
              await activateUser.request(id, data);
              close();

              toggle((prev) => !prev);
            } catch {}
          }
        }
      } else {
        setValidated(true);
      }
    } else {
      if (reason || reason1 || reason2 || reason3 || otherReason) {
        if (otherReason && !message.length > 0) {
          setValidated(true);
        } else {
          const data = {
            reason: {
              shop_id: id,
              activation_reasons:
                reason + reason1 + reason2 + reason3 + message,
              activated_on: new Date()
                .toISOString()
                .replace("T", " ")
                .replace("Z", "")
                .split(".")[0],
            },
          };

          try {
            await activateUser.request(id, data);
            close();

            toggle((prev) => !prev);
          } catch {}
        }
      } else {
        setValidated(true);
      }
    }
  };

  return (
    <>
      {subsType !== "active_plan" ? (
        <div class="alert alert-warning" role="alert">
          First add subscription for this shop
        </div>
      ) : (
        <Form
          noValidate
          validated={validated}
          autoComplete="nope"
          onSubmit={handleSubmit}
        >
          {error && (
            <p className="text-danger p-2 text-center my-3 rounded-1">
              *{error}
            </p>
          )}

          <Row className="py-2 mb-2 d-flex flex-column">
            <Form.Label className="mb-1">
              Reasons for Activation <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Col lg={"8"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Payment issues solved"
                  // checked={reason}
                  value="Payment issues solved"
                  onChange={(e) => {
                    // setCheckValidation(!e.target.checked);
                    if (e.target.checked == true) {
                      setReason(e.target.value);
                    } else {
                      setReason("");
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"8"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Shop not activated  for long time solved "
                  value=",Shop not activated for long time solved"
                  // checked={reason1}
                  onChange={(e) => {
                    // setCheckValidation(!e.target.checked);
                    if (e.target.checked == true) {
                      setReason1(e.target.value);
                    } else {
                      setReason1("");
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"8"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Behaviour issue solved"
                  value=",Behaviour issue solved"
                  // checked={reason3}
                  onChange={(e) => {
                    // setCheckValidation(!e.target.checked);
                    if (e.target.checked == true) {
                      setReason3(e.target.value);
                    } else {
                      setReason3("");
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"8"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Point system solved"
                  // checked={reason2}
                  value=",Point system solved"
                  onChange={(e) => {
                    // setCheckValidation(!e.target.checked);
                    if (e.target.checked == true) {
                      setReason2(e.target.value);
                    } else {
                      setReason2("");
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={"8"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Other"
                  value="-"
                  checked={otherReason}
                  onChange={(e) => {
                    // setCheckValidation(!e.target.checked);
                    if (e.target.checked == true) {
                      setOtherReason(e.target.value);
                    } else {
                      setOtherReason("");
                    }
                  }}
                />
              </Form.Group>
            </Col>

            {otherReason && (
              <>
                <Form.Label className="mb-1">Reason : </Form.Label>
                <Col lg={"10"}>
                  <Form.Group>
                    <Form.Control
                      required
                      value={message}
                      onChange={(e) => {
                        // setCheckValidation(e.target.checked);
                        setMessage(e.target.value);
                      }}
                      as="textarea"
                      rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      Fill Reason.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </>
            )}
            {shopType === "onTrial" && (
              <Col lg={"10"}>
                <Form.Group
                  className="mt-3"
                  as={Col}
                  lg="6"
                  md="6"
                  sm="12"
                  controlId="formBasicShopName"
                >
                  <label>
                    Trial till<span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={trialTill}
                    onChange={(e) => {
                      setTrialTill(e.target.value);
                    }}
                    required
                    type="date"
                  />

                  <Form.Control.Feedback type="invalid">
                    Please select Date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Button type="submit" disabled={validactive} className="w-100">
            Activate
          </Button>

          {checkValidation && (
            <p className="text-center mt-3" style={{ color: "tomato" }}>
              Select any one{" "}
            </p>
          )}
        </Form>
      )}
    </>
  );
};

export default ActivateShopsModal;
