import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import PopUpModal from "../../Components/Modal";
import SearchableSelect from "../../Components/SearchableSelect";
import SearchInput from "../../Components/SearchInput";
import EditModulePricingModal from "../../Components/ModulesPricing/EditModulePricingModal";
const ModulePricing = () => {
  let [modules, setModules] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [status, setStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [editModulePricingModalShow, setEditModulePricingModalShow] = useState(false);
  const [moduleData, setModuleData] = useState({});

  const fetchAllModulesCall = useApi(api.fetchAllModules);
  const resetFilterHandle = () => {
    setSizeOfPages(10);
    setStatus("");
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const fetchAllModule = async () => {
    const res = await fetchAllModulesCall.request({
      size: sizeOfPages,
      page: currentPage,
      search: searchInput,
      status: status?.value,
      orderBy: orderBy,
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setModules(
        data?.data?.map((m) => ({
          id: m.id,
          name: m.name,
          nav_key: m.nav_key,
          type: m.type,
          price: m.price,
          module_type: m.is_submodule === 0 ? "Main Module" : "Sub Module",
          parent_module: m.parent_name,
          created_at: m.created_at,
          updated_at: m.updated_at,
        }))
      );
      setCount(data?.count?.no_of_items);
      if (data?.count?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.count?.no_of_pages);
        if (currentPage > data?.count?.no_of_pages) {
          setCurrentPage(data?.count?.no_of_pages);
        }
      }
    } else {
      setModules([]);
    }
  };
  useEffect(() => {
    fetchAllModule();
  }, [currentPage, toggle, sizeOfPages, toggle, orderBy, searchInput, status]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const edit = (data) => {
    setEditModulePricingModalShow(true);
    setModuleData(data);
  };
  const closeModalEdit = () => {
    setEditModulePricingModalShow(false);
  };
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const handleStatus = (e) => {
    if (e.label === "All") {
      setStatus("");
    } else {
      setStatus({ value: e.value, label: e.label });
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              value={status}
              searchable={false}
              options={[
                { value: "", label: "All" },
                { value: "is_active", label: "Active" },
                { value: "is_coming", label: "Coming" },
              ]}
              handleChange={handleStatus}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : !modules[0] ? (
            <NoResult />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview data={modules} columnNotShow={["id", "is_deleted", "is_active", "is_coming", "parent_module", "deleted_at"]} dateColumn={["created_at", "updated_at"]} sortColumn={["created_at", "updated_at", "name"]} orderBy={orderBy} setOrderBy={setOrderBy} edit={edit} />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
          <PopUpModal title="Update Price" show={editModulePricingModalShow} onHide={() => setEditModulePricingModalShow(false)}>
            <EditModulePricingModal toggle={refresh} close={closeModalEdit} data={moduleData} />
          </PopUpModal>
        </>
      </Container>
    </div>
  );
};

export default ModulePricing;
