import Select from "react-select";

const SearchableSelect = ({
  label,
  value,
  id,
  disabled,
  onClick,
  handleChange,
  forceSelection,
  placeholder,
  options,
  important,
  multiselect,
  searchable,
  defaultValue,
  onInputChange,
  selectClassName,
  closeMenuOnSelect = true,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#5932EA" : "#80858C",
      background: state.isSelected ? "#F5F6FA" : "#fff",
      padding: 10,
      zIndex: 10,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      maxWidth: "100%",
      fontSize: "15px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  return (
    <>
      {label ? (
        <label>
          {label}{" "}
          {important ? <span className="text-danger">*</span> : <span></span>}
        </label>
      ) : (
        ""
      )}
      <Select
        onFocus={(e) => {
          e.target.autocomplete = "nope";
        }}
        autoComplete="nope"
        className={`text-secondary  bg-white text-capitalize ${selectClassName}`}
        isDisabled={disabled}
        forceSelection={forceSelection}
        classNamePrefix="react-select-container"
        defaultValue={defaultValue}
        isMulti={multiselect}
        isSearchable={searchable}
        closeMenuOnScroll={false}
        closeMenuOnSelect={closeMenuOnSelect}
        options={options}
        value={value}
        placeholder={placeholder}
        id={id}
        onClick={onClick}
        onChange={handleChange}
        styles={customStyles}
        onInputChange={onInputChange}
      />
    </>
  );
};

export default SearchableSelect;
