import axios from "axios";
import moment from "moment";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const config = {
  headers: {
    Authorization: localStorage.getItem("refreshToken"),
  },
};

// Dashboard
export function getPlatformShopsInsights() {
  return api.get("/dashboard/platform-shops-insights", { withCredentials: true });
}

export function getRegisteredShopsOverTime() {
  return api.get("/dashboard/registered-shops-over-time-periods", { withCredentials: true });
}

export function getActiveListingsInsights() {
  return api.get("/dashboard/active-listings-insights", { withCredentials: true });
}

export function getShopSalesInsights() {
  return api.get("/dashboard/shop-sales-insights", { withCredentials: true });
}

export function getDataInsights() {
  return api.get("/dashboard/data-insights", { withCredentials: true });
}

export function getTechbazaarSalesInsights() {
  return api.get("/dashboard/techbazaar-sales-insights", { withCredentials: true });
}
// shopkeepres

export function getShopKeepersOnTrial(data) {
  const { page, size, ...remData } = data;
  return api.post(`/shops/get-shopkeeper-on-trial${page && size ? `?page=${page}&size=${size}` : ""}`, remData, { withCredentials: true });
}

export function getShopKeepersOnPayment(data) {
  const { page, size, ...remData } = data;
  return api.post(`/shops/get-shopkeeper-on-payment${page && size ? `?page=${page}&size=${size}` : ""}`, remData, { withCredentials: true });
}
export function getDeactivateReasons(id) {
  //  const {size,page,...remData} = data;
  return api.get(`/shops/deactivate-reason/${id}`, { withCredentials: true });
}
export function getProvinces() {
  return api.get(`/location/get-province`, { withCredentials: true });
}
export function getCities(id) {
  return api.get(`/location/get-city/${id}`, { withCredentials: true });
}
export function getSalesman() {
  return api.get(`/user/get-marketing-user`, { withCredentials: true });
}
export function getOnTrialNoOfPages(data) {
  const { size, ...remData } = data;
  return api.post(`/shops/get-shopkeeper-on-trial/no-of-pages?size=${size}`, remData, { withCredentials: true });
}
export function getOnPayNoOfPages(data) {
  const { size, ...remData } = data;
  return api.post(`/shops/get-shopkeeper-on-payment/no-of-pages?size=${size}`, remData, { withCredentials: true });
}
export function getCon() {
  return api.get(`/user/get-marketing-user`, { withCredentials: true });
}

export function LoginShops(username) {
  return api.get(`shops/direct-login/${username}`, { withCredentials: true });
}

export function GetSingleShop(id) {
  return api.get(`shops/${id}`, { withCredentials: true });
}
export function getDropdownShops(search) {
  const searchParams = new URLSearchParams({
    ...(search ? { search } : {}),
  });
  return api.get(`shops/for-dropdown${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}
// Users

export function getTechUsers(data) {
  const { page, size, ...remData } = data;
  return api.post(`/user/tech-bazaar${page && size ? `?page=${page}&size=${size}` : ""}`, remData, { withCredentials: true });
}
export function getSellerUsers(data) {
  const { page, size, ...remData } = data;
  return api.post(`/user/seller${page && size ? `?page=${page}&size=${size}` : ""}`, remData, { withCredentials: true });
}
export function geTechUserNoOfPages(data) {
  const { size, ...remData } = data;
  return api.post(`/user/tech-bazaar/no-of-pages?size=${size}`, remData, {
    withCredentials: true,
  });
}
export function getSellerUserNoOfPages(data) {
  const { size, ...remData } = data;
  return api.post(`/user/seller/no-of-pages?size=${size}`, remData, {
    withCredentials: true,
  });
}
export function DeleteSellerUser(id) {
  return api.delete(`/user/seller-user/${id}`, { withCredentials: true });
}
export function DeleteTechUser(id) {
  return api.delete(`/user/tech-bazaar-user/${id}`, { withCredentials: true });
}

export function getTechUserbyId(id) {
  return api.get(`user/tech-bazaar-user/${id}`, { withCredentials: true });
}

export function getAdminUserbyId(id) {
  return api.get(`user/user/admin-user/${id}`, { withCredentials: true });
}
// point system
export function getPointsData(data) {
  const { page, size, ...remData } = data;
  return api.post(`/shops/trial-score${page && size ? `?page=${page}&size=${size}` : ""}`, remData, { withCredentials: true });
}

export function getPointsNoOfPages(data) {
  const { size, ...remData } = data;
  return api.post(`/shops//trial-score/no-of-pages?size=${size}`, remData, {
    withCredentials: true,
  });
}

// plans

export function getPlansDropDown(data) {
  return api.post(`/subscriptions/get/plans`, data, { withCredentials: true });
}
export function getPlans(data) {
  const { size, page, ...remData } = data;

  return api.post(`/subscriptions/get/plans?size=${size}&page=${page}`, remData, { withCredentials: true });
}
export function getPlansNoOFPages(data) {
  const { size, page, ...remData } = data;

  return api.post(`/subscriptions/get/plans/no-of-pages?size=${size}`, remData, { withCredentials: true });
}
export function getSinglePlan(id) {
  return api.get(`/subscriptions/plans/${id}`, { withCredentials: true });
}

export function checkCustomPlan(data) {
  return api.post(`/subscriptions/plans/check-custom-plan`, data, {
    withCredentials: true,
  });
}

export function activatePlans(id) {
  return api.get(`/subscriptions/plans/activate/${id}`, {
    withCredentials: true,
  });
}
export function deactivatePlans(id) {
  return api.get(`/subscriptions/plans/deactivate/${id}`, {
    withCredentials: true,
  });
}

// subscriptions

export function getSubscriptions(data) {
  const { size, page, ...remData } = data;
  return api.post(`/subscriptions/phase?size=${size}&page=${page}`, remData, {
    withCredentials: true,
  });
}
export function GetRefundDetails(id) {
  return api.post(`/subscriptions/refund-details/${id}`, {}, { withCredentials: true });
}
export function cancelSubscription(id, shopId) {
  return api.patch(`/subscriptions/cancel/${id}`, { shop_id: shopId }, { withCredentials: true });
}
export function cancelSubscriptionDeactivate(id, shopId) {
  return api.patch(`/subscriptions/cancel-deactivated/${id}`, { shop_id: shopId }, { withCredentials: true });
}
export function getNoOfSubscriptions(data) {
  const { size, ...remData } = data;
  return api.post(`/subscriptions/phase/no-of-pages?size=${size}`, remData, {
    withCredentials: true,
  });
}
export function getDeactivatePhase(data) {
  const { size, page, ...remData } = data;
  return api.post(`/subscriptions/phase/deactivate?size=${size}&page=${page}`, remData, { withCredentials: true });
}
export function getDeactivatePhaseNoOfPages(data) {
  const { size, ...remData } = data;
  return api.post(`/subscriptions/phase/deactivate/no-of-pages?size=${size}`, remData, { withCredentials: true });
}
export function getChangePlanDetails(id) {
  return api.get(`/subscriptions/change-plan/${id}`, { withCredentials: true });
}

// zphase
export function getZPhase(data) {
  const { size, page, ...remData } = data;
  return api.post(`/subscriptions/phasez?size=${size}&page=${page}`, remData, {
    withCredentials: true,
  });
}
export function getZPhaseNoOfPages(data) {
  const { size, ...remData } = data;
  return api.post(`/subscriptions/phasez/no-of-pages?size=${size}`, remData, {
    withCredentials: true,
  });
}

export function fetchThemeByPlanId(id) {
  return api.get(`/subscriptions/plans/${id}`, { withCredentials: true });
}
// requests
export function getRequest(data) {
  const { size, page, ...remData } = data;
  return api.post(`/request/get?page=${page}&size=${size}`, remData, {
    withCredentials: true,
  });
}
export function getRequestforsubs(data) {
  const { size, page, ...remData } = data;
  return api.post(`/request/get`, remData, { withCredentials: true });
}

export function getNote(id) {
  //  const {size,page,...remData} = data;
  return api.get(`/request/notes/${id}`, { withCredentials: true });
}
export function getRequestNoOfPages(data) {
  const { size, page, ...remData } = data;

  return api.post(`/request/no-of-pages?size=${size}`, remData, {
    withCredentials: true,
  });
}
export function getArears(id) {
  //  const {size,page,...remData} = data;
  return api.get(`/transactions/arrears/${id}`, { withCredentials: true });
}

export function getBill(id) {
  return api.get(`/transactions/bill/${id}`, { withCredentials: true });
}
// Data
export function getCompoentData(data) {
  const { search, size, page, ...remdata } = data;
  return api.get(`/components?page=${page}&size=${size}`, {
    withCredentials: true,
    headers: { search },
  });
}
export function getCompoentNoOfPages(data) {
  const { search, size, ...remdata } = data;
  return api.get(`/components/no-of-pages?size=${size}`, {
    withCredentials: true,
    headers: { search },
  });
}

//Theme
export function fetchTheme(id) {
  return api.get(`/themes/${id}`, { withCredentials: true });
}
export function getAllThemes() {
  return api.get(`/themes`, { withCredentials: true });
}
export function getSingleCompoentData(id) {
  return api.get(`/components/${id}`, { withCredentials: true });
}
export function DeleteComponent(id) {
  return api.delete(`/components/${id}`, { withCredentials: true });
}

export function getNoOfPagesPaidShops(data) {
  const { size, ...remData } = data;
  return api.post(`/transactions/get-paidshops/no-of-pages?size=${size}`, remData, { withCredentials: true });
}

export function getPaidShops(data) {
  const { page, size, ...remData } = data;
  return api.post(`/transactions/get-paidshops${page && size ? `?page=${page}&size=${size}` : ""}`, remData, { withCredentials: true });
}

// Banks
export function getBanks(data) {
  const { page, size, search, ...remData } = data;
  const searchParams = new URLSearchParams({
    size,
    page,
    ...(search.length > 0 ? { search: search } : {}),
  });
  return api.get(`/banks?` + searchParams.toString(), {
    withCredentials: true,
  });
}
export function getBanksNoOfPages(data) {
  const { page, size, search, ...remData } = data;
  const searchParams = new URLSearchParams({
    size,
    page,
    ...(search.length > 0 ? { search: search } : {}),
  });
  return api.get(`/banks/no-of-pages?` + searchParams.toString(), {
    withCredentials: true,
  });
}

export function getBankById(id) {
  return api.get(`/banks/${id}`, { withCredentials: true });
}

export function getImportData(data) {
  return api.get(`data-import-requests/?page=${data.page}&limit=${data.size}`, { withCredentials: true });
}

export function fetchAllRedirections(data) {
  const { size, page, search, filters, ...remData } = data;
  const headers = {
    filters: JSON.stringify(filters),
  };
  return api.get(`/forced-redirections/all?page=` + page + `&size=` + size + `&search=` + search, { headers, withCredentials: true });
}
export function getValidateData(id, pagination) {
  return api.get(`/data-import-requests/validate/${id}?page=${pagination.current}&limit=${pagination.pageSize}`, { withCredentials: true });
}

export function fetchAllSellerRegistrationRequests(data) {
  const { size, page, search, filters, ...remData } = data;
  const headers = {
    filters: JSON.stringify(filters),
  };
  return api.get(`/become-seller/all?page=` + page + `&size=` + size + `&search=` + search, { headers, withCredentials: true });
}

const orderByFormatForQueryString = (obj) => {
  const [[key, value]] = Object.entries(obj);
  return `${key}:${value}`;
};
export function fetchAllNotifications(data) {
  const { size, page, search, order, date, ...remData } = data;
  const orderByString = order ? orderByFormatForQueryString(order) : "";
  const searchParams = new URLSearchParams({
    size,
    page,
    ...(search.length > 0 ? { search: search } : {}),
    ...(order ? { order: orderByString } : {}),
    ...(date ? { "date[from]": moment(date.from).format("YYYY-MM-DD"), "date[to]": moment(date.to).format("YYYY-MM-DD") } : {}),
  });
  return api.get(`/notifications/all?` + searchParams.toString(), { withCredentials: true });
}

export function fetchAllSMSNotifications(data) {
  const { size, page, search, order, date, ...remData } = data;
  const orderByString = order ? orderByFormatForQueryString(order) : "";
  const searchParams = new URLSearchParams({
    size,
    page,
    ...(search.length > 0 ? { search: search } : {}),
    ...(order ? { order: orderByString } : {}),
    ...(date ? { "date[from]": moment(date.from).format("YYYY-MM-DD"), "date[to]": moment(date.to).format("YYYY-MM-DD") } : {}),
  });
  return api.get(`/sms-notification/all?` + searchParams.toString(), { withCredentials: true });
}
export function fetchAllUsersDeletionRequests(data) {
  const { size, page, search, orderBy, status, date, ...remData } = data;
  const orderByString = orderBy ? orderByFormatForQueryString(orderBy) : "";
  const searchParams = new URLSearchParams({
    size,
    page,
    ...(search.length > 0 ? { search: search } : {}),
    ...(orderBy ? { orderBy: orderByString } : {}),
    ...(status ? { status: status } : {}),
    ...(date ? { "date[from]": moment(date.from).format("YYYY-MM-DD"), "date[to]": moment(date.to).format("YYYY-MM-DD") } : {}),
  });
  return api.get(`/delete-requests?` + searchParams.toString(), { withCredentials: true });
}

export function getModules(data) {
  return api.get(`/modules/fetchAll?page=${data.currentPage}&size=${data.sizeOfPage}&name=${data.search}&status=${data.statusType}`, { withCredentials: true });
}

export function getParentsModules(id) {
  return api.get(`/modules/module`, { withCredentials: true });
}
export function getParentModules(id) {
  return api.get(`/modules/plan-modules`, { withCredentials: true });
}

export function getSubModules(id) {
  return api.get(`/modules/parent-module/${id}`, { withCredentials: true });
}

export function deleteModule(id) {
  return api.delete(`/modules/${id}`, { withCredentials: true });
}
export function fetchPlanRequests(data) {
  const { size, page, search, order, date, status, ...remData } = data;
  const orderByString = order ? orderByFormatForQueryString(order) : "";
  const searchParams = new URLSearchParams({
    size,
    page,
    ...(status && status.length > 0 ? { status: status } : {}),
    ...(search.length > 0 ? { search: search } : {}),
    ...(order ? { orderBy: orderByString } : {}),
    ...(date ? { "date[from]": moment(date.from).format("YYYY-MM-DD"), "date[to]": moment(date.to).format("YYYY-MM-DD") } : {}),
  });
  return api.get(`/plan-requests/all?` + searchParams.toString(), { withCredentials: true });
}
export function fetchAllConsumableItems(data) {
  const orderByString = data?.orderBy ? orderByFormatForQueryString(data?.orderBy) : "";
  const searchParams = new URLSearchParams({
    ...(data?.size ? { size: data?.size } : {}),
    ...(data?.page ? { page: data?.page } : {}),
    ...(data?.orderBy ? { orderBy: orderByString } : {}),
  });
  return api.get(`/addons/pricing/consumable-item${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}
export function fetchAllAddonsRequests(data) {
  const orderByString = data?.orderBy ? orderByFormatForQueryString(data?.orderBy) : "";
  const searchParams = new URLSearchParams({
    ...(data?.size ? { size: data?.size } : {}),
    ...(data?.page ? { page: data?.page } : {}),
    ...(data?.orderBy ? { orderBy: orderByString } : {}),
    ...(data?.status ? { status: data?.status } : {}),
    ...(data?.search ? { search: data?.search } : {}),
    ...(data?.consumableItem ? (data?.consumableItem === "is-for-extension" ? { isForExtension: "yes" } : { consumableItem: data?.consumableItem }) : {}),
    ...(data?.date ? { "date[from]": moment(data?.date?.from).format("YYYY-MM-DD"), "date[to]": moment(data?.date?.to).format("YYYY-MM-DD") } : {}),
  });
  return api.get(`/addons/request${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}
export function getRequestById(id) {
  return api.get(`/addons/request/${id}`, { withCredentials: true });
}
export function fetchAllModules(data) {
  const orderByString = data?.orderBy ? orderByFormatForQueryString(data?.orderBy) : "";
  const searchParams = new URLSearchParams({
    ...(data?.size ? { size: data?.size } : {}),
    ...(data?.page ? { page: data?.page } : {}),
    ...(data.search ? { name: data.search } : {}),
    ...(data.status ? { status: data.status } : {}),
    ...(data?.orderBy ? { orderBy: orderByString } : {}),
  });
  return api.get(`/modules/fetchAll${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}

export function fetchAllThemes(data) {
  const orderByString = data?.orderBy ? orderByFormatForQueryString(data?.orderBy) : "";
  const searchParams = new URLSearchParams({
    ...(data?.size ? { size: data?.size } : {}),
    ...(data?.page ? { page: data?.page } : {}),
    ...(data?.search ? { name: data.search } : {}),
    ...(data?.orderBy ? { orderBy: orderByString } : {}),
  });
  return api.get(`/themes${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}
export function fetchSampleDataOfDataRequest(id) {
  return api.get(`/data-import-requests/sample-data/${id}`, { withCredentials: true });
}
export function fetchAllCategories() {
  return api.get(`/categories`, { withCredentials: true });
}
export function fetchAllOrders(data) {
  const orderByString = data?.orderBy ? orderByFormatForQueryString(data?.orderBy) : "";
  const searchParams = new URLSearchParams({
    ...(data?.size ? { size: data?.size } : {}),
    ...(data?.page ? { page: data?.page } : {}),
    ...(data?.search.length > 0 ? { search: data?.search } : {}),
    ...(data.phone ? { phone: data.phone } : {}),
    ...(data?.status?.value ? { status: data?.status?.value } : {}),
    ...(data?.orderBy ? { orderBy: orderByString } : {}),
    ...(data?.category ? { category: data?.category?.value } : {}),
    ...(data?.fromDate && data?.toDate ? { "date[from]": moment(data?.fromDate).format("YYYY-MM-DD"), "date[to]": moment(data?.toDate).format("YYYY-MM-DD") } : {}),
  });
  return api.get(`/d4u-orders${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}

export function getDeliveryCharges(data) {
  return api.get(`/delivery-charges?page=${data.page}&size=${data.size}`, { withCredentials: true });
}

export function getCitiess() {
  return api.get(`/location/get-city`, { withCredentials: true });
}

export function fetchDeliverForYouOrdersStats(shop) {
  const searchParams = new URLSearchParams({
    ...(shop?.value ? { shop: shop?.value?.id } : {}),
  });
  return api.get(`/d4u-orders/stats/counts${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}
export function getShopConsumableItems(shopId) {
  return api.get(`/addons/shop/${shopId}/counts`, { withCredentials: true });
}
export function fetchAllDeliveryCities(data) {
  const orderByString = data?.orderBy ? orderByFormatForQueryString(data?.orderBy) : "";
  const searchParams = new URLSearchParams({
    ...(data?.size ? { size: data?.size } : {}),
    ...(data?.page ? { page: data?.page } : {}),
    ...(data?.orderBy ? { orderBy: orderByString } : {}),
    ...(data?.status !== undefined ? { active: data?.status } : {}),
    ...(data?.search ? { search: data?.search } : {}),
    ...(data?.province ? { province: data?.province } : {}),
  });
  return api.get(`/city${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}

export function fetchAllShops(data) {
  const orderByString = data?.orderBy ? orderByFormatForQueryString(data?.orderBy) : "";
  const searchParams = new URLSearchParams({
    ...(data?.size ? { size: data?.size } : {}),
    ...(data?.page ? { page: data?.page } : {}),
    ...(data?.orderBy ? { orderBy: orderByString } : {}),
    ...(data?.status ? { trialOrPaid: data?.status } : {}),
    ...(data?.d4u !== undefined ? { d4u: data.d4u } : {}),
    ...(data?.search ? { search: data?.search } : {}),
  });
  return api.get(`/shop-options/shops${searchParams.toString().length ? `?${searchParams.toString()}` : ""}`, { withCredentials: true });
}
