import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import style from "../../styles/Subscription.module.css";
import { useSelector } from "react-redux";
import Subscription from "../../Components/Subscription/Subscription";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";

const SubscriptionsPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const { user } = useSelector((state) => state.auth);

  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [addUserShow, setAddUserShow] = useState(true);
  const [refresh, toggle] = useState(false);
  const [userType, setUserType] = useState(null);
  const [des, setDes] = useState();
  const [phase, setPhase] = useState();
  const [userTypeOptions, setUserTypeOptions] = useState([
    { value: "super-admin", label: "Super Admin" },
    { value: "admin", label: "Admin" },
    { value: "marketing", label: "marketing" },
    { value: "data", label: "data" },
  ]);
  const [status, setStatus] = useState({ value: true, label: "Active" });

  // shops on  payment

  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({ shop_name: "ASC" });
  // INCOMPLETE ------------------------------------

  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const resetFilterHandle = () => {
    setUserType(null);
    setSearchInput("");
    setStatus({ value: true, label: "Active" });
  };
  useEffect(() => {}, [, phase, des, refresh]);

  const deleteInventory = (id) => {};
  const handleTabs = (e) => {
    if (e === "SellerUser") {
      setAddUserShow(false);
      setUserTypeOptions([
        { value: "primary", label: "Primary" },
        { value: "secondary", label: "Secondary" },
      ]);
    } else {
      setAddUserShow(true);
      setUserTypeOptions([
        { value: "super-admin", label: "Super Admin" },
        { value: "admin", label: "Admin" },
        { value: "marketing", label: "marketing" },
        { value: "data", label: "data" },
      ]);
    }
    resetFilterHandle();
  };
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };
  const getPhase = (value) => {
    setPhase(value);
  };

  return (
    <>
      <Container fluid>
        <div className="d-flex">
          <div className={`d-flex ${style.Trial_div} p-2 pt-3 me-5`}>
            <span className={`${style.Trial_span}`}>Trial</span>
            <button
              className={`btn btn-outline-primary ${phase === "A" && "banActive"} me-2 `}
              onClick={() => {
                setDes("On trial");
                getPhase("A");
              }}
            >
              A
            </button>
            <button
              className={`btn btn-outline-primary ${phase === "B" && "banActive"} me-2 `}
              onClick={() => {
                setDes("Banner Showing On trial");

                getPhase("B");
              }}
            >
              B
            </button>
            <button
              className={`btn btn-outline-primary ${phase === "C" && "banActive"} me-2 `}
              onClick={() => {
                setDes(" Grace Period On trial");
                getPhase("C");
              }}
            >
              C
            </button>
            <button
              disabled={true}
              className={`btn  btn-outline-secondary ${phase === "D" && "warnActive"} me-2 `}
              onClick={() => {
                setDes("Since Grace Period Ends on Trial");

                getPhase("D");
              }}
            >
              D
            </button>
            <button
              className={`btn  btn-outline-danger ${phase === "X" && "dangerActive"}  `}
              onClick={() => {
                getPhase("X");

                setDes("Deactivate on Trial");
              }}
            >
              X
            </button>
          </div>
          <div className={`d-flex ${style.Trial_div} p-2 pt-3 me-5`}>
            <span className={`${style.Active_span}`}>Active</span>

            <button
              className={`btn btn-outline-primary ${phase === "E" && "banActive"} me-2 `}
              onClick={() => {
                getPhase("E");
                setDes("On Payment");
              }}
            >
              E
            </button>
            <button
              className={`btn btn-outline-primary ${phase === "F" && "banActive"} me-2 `}
              onClick={() => {
                setDes("Banner Showing on Payment");

                getPhase("F");
              }}
            >
              F
            </button>
            <button
              className={`btn btn-outline-primary ${phase === "G" && "banActive"} me-2 `}
              onClick={() => {
                setDes("Grace Period on Payment");

                getPhase("G");
              }}
            >
              G
            </button>
            <button
              disabled={true}
              className={`btn  btn-outline-secondary ${phase === "H" && "warnActive"} me-2 `}
              onClick={() => {
                setDes("Since Grace Period ends On payemnt");

                getPhase("H");
              }}
            >
              H
            </button>
            <button
              className={`btn  btn-outline-danger ${phase === "Y" && "dangerActive"}  `}
              onClick={() => {
                setDes("Deactivate on payment");

                getPhase("Y");
              }}
            >
              Y
            </button>
          </div>
          <div className={`d-flex ${style.cancel_div} p-2 pt-3`}>
            <span className={`${style.Cancel_span}`}>Cancel</span>

            <button
              className={`btn btn-outline-secondary ${phase === "Z" && "canceActive"} w-100 `}
              onClick={() => {
                getPhase("Z");
                setDes("Cancel Subscription");
              }}
            >
              Z
            </button>
          </div>
        </div>
        <strong className="d-flex justify-content-end">
          <h5>{des}</h5>
        </strong>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="6" lg="2" className=" mb-2">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Subscription phase={phase} search={searchInput} status={status} orderBy={orderBy} setOrderBy={setOrderBy} sizeOfPage={sizeOfPages} userType={userType} refresh={refresh} toggle={toggle} />
            </Col>
          </Row>
        </>
      </Container>
    </>
  );
};
export default SubscriptionsPage;
