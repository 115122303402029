import "./StatCard.css";

export default function StatCard({ title, count }) {
  // console.log(title, count);

  return (
    <div className="card card-side-border h-100">
      <div className="card-body">
        <div className="d-flex flex-column align-items-start h-100 justify-content-between">
          <p className="text-secondary fs-6 fw-bold m-0">{title}</p>
          <p className="text-black align-self-end align-self-bottom fs-3 fw-bold m-0">{count}</p>
        </div>
      </div>
    </div>
  );
}
