import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const GroupedBarChart = ({ data, title }) => {
    // Data may contain multiple users and their data for different periods
    // for each user we'll get rid of all-time data and create a dataset for each user

    const users = Object.keys(data);
    const periodLabels = {
        today: "Today",
        "this-week": "This Week",
        "this-month": "This Month",
        "this-year": "This Year",
        "last-year": "Last Year",
      };
    const datasets = users.map((user, index) => {
        const { "all-time": _, ...dataWithoutAllTime } = data[user];

        return {
            label: user,
            data: Object.keys(periodLabels).map((period) => dataWithoutAllTime[period]),
            backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.2)`,
            borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`,
            borderWidth: 1,
            stack: index,
        };
    });

    const labels = Object.values(periodLabels);

    const chartData = {
        labels: labels,
        datasets: datasets,
    };

    const options = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: title,
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default GroupedBarChart;
