import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import useMarkedFields from "../../Hooks/validationHook";

import * as postapi from "../../api/postapi";

import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
const DeactivateModal = ({ id, data, close, toggle }) => {
    
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [otherReason, setOtherReason] = useState(false);
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [validActive, setValidActive] = useState(false);
  const deactivateUser = useApi(postapi.DeactivateShops);
  const [error, setError] = useState("");

  useEffect(() => {
    if (selectedReasons.length > 0 || (otherReason && message.length > 0)) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  }, [selectedReasons, otherReason, message]);

  const handleReasonChange = (e, reason) => {
    const isChecked = e.target.checked;
    setSelectedReasons((prevReasons) => {
      if (isChecked) {
        return [...prevReasons, reason];
      } else {
        return prevReasons.filter((r) => r !== reason);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedReasons.length > 0 || otherReason) {
      if (otherReason && !message.length > 0) {
        setValidated(true);
      } else {
        const data = {
          reason: {
            shop_id: id,
            deactivation_reasons: `${selectedReasons.join(",")}${message.length > 0 ? ","+message:""}`,
            deactivated_on: new Date().toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          },
        };
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.stopPropagation();
        }
        if (!validated) {
          setValidActive(true);
          close();
          try {
            await deactivateUser.request(id, data);
            toggle((prev) => !prev);
          } catch {}
          setValidActive(false);
        }
      }
    } else {
      setValidated(true);
    }
  };

  return (
      <>
        <Form noValidate validated={validated} autoComplete="nope" onSubmit={handleSubmit}>
          <Row className="py-2 mb-2 d-flex flex-column">
            <Form.Label className="mb-1">Reasons for Deactivation <span style={{ color: "red" }}>*</span></Form.Label>
            {/* Form fields for selecting reasons */}
            {/* Payment issues */}
            <Col lg={"8"}>
              <Form.Group>
                <Form.Check
                    type="checkbox"
                    label="Payment issues"
                    value="Payment issues"
                    onChange={(e) => handleReasonChange(e, "Payment issues")}
                />
              </Form.Group>
            </Col>
            {/* Shop not activated for long time */}
            <Col lg={"8"}>
                <Form.Group>
                    <Form.Check
                        type="checkbox"
                        label="Shop not activated for long time"
                        value="Shop not activated for long time"
                        onChange={(e) => handleReasonChange(e, "Shop not activated for long time")}
                    />
                </Form.Group>
            </Col>
            {/* Behaviour issue */}
            <Col lg={"8"}>
                <Form.Group>
                    <Form.Check
                        type="checkbox"
                        label="Behaviour issue"
                        value="Behaviour issue"
                        onChange={(e) => handleReasonChange(e, "Behaviour issue")}
                    />
                </Form.Group>
            </Col>
            {/* Point system */}
            <Col lg={"8"}>
                <Form.Group>
                    <Form.Check
                        type="checkbox"
                        label="Point system"
                        value="Point system"
                        onChange={(e) => handleReasonChange(e, "Point system")}
                    />
                </Form.Group>
            </Col>
            {/* Other */}
            <Col lg={"8"}>
                <Form.Group>
                    <Form.Check
                        type="checkbox"
                        label="Other"
                        checked={otherReason}
                        onChange={(e) => setOtherReason(e.target.checked)}
                    />
                </Form.Group>
            </Col>
            {otherReason && (
                <>
                  <Form.Label className="mb-1">Reason : </Form.Label>
                  <Col lg={"10"}>
                    <Form.Group>
                      <Form.Control
                          required
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          as="textarea"
                          rows={3}
                      />
                      <Form.Control.Feedback type="invalid">Fill Reason.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </>
            )}
          </Row>
          <Button type="submit" disabled={validActive} className="w-100">
            Deactivate
          </Button>
          {validated && (
              <p className="text-center mt-3" style={{ color: "tomato" }}>
                Select at least one reason
              </p>
          )}
        </Form>
      </>
  );
};

export default DeactivateModal;
