import style from "./../../styles/Sidebar.module.css";
import SideNavData from "./SideNavData";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "./../../Features/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";

const Sidebar = ({ setIsSandwichOpen, isSandwichOpen, toggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const userName1 = user.user_full_name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");

  const navigate = useNavigate();
  const publicPermissions = ["Dashboard"];
  const permissions = user?.permissionArray;
  const userType = user.user_type;

  const [data, setdata] = useState(SideNavData);

  useEffect(() => {
    const newPermissions = permissions?.concat(publicPermissions);
    if (userType === "super-admin") {
      setdata(SideNavData);
    } else {
      if (userType === "admin") {
        // const newSideNavData = SideNavData.map((Nav) => {
        //   if (Nav.identifier === "Settings") {
        //     const NewSubNav = Nav.subNav.filter(
        //       (Sub) => Sub.identifier !== "AddNewLocation"
        //     );
        //     return { ...Nav, subNav: NewSubNav };
        //   } else {
        //     return Nav;
        //   }
        // });
        //new Logic according to admin
        const newSideNavData = SideNavData;
        setdata(newSideNavData.filter((ans) => newPermissions?.includes(ans.identifier)));
      }
    }
  }, [permissions, userType, user]);
  const logout = () => {
    localStorage.removeItem("refreshToken");
    dispatch(authActions.logout());
    navigate("/login");
  };
  
  return (
    <div className={`bg-white shadowMain ${style.sidebar} ${!isSandwichOpen ? "" : style.sandwichOpen}`}>
      <div className={`${isSandwichOpen ? "justify-content-xl-center" : ""} py-3 px-2 ${style.infoDiv}`}>
        <div className={`${style.userInfo}`}>
          <div className={`ms-2 me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}>{userName1}</div>
          {!isSandwichOpen && (
            <div>
              <p className="fw-bold mb-0 text-capitalize">{user && user.user_full_name}</p>
              <span className="fw-light text-capitalize">{user && user.user_type}</span>
            </div>
          )}
        </div>
        <div role="button" className="text-white d-md-block d-lg-none" onClick={() => toggle()}>
          Close <FontAwesomeIcon icon={faClose} />
        </div>
      </div>
      <div className={`d-flex flex-column justify-content-between ${isSandwichOpen ? "align-items-center" : "align-items-start"} ${style.sidebarItems}`}>
        <div className={`${style.scroll} ${style.scrollheight} w-100`}>
          <Menu setIsSandwichOpen={setIsSandwichOpen} navData={data} isSandwichOpen={isSandwichOpen} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
