import React from "react";
import { Button, Modal } from "react-bootstrap";

const NotificationImageModal = ({ show, close, data }) => {
  return (
    <>
      <Modal show={show} onHide={close} dialogClassName="notification-image-modal">
        <Modal.Header closeButton>
          <Modal.Title>Notification Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center notification-image-modal-img-container">
            <img className="notification-image-modal-img" src={process.env.REACT_APP_URL_S3 + "/" + data?.image} style={{ height: "200px", width: "inherit", overflow: "hidden", objectFit: "cover" }} />
          </div>
        </Modal.Body>
        {data?.link && (
          <Modal.Footer className="border-0 d-flex justify-content-center">
            <a href={data?.link} target="_blank" rel="noopener noreferrer">
              <Button variant="outline-primary" className="notification-image-modal-link-button" onClick={close}>
                more detail {">>"}
              </Button>
            </a>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default NotificationImageModal;
